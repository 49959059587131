import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import dashboardReducer from "./slice/dashboardSlice";
import paymentReducer from "./slice/paymentSlice";

const persistConfig = {
  key: "store",
  storage,
};

export const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userReducer,
    dashboard: dashboardReducer,
    payment: paymentReducer,
  })
);
