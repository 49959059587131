import React, { useEffect, useState } from "react";
import { invalid, serviceCircle } from "../images";
import { useLocation, useNavigate } from "react-router-dom";
import { postQueryRemitter } from "../urls";
import API from "./api2";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import loaderGif from "../gif/buffer.gif";
import EkycPage from "../pages/services/DMT/EkycPage";

const CheckCustomer = ({ contactNo, name }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mobileNumber = contactNo || location.state?.contactNo || "";
  const [resp, setResp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Continue");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentPage, setCurrentPage] = useState("checkCustomer");

  // get coordinates
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          Swal.fire({
            title: "Location Access Required",
            text: "Please enable location services to proceed with login.",
            icon: "warning",
            confirmButtonText: "Enable",
            allowOutsideClick: false,
          }).then(() => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
              },
              (error) => {
                console.log("Location permission denied.");
              }
            );
          });
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleCustomerClick = (contact_no) => {
    setLoading(true);
    const formData = {
      mobile_number: contact_no,
      latitude: latitude,
      longitude: longitude,
    };
    API({
      ...postQueryRemitter,
      body: formData,
      onSuccess: (res) => {
        setLoading(false);
        const { response_code } = res.data.data;
        Swal.fire({
          title: `${res.data.data.message}`,
        }).then(() => {
          if (response_code) {
            if (response_code === 2) {
              setCurrentPage("ekyc");
            } else {
              const { mobile, stateResp, ekyc_id } = res.data.data.data;
              setTimeout(() => {
                changePageFunc(response_code, mobile, stateResp, ekyc_id);
              }, 1000);
            }
          }
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleRegisterClick = () => {
    changePageFunc(resp, false, {}, mobileNumber);
  };

  // change page
  const changePageFunc = (response_code, mobile, stateResp, ekyc_id) => {
    const page =
      response_code === 1
        ? "addBeneficiary"
        : response_code === 3
        ? "remitterReg"
        : null;

    dispatch(changePage({ page }));
    navigate("/", {
      state: {
        mobile,
        stateResp,
        ekyc_id,
      },
    });
  };

  if (currentPage === "ekyc") {
    return <EkycPage setCurrentPage={setCurrentPage} contactNo={contactNo} />;
  }

  return (
    <div className="container-fluid">
      <div
        className="col-11 position-relative mx-auto mt-2"
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          border: "1px solid #D9D9D9",
          boxShadow: "0px 4px 40px 10px #00000026",
        }}
      >
        <img
          style={{
            position: "absolute",
            right: "0",
            top: "0",
          }}
          src={serviceCircle}
          alt="circle"
        />
        <div className="row">
          <div
            style={{ borderRight: "1px solid #D9D9D9" }}
            className="col-4 ms-3 py-3"
          >
            <div
              className="p-4"
              style={{
                backgroundColor: "rgba(25, 66, 128, 1)",
                borderRadius: "20px",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "white",
                }}
                className="mb-1"
              >
                {name}
              </p>
              <div
                className="my-3 px-3 py-2 mx-auto"
                style={{
                  backgroundColor: "#FFEEBF",
                  borderRadius: "5px",
                  width: "260px",
                }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "#4F575B",
                  }}
                  className="mb-0"
                >
                  Verify Contact list!
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    color: "#4F575B",
                    lineHeight: "12px",
                  }}
                  className="mb-0 mt-1"
                >
                  This verification is to check if the mobile number is under
                  the record list or not.
                </p>
              </div>
              <div className="col-10 mx-auto">
                <label
                  htmlFor="contact_no"
                  style={{
                    color: "#ADACAC",
                    transition: "all 0.4s ease-in-out",
                    fontSize: "14px",
                    opacity: mobileNumber ? 1 : 0,
                    marginTop: "8px",
                  }}
                  className="ms-2 mt-3 trans_text"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="contact_no"
                  name="contact_no"
                  value={mobileNumber}
                  placeholder="Enter Mobile No."
                  style={{
                    backgroundColor: "transparent",
                    borderBottom: mobileNumber
                      ? "1.5px solid white"
                      : "1.5px solid #ADACAC",
                    width: "100%",
                    color: "white",
                    borderRadius: "0px",
                    padding: "0px 8px",
                  }}
                  autoComplete="off"
                  className="trans_text"
                  readOnly
                />
              </div>
              <div className="text-center mt-4">
                <button
                  className="px-4 py-2 trans_text col-9"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    border: "none",
                    fontSize: "17px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    if (buttonText === "Continue") {
                      handleCustomerClick(mobileNumber);
                    } else {
                      handleRegisterClick();
                    }
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <img
                      src={loaderGif}
                      alt="Loading..."
                      style={{ height: "30px" }}
                    />
                  ) : (
                    buttonText
                  )}
                </button>
                {buttonText === "Register" && (
                  <div
                    className="mx-auto mt-3 py-1 px-2"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      width: "225px",
                      textAlign: "left",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img src={invalid} alt="record-invalid" />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#E14A4A",
                        }}
                        className="mb-0 ms-1"
                      >
                        Record Invalid!
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#1A1918",
                        fontWeight: "500",
                        lineHeight: "12px",
                      }}
                      className="mb-0 ms-3"
                    >
                      Customer record{" "}
                      <span style={{ color: "#E14A4A" }}>Not found</span> .
                      Please register the customer
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckCustomer;
