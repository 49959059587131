import React, { useEffect, useState } from "react";
import { brandDropdown, getBusinessBrand } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Filter2 from "../components/Filter2";

const UpdateBrand = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 100 });
  const [dropdown, setDropdown] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();

  useEffect(() => {
    fetchBrands({ initial: true });
    fetchBrandsDropdown();
  }, []);

  async function fetchBrandsExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchBrands({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  const fetchBrandsDropdown = () => {
    API({
      ...brandDropdown,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching brands dropdown: ", error);
      },
    });
  };

  const fetchBrands = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...getBusinessBrand,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        if (initial) {
          setActive(res.data.data.active_count);
          setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
            }
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching brands: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ brand_id, targetPage } = {}) => {
    if (brand_id) {
      dispatch(
        changePage({
          page: "brandInformation",
          brand_id: brand_id,
        })
      );

      navigate("/", {
        state: {
          brand_id,
        },
      });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };
  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchBrands({ append: true });
    }
  }, [pagination]);
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            List of Brand
          </h2>
          <div className="d-flex me-5 pe-3">
            <div
              className="d-flex align-items-center"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(20, 140, 67, 1)",
                }}
                className="mb-0"
              >
                Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {active}
              </span>
            </div>
            <div
              className="d-flex align-items-center ms-3"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(249, 124, 75, 1)",
                }}
                className="mb-0"
              >
                In Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {inActive}
              </span>
            </div>
          </div>
        </div>
        <Filter2
          getDataFunc={fetchBrands}
          excelFunc={fetchBrandsExcel}
          excelFileName={"Brands Data"}
          filters={[
            {
              placeholder: "Brand ID",
              id: "brand_idd",
              filterType: "input",
              inputType: "number",
              options: [],
            },
            {
              placeholder: "Business Name",
              id: "business_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.map((item) => ({
                value: item.business_name,
                label: item.business_name,
              })),
            },
            {
              placeholder: "Email-ID",
              id: "contact_email",
              filterType: "input",
              inputType: "email",
              options: [],
            },
            {
              placeholder: "Mobile No.",
              id: "contact_no",
              filterType: "input",
              inputType: "number",
              options: [],
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addBusinessBrand" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD BRAND"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ brand_id: row.original.brand_id })
                    }
                  />
                );
              },
            },
            { Header: "Brand ID", accessor: "brand_id" },
            { Header: "Brand Name", accessor: "business_name" },
            { Header: "Email-ID", accessor: "contact_email" },
            { Header: "Contact No", accessor: "contact_no" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default UpdateBrand;
