import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import InputForm from "../components/InputForm";
import { fullScreen, smallScreen } from "../images";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  addTicket,
  ticketCategory,
  helpSelectedDropdownRefined,
} from "../urls";
import API from "./api2";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import loaderGif from "../gif/buffer.gif";
import "quill-table-ui/dist/index.css";
import QuillTableUI from "quill-table-ui";

Quill.register(
  {
    "modules/tableUI": QuillTableUI,
  },
  true
);

const HelpDeskModal = ({ show, onClose, username }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentCharacters, setCurrentCharacters] = useState(0);

  const [pageData, setPageData] = useState({
    subject: "",
    type: "",
    category: "",
    subcategory: "",
    ticket_desc: "",
    attachments: [],
  });

  const quillRef = useRef(null);

  // dropdowns for type, category, sub-category
  const handleTicketTypeChange = (selectedOption) => {
    setPageData({
      ...pageData,
      type: selectedOption.value,
      category: "",
      subcategory: "",
    });

    setCategoryOptions([]);
    setSubcategoryOptions([]);
    fetchCategories(selectedOption.value);
  };
  const handleCategoryChange = (selectedOption) => {
    setPageData({
      ...pageData,
      category: selectedOption ? selectedOption.value : "",
      subcategory: "",
    });
    setSubcategoryOptions([]);
    if (selectedOption) {
      fetchSubcategories(pageData.type, selectedOption.value);
    }
  };
  const handleSubcategoryChange = (selectedOption) => {
    const subcategoryValue = selectedOption ? selectedOption.value : "";
    setPageData({
      ...pageData,
      subcategory: subcategoryValue,
    });

    const quill = quillRef.current.__quill;
    let prefillText = "";

    if (
      subcategoryValue === "Password Reset" &&
      pageData.category === "FinPage"
    ) {
      prefillText = `
<p>Hi Support Team,</p>
<p>I need assistance with resetting my password. Below are the details:</p>
<p><b>Employee Name:</b> [Your Name]</p>
<p><b>Employee Code:</b> [Your Employee Code]</p>
<p><b>Branch Name:</b> [Your Branch Name]</p>
<p>Please proceed with the password reset at your earliest convenience.</p>
<p>Thank you!</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request for Password Reset in Finpage",
      }));
    } else if (
      subcategoryValue === "ID Block" &&
      pageData.category === "FinPage"
    ) {
      prefillText = `
<p>Hi Support Team,</p>
<p>I require assistance in unblocking my User ID. Please find below the necessary details for the unblock request:</p>
<p><b>Employee Name:</b> [Your Name]</p>
<p><b>Employee Code:</b> [Your Employee Code]</p>
<p><b>Branch Name:</b> [Your Branch Name]</p>
<p>Kindly proceed with unblocking my User ID as soon as possible.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request to Unblock User ID in Finpage",
      }));
    } else if (
      subcategoryValue === "ID Block" &&
      pageData.category === "FinPage"
    ) {
      prefillText = `
<p>Hi Support Team,</p>
<p>I require assistance in unblocking my User ID. Please find below the necessary details for the unblock request:</p>
<p><b>Employee Name:</b> [Your Name]</p>
<p><b>Employee Code:</b> [Your Employee Code]</p>
<p><b>Branch Name:</b> [Your Branch Name]</p>
<p>Kindly proceed with unblocking my User ID as soon as possible.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request to Unblock User ID in Finpage",
      }));
    } else if (
      subcategoryValue === "ID Block" &&
      pageData.category === "PrayasApp"
    ) {
      prefillText = `
<p>Hi Support Team,</p>
<p>I require assistance in unblocking my User ID. Please find below the necessary details for the unblock request:</p>
<p><b>Employee Name:</b> [Your Name]</p>
<p><b>Employee Code:</b> [Your Employee Code]</p>
<p><b>Branch Name:</b> [Your Branch Name]</p>
<p>Kindly proceed with unblocking my User ID as soon as possible.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request to Unblock User ID in PrayasApp",
      }));
    } else if (
      subcategoryValue === "Password Reset" &&
      pageData.category === "PrayasApp"
    ) {
      prefillText = `
<p>Hi Support Team,</p>
<p>I need assistance with resetting my password. Below are the details:</p>
<p><b>Employee Name:</b> [Your Name]</p>
<p><b>Employee Code:</b> [Your Employee Code]</p>
<p><b>Branch Name:</b> [Your Branch Name]</p>
<p>Please proceed with the password reset at your earliest convenience.</p>
<p>Thank you!</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request for Password Reset in PrayasApp",
      }));
    } else if (subcategoryValue === "Issue In Cashless Collection") {
      prefillText = `
<p>Dear Support Team,</p>
<p>The branch team has identified an issue regarding the posting of collection entries for EMI payments received through cashless mode. Please find below the details required to address this concern:
</p>
<p><b>Application ID</b> [Application ID]</p>
<p><b>Branch Name:</b> [Branch Name]</p>
<p><b>Customer Name:</b> [Customer Name]</p>
<p><b>Payment Date:</b> [Payment Date]</p>
<p><b>Center ID:</b> [Center ID]</p>
<p><b>Amount of Transaction:</b> [Amount]</p>
<p><b>UTR Number:</b> [UTR Number]</p>
<p><b>Transaction Screenshot:</b> [Attach screenshot if possible]</p>
<p><b>Detailed Remarks:</b> [Provide detailed description of the issue]</p>
<p>Kindly review this information and take appropriate action to resolve the issue with posting the collection entries.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Concern Regarding Issue in cashless collections",
      }));
    } else if (subcategoryValue === "CGT Reschedule") {
      prefillText = `
<p>Hello Support Team,</p>
<p>We need to request a reschedule for CGT, as the reschedule period falls between >14 days and <=90 days. Please find below the required branch details:</p>
<p><b>Branch Details:</b> [Branch Details]</p>
<p><b>Center ID:</b> [Center ID]</p>
<p><b>Date:</b> [Date]</p>
<p>Kindly assist with processing this request at your earliest convenience.</p>
<p>Thank you for your attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request for CGT Reschedule",
      }));
    } else if (subcategoryValue === "Add On Members") {
      prefillText = `
<p>Dear Support Team,</p>
<p>The branch needs to add members to an existing group. Please find below the required details:</p>
<p><b>Branch Details:</b> [Branch Details]</p>
<p><b>Center ID:</b> [Center ID]</p>
<p>Kindly assist with adding the requested members to the group as per our requirements.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request to Add On Members to Existing Group",
      }));
    } else if (subcategoryValue === "HV Reschedule") {
      prefillText = `
<p>Hello Support Team,</p>
<p>We need to request a reschedule for HV, as the reschedule period falls between >14 days and <=90 days. Please find below the required details:</p>
<p><b>Branch Details:</b> [Branch Details]</p>
<p><b>Center ID:</b> [Center ID]</p>
<p><b>Meeting Assigned To:</b> [Meeting Assigned To]</p>
<p><b>Date:</b> [Date]</p>
<p>Kindly process this request accordingly and update us on the rescheduled details.</p>
<p>Thank you for your assistance.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request for HV Reschedule",
      }));
    } else if (subcategoryValue === "Change In Income") {
      prefillText = `
<p>Hello Support Team,</p>
<p>We need to request a change in the income details for the application. Please find below the required information:</p>
<p><b>Application ID: </b> [Application ID]</p>
<p><b>Borrower's Income:</b> [Borrower's Income]</p>
<p><b>Co-Borrower's Income:</b> [Co-Borrower's Income]</p>
<p><b>Unmarried Son's Income:</b> [Unmarried Son's Income]</p>
<p><b>Unmarried Daughter's Income:</b> [Unmarried Daughter's Income]</p>
<p>Kindly update the income details as per the above information.</p>
<p>Thank you for your prompt attention to this matter.</p>
<p>Best regards,<br>[Your Name]</p>
`;
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "Request to Change Income Details",
      }));
    } else {
      setPageData((prevData) => ({
        ...prevData,
        ticket_desc: prefillText,
        subject: "",
      }));
    }

    quill.clipboard.dangerouslyPasteHTML(prefillText);
  };

  useEffect(() => {
    const fetchType = () => {
      API({
        ...ticketCategory,
        onSuccess: (res) => {
          setTypeOptions(
            res.data.data.map((t) => ({
              value: t.type,
              label: t.type,
            }))
          );
        },
        onError: (error) => {
          console.error("Error fetching type:", error);
        },
      });
    };
    fetchType();
  }, []);

  const fetchCategories = (ticketType) => {
    API({
      ...helpSelectedDropdownRefined,
      params: { type: ticketType },
      onSuccess: (res) => {
        setCategoryOptions(
          res.data.data.category_data.map((category) => ({
            value: category.category,
            label: category.category,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (type, category) => {
    API({
      ...helpSelectedDropdownRefined,
      params: { type: type, category: category },
      onSuccess: (res) => {
        setSubcategoryOptions(
          res.data.data.subcategory_data.map((subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  // attachments
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "image/gif",
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
    ];

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Some files were not uploaded because they are not a valid type (PDF, Word, Excel, image, or video).",
        });
        return false;
      }

      if (file.size > 5 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "File Size Exceeded",
          text: "Some files were not uploaded because they exceed the maximum file size of 5MB.",
        });
        return false;
      }

      return true;
    });

    setLoading(true);
    const uploadingFiles = validFiles.map((file) => ({
      file,
      isUploading: true,
    }));
    setUploadingFiles([...uploadingFiles]);

    const uploadPromises = uploadingFiles.map((uploadingFile, index) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadingFiles((prevFiles) =>
            prevFiles.map((file, idx) =>
              idx === index ? { ...file, isUploading: false } : file
            )
          );
          resolve();
        };
        reader.readAsDataURL(uploadingFile.file);
      });
    });

    Promise.all(uploadPromises).then(() => {
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setLoading(false);
    });
  };

  const handleRemoveAttachment = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (file, index) => index !== indexToRemove
    );
    const updatedUploadingFiles = uploadingFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    setUploadingFiles(updatedUploadingFiles);
  };

  // toggle screen
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (quillRef.current && !quillRef.current.__quill) {
      const handleFileUpload = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute(
          "accept",
          "image/*,application/pdf,.doc,.docx,.xls,.xlsx,video/*"
        );
        input.click();

        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            const allowedTypes = [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "image/jpeg",
              "image/png",
              "image/gif",
              "video/mp4",
              "video/mpeg",
              "video/quicktime",
            ];

            if (!allowedTypes.includes(file.type)) {
              Swal.fire({
                icon: "error",
                title: "Invalid File Type",
                text: "Please upload a valid file type (PDF, Word, Excel, image, or video).",
              });
              return;
            }

            if (file.size > 5 * 1024 * 1024) {
              Swal.fire({
                icon: "error",
                title: "File Size Exceeded",
                text: "The file exceeds the maximum size limit of 5MB.",
              });
              return;
            }

            setLoading(true);
            const reader = new FileReader();
            reader.onload = (e) => {
              setSelectedFiles((prevFiles) => [...prevFiles, file]);
              setLoading(false);
            };
            reader.readAsDataURL(file);
          }
        };
      };

      const quill = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image", "video", "table", "code-block"],
              [{ align: [] }],
              [{ reset: "Reset" }],
            ],
            handlers: {
              reset: handleResetTicketDesc,
              image: handleFileUpload,
              file: handleFileUpload,
            },
          },
          table: true,
          tableUI: true,
        },
        placeholder: "Write your description here...",
        clipboard: {
          matchVisual: false,
        },
      });

      quillRef.current.__quill = quill;

      const customButton = quillRef.current.querySelector(".ql-reset");
      if (customButton) {
        customButton.addEventListener("click", () => {
          quill.root.innerHTML = "";
          setPageData((prevData) => ({
            ...prevData,
            ticket_desc: "",
          }));
        });
      }

      quill.on("text-change", (delta, oldDelta, source) => {
        const text = quill.getText();
        const strippedText = quill.root.innerText.trim();
        const characters = strippedText.length;

        if (characters > 5000) {
          const excessCharacters = characters - 5000;
          quill.deleteText(5000, excessCharacters);
          Swal.fire({
            icon: "warning",
            title: "Character Limit Exceeded",
            text: "You have reached the maximum character limit (5000 characters).",
          });
        }

        setPageData((prevData) => ({
          ...prevData,
          ticket_desc: quill.root.innerHTML,
        }));
        setCurrentCharacters(characters);
      });
    }
  }, []);

  const isFormValid = () => {
    return (
      pageData.subject &&
      pageData.type &&
      pageData.category &&
      pageData.subcategory &&
      pageData.ticket_desc
    );
  };

  const handleResetTicketDesc = () => {
    setPageData({
      ...pageData,
      ticket_desc: "",
    });

    const quill = quillRef.current.__quill;
    quill.setText("");
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const formData = new FormData();
    formData.append("category", pageData.category);
    formData.append("subcategory", pageData.subcategory);
    formData.append("subject", pageData.subject);
    formData.append("type", pageData.type);

    let ticketDescription = pageData.ticket_desc.replace(/<img[^>]*>/g, "");

    formData.append("ticket_desc", ticketDescription);

    selectedFiles.forEach((file) => {
      formData.append("attachments", file);
    });
    setLoading(true);

    API({
      ...addTicket,
      isFormData: true,
      body: formData,
      onSuccess: (res) => {
        setLoading(false);
        onClose();
        setTimeout(() => {
          Swal.fire({
            title: `${res.data.message}`,
          });
        }, 2000);
        setPageData({
          subject: "",
          type: "",
          ticket_desc: "",
          category: "",
          subcategory: "",
        });
        setSelectedFiles([]);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdropClassName="modal-backdrop-transparent"
      className={`modal-right help-modal ${isFullScreen ? "full-screen" : ""}`}
      style={{ marginTop: "5px" }}
    >
      <div className="d-flex align-items-center justify-content-between mx-2 py-2">
        <div></div>
        <p
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "15px",
          }}
          className="mb-0 text-center pt-2"
        >
          Hi {""}
          <span style={{ color: "#F45858", fontFamily: "Montserrat" }}>
            {username}
          </span>
          , How can we help ?
        </p>
        <img
          style={{ width: "20px", cursor: "pointer", pointerEvents: "all" }}
          src={isFullScreen ? smallScreen : fullScreen}
          alt="fullscreen"
          onClick={toggleFullScreen}
        />
      </div>
      <Modal.Body
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 10px",
        }}
        className={isFullScreen ? "mx-4" : "mx-2"}
      >
        <div className="row px-2">
          {/* type */}
          <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
            <label
              style={{
                marginTop: "4px",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="type"
              className="form-label ms-2"
            >
              Select Type
            </label>
            <Select
              id="type"
              name="type"
              placeholder="Select Type"
              value={
                pageData.type
                  ? {
                      value: pageData.type,
                      label: pageData.type,
                    }
                  : null
              }
              onChange={handleTicketTypeChange}
              options={typeOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  padding: "2px 6px",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </div>
          {/* category */}
          <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
            <label
              style={{
                marginTop: "4px",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="category"
              className="form-label ms-2"
            >
              Issue Category
            </label>
            <Select
              id="category"
              name="category"
              placeholder="Category"
              value={
                pageData.category
                  ? {
                      value: pageData.category,
                      label: pageData.category,
                    }
                  : null
              }
              onChange={handleCategoryChange}
              options={categoryOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  padding: "2px 6px",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </div>

          {/* sub category */}
          <div className={isFullScreen ? "col-3 mt-2" : "col-6 mt-2"}>
            <label
              style={{
                marginTop: "4px",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="category"
              className="form-label ms-2"
            >
              Issue Sub Category
            </label>
            <Select
              id="subcategory"
              name="subcategory"
              placeholder="Sub Category"
              value={
                pageData.subcategory
                  ? {
                      value: pageData.subcategory,
                      label: pageData.subcategory,
                    }
                  : null
              }
              onChange={handleSubcategoryChange}
              options={subcategoryOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  padding: "2px 6px",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </div>

          {/* subject */}
          <div className={isFullScreen ? "col-9 mt-2" : "col-12 mt-2"}>
            <InputForm
              type={"text"}
              placeholder={"Issue Subject"}
              id={"subject"}
              name="subject"
              value={pageData.subject}
              onChange={(e) =>
                setPageData({ ...pageData, subject: e.target.value })
              }
            />
          </div>
        </div>

        {/* description */}
        <div className="mt-2 px-2">
          <label
            style={{
              marginTop: "4px",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="ticket_desc"
            className="form-label ms-2"
          >
            Description
          </label>

          <div
            ref={quillRef}
            style={{
              backgroundColor: "#FFF6D8",
              borderRadius: "14px",
              minHeight: isFullScreen ? "257px" : "150px",
            }}
          ></div>
          <div style={{ fontSize: "14px", color: "red" }}>
            Character count: {currentCharacters}/5000
          </div>
        </div>

        {/* attachment */}
        <div className="mt-2 px-2">
          <label
            style={{
              marginTop: "8px",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="attachments"
            className="form-label ms-2"
          >
            Attach Document
          </label>
          <input
            type="file"
            id="attachments"
            name="attachments"
            multiple
            accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,video/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label
            htmlFor="attachments"
            className={"custom-upload-btn d-block col-8"}
          >
            Upload Supporting Documents
          </label>
          <div className="file-container">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="file-box"
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => handleRemoveAttachment(index)}
              >
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`file_${index}`}
                    className="uploaded-image"
                  />
                ) : (
                  <div className="file-icon">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center mt-2">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              cursor: "pointer",
            }}
            className="mb-0"
            onClick={onClose}
          >
            cancel
          </p>

          <button
            className="border-0 text-white ms-3"
            style={{
              backgroundColor: "rgba(61, 145, 137, 1)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              padding: "6px 18px",
              opacity: isFormValid() ? 1 : 0.5,
            }}
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Submit
          </button>
        </div>
      </Modal.Footer>
      {isLoading && (
        <img
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            width: "60px",
            transform: "translate(-50%)",
          }}
          src={loaderGif}
          alt="Loading..."
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  username: state.user.username,
  name: state.user.name,
});

export default connect(mapStateToProps)(HelpDeskModal);
