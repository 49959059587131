import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import API from "../components/api2";
import { forgetPassword, logout } from "../urls";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import ResetNav from "../components/ResetNav";
import { greenTick, liDot } from "../images";
import { onLogout } from "../redux/slice/userSlice";

const ForgetPassowrd = () => {
  const [pageData, setPageData] = useState({
    new_password: "",
    confirm_password: "",
    isSuccess: false,
    passwordHelpText: [
      {
        text: "Your password can’t be too similar to your other personal information.",
        matched: false,
      },
      {
        text: "Your password must contain at least 8 characters.",
        matched: false,
      },
      {
        text: "Your password can’t be a commonly used password.",
        matched: false,
      },
      { text: "Your password can’t be entirely numeric.", matched: false },
      {
        text: "Your password must contain at least 1 uppercase letter, A-Z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 lowercase letter, a-z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 symbol: ()[]{}|\\`~!@#$%^&*_-+=;:'\",<>./?",
        matched: false,
      },
    ],
  });
  const [formFilled, setFormFilled] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = location.state || {};
  const dispatch = useDispatch();

  useEffect(() => {
    validatePassword();
  }, [pageData.new_password]);

  useEffect(() => {
    const isFilled = Object.keys(pageData).every(
      (key) => typeof pageData[key] === "string" && pageData[key].trim() !== ""
    );

    setFormFilled(isFilled);

    // Check for password match only if new_password and confirm_password are not empty
    if (pageData.new_password !== "" && pageData.confirm_password !== "") {
      setPasswordsMatch(pageData.new_password === pageData.confirm_password);
    } else {
      setPasswordsMatch(false);
    }
  }, [pageData]);

  function onSubmit(e) {
    e.preventDefault();

    if (!passwordsMatch) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match",
      });
      return;
    }

    API({
      ...forgetPassword,
      body: {
        new_password: pageData.new_password,
        confirm_password: pageData.confirm_password,
        username: username,
      },

      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });

        API({
          ...logout,
          onSuccess: (res) => {
            dispatch(onLogout());
          },
          onError: (error) => {
            console.log("error");
          },
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  const handleCancel = () => {
    dispatch(onLogout());
  };
  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
    setPasswordError(null);

    if (id === "new_password" && value === "") {
      const updatedPasswordHelpText = pageData.passwordHelpText.map((item) => ({
        ...item,
        matched: false,
      }));
      setPageData((prev) => ({
        ...prev,
        passwordHelpText: updatedPasswordHelpText,
      }));
    }
  }
  const validatePassword = () => {
    const { new_password } = pageData || {};
    if (!new_password) return "New password is required";

    let passwordHelpText = [
      {
        text: "Your password can’t be too similar to your other personal information.",
        matched: false,
      },
      {
        text: "Your password must contain at least 8 characters.",
        matched: false,
      },
      {
        text: "Your password can’t be a commonly used password.",
        matched: false,
      },
      { text: "Your password can’t be entirely numeric.", matched: false },
      {
        text: "Your password must contain at least 1 uppercase letter, A-Z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 lowercase letter, a-z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 symbol: ()[]{}|\\`~!@#$%^&*_-+=;:'\",<>./?",
        matched: false,
      },
    ];

    if (new_password.length >= 8) passwordHelpText[1].matched = true;
    if (!/^[0-9]+$/.test(new_password)) passwordHelpText[3].matched = true;
    if (/[a-z]/.test(new_password)) passwordHelpText[5].matched = true;
    if (/[A-Z]/.test(new_password)) passwordHelpText[4].matched = true;
    const specialCharactersRegex = /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/;
    if (specialCharactersRegex.test(new_password))
      passwordHelpText[6].matched = true;

    if (!new_password.includes("personal information")) {
      passwordHelpText[0].matched = true;
    }

    const commonPasswords = ["password", "123456", "qwerty"];
    if (!commonPasswords.includes(new_password.toLowerCase())) {
      passwordHelpText[2].matched = true;
    }

    setPageData((prev) => ({ ...prev, passwordHelpText }));

    let allCriteriaMatched = true;

    for (let i = 0; i < passwordHelpText.length; i++) {
      if (!passwordHelpText[i].matched) {
        allCriteriaMatched = false;
        break;
      }
    }

    return allCriteriaMatched ? null : "Password does not match the criteria";
  };

  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <ResetNav />
        <div className="col-5 mx-auto">
          <form onSubmit={onSubmit} className="text-start">
            <div className="d-flex flex-column w-100">
              <Input
                type="password"
                name="new_password"
                id="new_password"
                autoComplete="off"
                showLabel={false}
                placeholder="New Password"
                valueState={pageData}
                onChange={pageDataFunc}
                className="p-2 mt-4 w-100"
                showPassIcon2={true}
              />
              <Input
                type="password"
                name="confirm_password"
                id="confirm_password"
                autoComplete="off"
                placeholder="Confirm New Password"
                valueState={pageData}
                onChange={pageDataFunc}
                className="p-2 mt-3 w-100"
                showLabel={false}
                showPassIcon2={true}
              />

              <div className="text-center mt-2">
                <button
                  type="submit"
                  className="border-0 text-white py-2 px-4 mt-2 col-5"
                  style={{
                    backgroundColor: "#194280",
                    borderRadius: "10px",
                    opacity: passwordsMatch ? 1 : 0.5,
                  }}
                  disabled={!passwordsMatch}
                >
                  Update Password
                </button>

                <p
                  onClick={handleCancel}
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    color: "rgba(26, 25, 24, 1)",
                    letterSpacing: "1px",
                    cursor: "pointer",
                  }}
                  className="mb-0 mt-1"
                >
                  Cancel
                </p>
              </div>
            </div>
          </form>
          <div
            className="py-1 px-3 mt-3"
            style={{
              border: "1px solid #B0B8CF",
              borderRadius: "10px",
              width: "600px",
              marginLeft: "-40px",
            }}
          >
            {pageData.passwordHelpText.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center mx-auto mt-1"
              >
                <img
                  src={item.matched ? greenTick : liDot}
                  alt="tick"
                  style={{ position: "absolute" }}
                />
                <span
                  style={{
                    color: "rgba(18, 33, 88, 1)",
                    fontSize: "15px",
                    paddingLeft: "30px",
                  }}
                >
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassowrd;
