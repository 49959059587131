import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { circles } from "../../../images";
import API from "../../../components/api2";
import { aepsMerchantOnBoard, aepsOnBoardStatus } from "../../../urls";
import Swal from "sweetalert2";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const OnBoarding = ({ aepsMob }) => {
  const [formData, setFormData] = useState({
    mobile_number: aepsMob,
    email: "",
  });
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    setIsFormValid(emailValid);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  // onboard status
  useEffect(() => {
    API({
      ...aepsOnBoardStatus,
      onSuccess: (res) => {
        const { data } = res.data;
        if (res.data.data.status && res.data.data.is_approved === "Accepted") {
          Swal.fire({
            title: "Onboarding Completed",
            text: "Please proceed with 2FA",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              changePageFunc();
            }
          });
        } else if (
          res.data.data.status &&
          res.data.data.is_approved === "Pending"
        ) {
          Swal.fire({
            title: "Onboarding Completed",
            text: "Please wait 6hr for AEPS Activation",
            icon: "success",
          });
        } else if (
          res.data.data.status &&
          res.data.data.is_approved === "Rejected"
        ) {
          Swal.fire({
            title: "Onboarding Rejected by bank",
            icon: "error",
          });
        } else if (
          res.data.data.status === false &&
          res.data.data.is_approved === "Pending" &&
          res.data.data.url != ""
        ) {
          Swal.fire({
            title: "Onboarding Not Completed",
            text: "You will be redirected to another page. Click OK to proceed.",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = data.url;
            }
          });
        } else {
          console.log("error");
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }, [dispatch]);

  // on boarding
  const handleContinue = (e) => {
    e.preventDefault();
    API({
      ...aepsMerchantOnBoard,
      body: formData,
      onSuccess: (res) => {
        const { data } = res.data;
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          if (data.onboard_pending === 1) {
            setTimeout(() => {
              window.location.href = data.redirecturl;
            }, 2000);
          }
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "configureBanks",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />
      <div className="my-2 bg-worker flex-grow-1 w-100 p-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1px solid rgba(217, 217, 217, 1)",
          }}
        >
          Onboarding Form
        </h2>
        <form className="col-5 mt-4 mx-auto" onSubmit={handleContinue}>
          <label
            htmlFor="mobile_number"
            className="mb-0 login_text"
            style={{
              fontWeight: "500",
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Enter your mobile number
          </label>
          <input
            type="number"
            name="mobile_number"
            id="mobile_number"
            placeholder="+91"
            className="p-2 w-100 mt-1 login_text"
            value={formData.mobile_number}
            readOnly
            onChange={handleInputChange}
            style={{
              backgroundColor: formData.mobile_number
                ? "white"
                : "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              border: formData.mobile_number
                ? "1px solid #D1D8DF"
                : "1px solid rgba(255, 231, 159, 0.4)",
            }}
          />
          <p
            className="mb-0 ms-1 trans_text"
            style={{ fontSize: "12px", fontWeight: "500" }}
          >
            Note: Register with UIDAI number
          </p>
          <label
            htmlFor="email"
            className="mb-0 mt-2 login_text"
            style={{
              fontWeight: "500",
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Enter your mail id
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="p-2 w-100 mt-1 login_text"
            value={formData.email}
            onChange={handleInputChange}
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
            style={{
              backgroundColor: isEmailFocused
                ? "white"
                : "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              border: isEmailFocused
                ? "1px solid #D1D8DF"
                : "1px solid rgba(255, 231, 159, 0.4)",
            }}
          />
          <div className="text-center col-11 mx-auto mt-4 pt-2">
            <button
              className="border-0 w-100 py-2 px-3 login_text"
              style={{
                backgroundColor: isFormValid
                  ? "rgba(22, 64, 127, 1)"
                  : "rgba(22, 64, 127, 0.5)",
                borderRadius: "10px",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "500",
                cursor: isFormValid ? "pointer" : "not-allowed",
              }}
              type="submit"
              disabled={!isFormValid}
            >
              Continue
            </button>
          </div>
        </form>

        <div className="mt-4 pt-2 col-5 mx-auto">
          <h2
            style={{
              fontWeight: "500",
              fontSize: "16px",
              textDecoration: "underline",
            }}
            className="login_text"
          >
            NOTE
          </h2>
          <ul className="ps-0 login_text" style={{ listStyleType: "none" }}>
            <li style={{ fontWeight: "500" }}>
              Onboarding state should match Aadhaar address.
            </li>
            <li className="mt-1" style={{ fontWeight: "500" }}>
              Merchant original aadhar image
            </li>
            <li className="mt-1" style={{ fontWeight: "500" }}>
              Merchant original pancard image
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  aepsMob: state.user.aepsMobile,
});

export default connect(mapStateToProps)(OnBoarding);
