import React, { useState, useEffect } from "react";
import Select from "react-select";
import { apply, download, excel, filter } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { ExportToExcel } from "./ExportToExcel";

const Filter = ({
  forPage,
  applyFilters,
  brands,
  employees,
  user,
  menus,
  roles,
  service,
  consumer,
  businessScheme,
  commission,
  apiPortal,
  merchApp,
  allMerch,
  workflows,
  endCustomer,
  tickets,
  serviceProvider,
  filteredTable,
  setFilteredTable,
  excelData,
}) => {
  const [filterValues, setFilterValues] = useState({
    brand_id: "",
    business_name: "",
    contact_email: "",
    contact_no: "",
    employee_code: "",
    employee_name: "",
    email_id: "",
    menu_id: "",
    menu_name: "",
    role_id: "",
    role_name: "",
    service_id: "",
    service_code: "",
    service_name: "",
    service_status: "",
    provider_id: "",
    service_provider_name: "",
    contact_person: "",
    contact_no: "",
    legal_name: "",
    consumer_service_id: "",
    convenience_fees: "",
    net_commission: "",
    active_status: "",
    service_type: "",
    utility_service_name: "",
    commision_type: "",
    consumer_service_name: "",
    service_type: "",
    first_name: "",
    business_name: "",
    shop_name: "",
    business_area: "",
    application_number: "",
    merchant_id: "",
    scheme_id: "",
    scheme_name: "",
    scheme_description: "",
    workflow_name: "",
    type: "",
    category: "",
    subcategory: "",
    ticket_sid: "",
    assigned_to: "",
    reported_by: "",
    customer_id: "",
    customer_type: "",
    active_status: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (id, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [id]: value,
    }));

    // employee
    if (id === "employee_code" && value !== "" && employees) {
      const selectedEmployee = employees.find(
        (employee) => employee.employee_code === value
      );
      if (selectedEmployee) {
        setFilterValues((prev) => ({
          ...prev,
          employee_name: selectedEmployee.employee_name,
        }));
      }
    }

    // user
    if (id === "employee_code" && value !== "" && user) {
      const selectedUser = user.find((u) => u.employee_code === value);
      if (selectedUser) {
        setFilterValues((prev) => ({
          ...prev,
          employee_name: selectedUser.employee_name,
        }));
      }
    }

    // menus
    if (id === "menu_id" && value !== "") {
      const selectedMenu = menus.find((menu) => menu.menu_id === value);
      if (selectedMenu) {
        setFilterValues((prev) => ({
          ...prev,
          menu_name: selectedMenu.menu_name,
        }));
      }
    }
    if (id === "menu_name" && value !== "") {
      const selectedMenu = menus.find((menu) => menu.menu_name === value);
      if (selectedMenu) {
        setFilterValues((prev) => ({
          ...prev,
          menu_id: selectedMenu.menu_id,
        }));
      }
    }

    // standard service
    if (id === "service_id" && value !== "" && service) {
      const selectedService = service.find((s) => s.service_id === value);
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          service_name: selectedService.service_name,
        }));
      }
    }
    if (id === "service_status" && value !== "") {
      setFilterValues((prev) => ({
        ...prev,
        service_status: value,
      }));
    }

    // service provider
    if (id === "provider_id" && value !== "" && serviceProvider) {
      const selectedService = serviceProvider.find(
        (s) => s.provider_id === value
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          service_provider_name: selectedService.service_provider_name,
        }));
      }
    }

    // commission
    if (id === "consumer_service_id" && value !== "" && commission) {
      const selectedCommission = commission.find(
        (c) => c.consumer_service_id === value
      );
      if (selectedCommission) {
        setFilterValues((prev) => ({
          ...prev,
          convenience_fees: selectedCommission.convenience_fees,
        }));
      }
    }

    // consumer
    if (id === "consumer_service_id" && value !== "" && consumer) {
      const selectedConsumer = consumer.find(
        (c) => c.consumer_service_id === value
      );
      if (selectedConsumer) {
        setFilterValues((prev) => ({
          ...prev,
          consumer_service_name: selectedConsumer.consumer_service_name,
        }));
      }
    }

    // api & portal
    if (id === "service_id" && value !== "" && apiPortal) {
      const selectedService = apiPortal.find((p) => p.service_id === value);
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          utility_service_name: selectedService.utility_service_name,
        }));
      }
    }

    // roles
    if (id === "role_id" && value !== "") {
      const selectedRole = roles.find((role) => role.role_id === value);
      if (selectedRole) {
        setFilterValues((prev) => ({
          ...prev,
          role_name: selectedRole.role_name,
        }));
      }
    }

    // merchant application
    if (id === "application_number" && value !== "" && merchApp) {
      const selectedMerchApp = merchApp.find(
        (c) => c.application_number === value
      );
      if (selectedMerchApp) {
        setFilterValues((prev) => ({
          ...prev,
          first_name: selectedMerchApp.first_name,
        }));
      }
    }

    // all merchant
    if (id === "merchant_id" && value !== "" && allMerch) {
      const selectedMerchApp = allMerch.find((c) => c.merchant_id === value);
      if (selectedMerchApp) {
        setFilterValues((prev) => ({
          ...prev,
          first_name: selectedMerchApp.first_name,
        }));
      }
    }

    // all business scheme
    if (id === "scheme_id" && value !== "" && businessScheme) {
      const selectedBusinessScheme = businessScheme.find(
        (c) => c.scheme_id === value
      );
      if (selectedBusinessScheme) {
        setFilterValues((prev) => ({
          ...prev,
          scheme_name: selectedBusinessScheme.scheme_name,
        }));
      }
    }

    // all workflows
    if (id === "workflow_name" && value !== "" && workflows) {
      const selectedWorkflow = workflows.find((c) => c.workflow_name === value);
      if (selectedWorkflow) {
        setFilterValues((prev) => ({
          ...prev,
          workflow_name: selectedWorkflow.workflow_name,
        }));
      }
    }

    if (id === "type" && value !== "" && workflows) {
      const selectedWorkflow = workflows.find((c) => c.type === value);
      if (selectedWorkflow) {
        setFilterValues((prev) => ({
          ...prev,
          type: selectedWorkflow.type,
        }));
      }
    }

    // tickets
    if (id === "ticket_sid" && value !== "" && tickets) {
      const selectedWorkflow = tickets.find((c) => c.ticket_sid === value);
      if (selectedWorkflow) {
        setFilterValues((prev) => ({
          ...prev,
          user: selectedWorkflow.user,
        }));
      }
    }

    // end customer
    if (id === "customer_id " && value !== "" && endCustomer) {
      const selectedCustomer = endCustomer.find((c) => c.customer_id === value);
      if (selectedCustomer) {
        setFilterValues((prev) => ({
          ...prev,
          customer_type: selectedCustomer.customer_type,
        }));
      }
    }
    if (id === "customer_id " && value !== "" && endCustomer) {
      const selectedCustomer = endCustomer.find((c) => c.customer_id === value);
      if (selectedCustomer) {
        setFilterValues((prev) => ({
          ...prev,
          active_status: selectedCustomer.active_status,
        }));
      }
    }
  };

  const handleApplyFilters = () => {
    let filtered = [];

    if (forPage === "UpdateBrand" && brands) {
      filtered = brands.filter((brand) => {
        return (
          (!filterValues.brand_id ||
            brand.brand_id === parseInt(filterValues.brand_id)) &&
          (!filterValues.business_name ||
            brand.business_name
              .toLowerCase()
              .includes(filterValues.business_name.toLowerCase())) &&
          (!filterValues.contact_email ||
            brand.contact_email
              .toLowerCase()
              .includes(filterValues.contact_email.toLowerCase())) &&
          (!filterValues.contact_no ||
            brand.contact_no.includes(filterValues.contact_no))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "UpdateEmployee" && employees) {
      filtered = employees.filter((employee) => {
        return (
          (!filterValues.employee_code ||
            employee.employee_code === filterValues.employee_code) &&
          (!filterValues.employee_name ||
            (employee.employee_name &&
              employee.employee_name
                .toLowerCase()
                .includes(filterValues.employee_name.toLowerCase()))) &&
          (!filterValues.email_id ||
            (employee.email_id &&
              employee.email_id.includes(filterValues.email_id))) &&
          (!filterValues.contact_no ||
            (employee.contact_no &&
              employee.contact_no.includes(filterValues.contact_no)))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "AllEmployeeRights" && user) {
      filtered = user.filter((u) => {
        return (
          (!filterValues.employee_code ||
            u.employee_code === filterValues.employee_code) &&
          (!filterValues.employee_name ||
            (u.employee_name &&
              u.employee_name
                .toLowerCase()
                .includes(filterValues.employee_name.toLowerCase()))) &&
          (!filterValues.email_id ||
            (u.email_id && u.email_id.includes(filterValues.email_id))) &&
          (!filterValues.contact_no ||
            (u.contact_no && u.contact_no.includes(filterValues.contact_no)))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "CommissionList" && commission) {
      filtered = commission.filter((c) => {
        return (
          (!filterValues.consumer_service_id ||
            c.consumer_service_id === filterValues.consumer_service_id) &&
          (!filterValues.active_status ||
            (c.active_status &&
              c.active_status
                .toLowerCase()
                .includes(filterValues.active_status.toLowerCase()))) &&
          (!filterValues.convenience_fees ||
            (c.convenience_fees &&
              c.convenience_fees.includes(filterValues.convenience_fees))) &&
          (!filterValues.net_commission ||
            (c.net_commission &&
              c.net_commission.includes(filterValues.net_commission)))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "ConsumerList" && consumer) {
      filtered = consumer.filter((c) => {
        return (
          (!filterValues.consumer_service_id ||
            c.consumer_service_id === filterValues.consumer_service_id) &&
          (!filterValues.consumer_service_name ||
            (c.consumer_service_name &&
              c.consumer_service_name.includes(
                filterValues.consumer_service_name
              ))) &&
          (!filterValues.service_type ||
            (c.service_type &&
              c.service_type.includes(filterValues.service_type))) &&
          (!filterValues.commision_type ||
            (c.commision_type &&
              c.commision_type
                .toLowerCase()
                .includes(filterValues.commision_type.toLowerCase()))) &&
          (!filterValues.active_status ||
            (c.active_status &&
              c.active_status
                .toLowerCase()
                .includes(filterValues.active_status.toLowerCase())))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "ApiPortalList" && apiPortal) {
      filtered = apiPortal.filter((p) => {
        return (
          (!filterValues.service_id ||
            p.service_id === filterValues.service_id) &&
          (!filterValues.service_type ||
            (p.service_type &&
              p.service_type.includes(filterValues.service_type))) &&
          (!filterValues.utility_service_name ||
            (p.utility_service_name &&
              p.utility_service_name
                .toLowerCase()
                .includes(filterValues.utility_service_name.toLowerCase()))) &&
          (!filterValues.active_status ||
            (p.active_status &&
              p.active_status
                .toLowerCase()
                .includes(filterValues.active_status.toLowerCase())))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "ServiceProviderList" && serviceProvider) {
      filtered = serviceProvider.filter((s) => {
        return (
          (!filterValues.provider_id ||
            s.provider_id === filterValues.provider_id) &&
          (!filterValues.service_provider_name ||
            (s.service_provider_name &&
              s.service_provider_name
                .toLowerCase()
                .includes(filterValues.service_provider_name.toLowerCase()))) &&
          (!filterValues.contact_person ||
            (s.contact_person &&
              s.contact_person.includes(filterValues.contact_person))) &&
          (!filterValues.contact_no ||
            s.contact_no === filterValues.contact_no) &&
          (!filterValues.legal_name ||
            (s.legal_name && s.legal_name.includes(filterValues.legal_name)))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "MenuList" && menus) {
      filtered = menus.filter((menu) => {
        return (
          (!filterValues.menu_id ||
            menu.menu_id === parseInt(filterValues.menu_id)) &&
          (!filterValues.menu_name ||
            menu.menu_name
              .toLowerCase()
              .includes(filterValues.menu_name.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "StandardServiceList" && service) {
      filtered = service.filter((s) => {
        return (
          (!filterValues.service_id ||
            s.service_id === parseInt(filterValues.service_id)) &&
          (!filterValues.service_name ||
            s.service_name
              .toLowerCase()
              .includes(filterValues.service_name.toLowerCase())) &&
          (!filterValues.service_status ||
            s.service_status === filterValues.service_status)
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "RolesList" && roles) {
      filtered = roles.filter((role) => {
        return (
          (!filterValues.role_id ||
            role.role_id === parseInt(filterValues.role_id)) &&
          (!filterValues.role_name ||
            role.role_name
              .toLowerCase()
              .includes(filterValues.role_name.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "MerchantApplications" && merchApp) {
      filtered = merchApp.filter((m) => {
        return (
          (!filterValues.application_number ||
            m.application_number ===
              parseInt(filterValues.application_number)) &&
          (!filterValues.first_name ||
            m.first_name
              .toLowerCase()
              .includes(filterValues.first_name.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "AllMerchants" && allMerch) {
      filtered = allMerch.filter((m) => {
        return (
          (!filterValues.merchant_id ||
            m.merchant_id === parseInt(filterValues.merchant_id)) &&
          (!filterValues.first_name ||
            m.first_name
              .toLowerCase()
              .includes(filterValues.first_name.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "AllBusinessScheme" && businessScheme) {
      filtered = businessScheme.filter((b) => {
        return (
          (!filterValues.scheme_id ||
            b.scheme_id === parseInt(filterValues.scheme_id)) &&
          (!filterValues.scheme_name ||
            b.scheme_name
              .toLowerCase()
              .includes(filterValues.scheme_name.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "WorkflowList" && workflows) {
      filtered = workflows.filter((b) => {
        return (
          (!filterValues.workflow_name ||
            b.workflow_name
              .toLowerCase()
              .includes(filterValues.workflow_name.toLowerCase())) &&
          (!filterValues.type ||
            b.type.toLowerCase().includes(filterValues.type.toLowerCase())) &&
          (!filterValues.category ||
            b.category
              .toLowerCase()
              .includes(filterValues.category.toLowerCase())) &&
          (!filterValues.subcategory ||
            b.subcategory
              .toLowerCase()
              .includes(filterValues.subcategory.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "AllTickets" && tickets) {
      filtered = tickets.filter((b) => {
        return (
          (!filterValues.ticket_sid ||
            b.ticket_sid === parseInt(filterValues.ticket_sid)) &&
          (!filterValues.reported_by ||
            b.reported_by
              .toLowerCase()
              .includes(filterValues.reported_by.toLowerCase())) &&
          (!filterValues.assigned_to ||
            b.assigned_to
              .toLowerCase()
              .includes(filterValues.assigned_to.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } else if (forPage === "EndCustomerList" && endCustomer) {
      filtered = endCustomer.filter((b) => {
        return (
          (!filterValues.customer_id ||
            b.customer_id === parseInt(filterValues.customer_id)) &&
          (!filterValues.customer_type ||
            b.customer_type
              .toLowerCase()
              .includes(filterValues.customer_type.toLowerCase())) &&
          (!filterValues.active_status ||
            b.active_status
              .toLowerCase()
              .includes(filterValues.active_status.toLowerCase()))
        );
      });
      if (filtered.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    }
    applyFilters(filtered);
    setFilteredTable(filtered);
  };

  useEffect(() => {
    if (filterValues.menu_id) {
      const selectedMenu = menus.find(
        (menu) => menu.menu_id === parseInt(filterValues.menu_id)
      );
      if (selectedMenu) {
        setFilterValues((prev) => ({
          ...prev,
          menu_name: selectedMenu.menu_name,
        }));
      }
    }
  }, [filterValues.menu_id, menus]);

  useEffect(() => {
    if (filterValues.role_id) {
      const selectedrole = roles.find(
        (role) => role.role_id === parseInt(filterValues.role_id)
      );
      if (selectedrole) {
        setFilterValues((prev) => ({
          ...prev,
          role_name: selectedrole.role_name,
        }));
      }
    }
  }, [filterValues.role_id, roles]);

  useEffect(() => {
    if (filterValues.employee_code && employees) {
      const selectedEmployee = employees.find(
        (employee) => employee.employee_code === filterValues.employee_code
      );
      if (selectedEmployee) {
        setFilterValues((prev) => ({
          ...prev,
          employee_name: selectedEmployee.employee_name,
        }));
      }
    }
  }, [filterValues.employee_code, employees]);

  useEffect(() => {
    if (filterValues.employee_code && user) {
      const selectedEmployee = user.find(
        (employee) => employee.employee_code === filterValues.employee_code
      );
      if (selectedEmployee) {
        setFilterValues((prev) => ({
          ...prev,
          employee_name: selectedEmployee.employee_name,
        }));
      }
    }
  }, [filterValues.employee_code, user]);

  useEffect(() => {
    if (filterValues.service_id && apiPortal) {
      const selectedService = apiPortal.find(
        (s) => s.service_id === filterValues.service_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          service_name: selectedService.service_name,
        }));
      }
    }
  }, [filterValues.service_id, apiPortal]);

  useEffect(() => {
    if (filterValues.service_id && apiPortal) {
      const selectedService = apiPortal.find(
        (s) => s.service_id === filterValues.service_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          utility_service_name: selectedService.utility_service_name,
        }));
      }
    }
  }, [filterValues.service_id, apiPortal]);

  useEffect(() => {
    if (filterValues.consumer_service_id && commission) {
      const selectedCommission = commission.find(
        (c) => c.consumer_service_id === filterValues.consumer_service_id
      );
      if (selectedCommission) {
        setFilterValues((prev) => ({
          ...prev,
          convenience_fees: selectedCommission.convenience_fees,
        }));
      }
    }
  }, [filterValues.consumer_service_id, commission]);

  useEffect(() => {
    if (filterValues.provider_id && service) {
      const selectedService = service.find(
        (s) => s.provider_id === filterValues.provider_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          service_provider_name: selectedService.service_provider_name,
        }));
      }
    }
  }, [filterValues.provider_id, service]);

  useEffect(() => {
    if (filterValues.consumer_service_id && consumer) {
      const selectedService = consumer.find(
        (s) => s.consumer_service_id === filterValues.consumer_service_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          consumer_service_name: selectedService.consumer_service_name,
        }));
      }
    }
  }, [filterValues.consumer_service_id, consumer]);

  useEffect(() => {
    if (filterValues.application_number && merchApp) {
      const selectedService = merchApp.find(
        (s) => s.application_number === filterValues.application_number
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          first_name: selectedService.first_name,
        }));
      }
    }
  }, [filterValues.application_number, merchApp]);

  useEffect(() => {
    if (filterValues.merchant_id && allMerch) {
      const selectedService = allMerch.find(
        (s) => s.merchant_id === filterValues.merchant_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          first_name: selectedService.first_name,
        }));
      }
    }
  }, [filterValues.merchant_id, allMerch]);

  useEffect(() => {
    if (filterValues.scheme_id && businessScheme) {
      const selectedService = businessScheme.find(
        (s) => s.scheme_id === filterValues.scheme_id
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          scheme_name: selectedService.scheme_name,
        }));
      }
    }
  }, [filterValues.scheme_id, businessScheme]);

  useEffect(() => {
    if (filterValues.workflow_name && workflows) {
      const selectedService = workflows.find(
        (s) => s.type === filterValues.type
      );
      if (selectedService) {
        setFilterValues((prev) => ({
          ...prev,
          type: selectedService.type,
        }));
      }
    }
  }, [filterValues.type, workflows]);

  const brandIdOptions = Array.isArray(brands)
    ? brands.map((brand) => ({
        value: brand.brand_id,
        label: brand.brand_id,
      }))
    : [];

  const businessNameOptions = Array.isArray(brands)
    ? brands.map((brand) => ({
        value: brand.business_name,
        label: brand.business_name,
      }))
    : [];

  const employeeIdOptions = employees
    ? employees.map((employee) => ({
        value: employee.employee_code,
        label: employee.employee_code,
      }))
    : [];

  const employeeNameOptions = employees
    ? employees.map((employee) => ({
        value: employee.employee_name,
        label: employee.employee_name,
      }))
    : [];

  const userIdOptions = user
    ? user.map((u) => ({
        value: u.employee_code,
        label: u.employee_code,
      }))
    : [];

  const userNameOptions = user
    ? user.map((u) => ({
        value: u.employee_name,
        label: u.employee_name,
      }))
    : [];

  const menuIdOptions = menus
    ? menus.map((menu) => ({
        value: menu.menu_id,
        label: menu.menu_id,
      }))
    : [];

  const menuNameOptions = menus
    ? menus.map((menu) => ({
        value: menu.menu_name,
        label: menu.menu_name,
      }))
    : [];
  const roleIdOptions = roles
    ? roles.map((role) => ({
        value: role.role_id,
        label: role.role_id,
      }))
    : [];

  const roleNameOptions = roles
    ? roles.map((role) => ({
        value: role.role_name,
        label: role.role_name,
      }))
    : [];

  const ssIdOptions = service
    ? service.map((s) => ({
        value: s.service_id,
        label: s.service_id,
      }))
    : [];

  const ssNameOptions = service
    ? service.map((s) => ({
        value: s.service_name,
        label: s.service_name,
      }))
    : [];

  const ssActiveOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "PLANNED", label: "PLANNED" },
    { value: "SUSPENDED", label: "SUSPENDED" },
  ];
  const spIdOptions = serviceProvider
    ? serviceProvider.map((s) => ({
        value: s.provider_id,
        label: s.provider_id,
      }))
    : [];
  const spNameOptions = serviceProvider
    ? serviceProvider.map((s) => ({
        value: s.service_provider_name,
        label: s.service_provider_name,
      }))
    : [];
  const spLegalOptions = serviceProvider
    ? serviceProvider.map((s) => ({
        value: s.legal_name,
        label: s.legal_name,
      }))
    : [];

  const commissionId = commission
    ? commission.map((c) => ({
        value: c.consumer_service_id,
        label: c.consumer_service_id,
      }))
    : [];
  const commissionActive = [
    { value: "A", label: "Active" },
    { value: "I", label: "Inactive" },
  ];
  const commissionType = [
    { value: "POST-TRANSACTION", label: "POST-TRANSACTION" },
    { value: "CHARGE-CONSUMER", label: "CHARGE-CONSUMER" },
    { value: "ADVANCE-DISCOUNT", label: "ADVANCE-DISCOUNT" },
  ];

  const apiPortalIdOptions = apiPortal
    ? apiPortal.map((c) => ({
        value: c.service_id,
        label: c.service_id,
      }))
    : [];
  const apiPortalNameOptions = apiPortal
    ? apiPortal.map((s) => ({
        value: s.utility_service_name,
        label: s.utility_service_name,
      }))
    : [];
  const consumerIdOptions = consumer
    ? consumer.map((s) => ({
        value: s.consumer_service_id,
        label: s.consumer_service_id,
      }))
    : [];
  const consumerNameOptions = consumer
    ? consumer.map((s) => ({
        value: s.consumer_service_name,
        label: s.consumer_service_name,
      }))
    : [];
  const merchAppId = merchApp
    ? merchApp.map((s) => ({
        value: s.application_number,
        label: s.application_number,
      }))
    : [];
  const merchAppName = merchApp
    ? merchApp.map((s) => ({
        value: s.first_name,
        label: s.first_name,
      }))
    : [];
  const merchAppShopName = merchApp
    ? merchApp.map((s) => ({
        value: s.shop_area,
        label: s.shop_area,
      }))
    : [];
  const allMerchId = allMerch
    ? allMerch.map((s) => ({
        value: s.merchant_id,
        label: s.merchant_id,
      }))
    : [];
  const allMerchName = allMerch
    ? allMerch.map((s) => ({
        value: s.first_name,
        label: s.first_name,
      }))
    : [];
  const allMerchShopName = allMerch
    ? allMerch.map((s) => ({
        value: s.shop_area,
        label: s.shop_area,
      }))
    : [];
  const allBSchemeId = businessScheme
    ? businessScheme.map((b) => ({
        value: b.scheme_id,
        label: b.scheme_id,
      }))
    : [];
  const allBSchemeName = businessScheme
    ? businessScheme.map((b) => ({
        value: b.scheme_name,
        label: b.scheme_name,
      }))
    : [];

  const allWorkflowName = workflows
    ? workflows.map((w) => ({
        value: w.workflow_name,
        label: w.workflow_name,
      }))
    : [];
  const allWorkflowCategory = workflows
    ? workflows.map((w) => ({
        value: w.category,
        label: w.category,
      }))
    : [];
  const allWorkflowSubCategory = workflows
    ? workflows.map((w) => ({
        value: w.subcategory,
        label: w.subcategory,
      }))
    : [];
  const allTicketId = tickets
    ? tickets.map((w) => ({
        value: w.ticket_sid,
        label: w.ticket_sid,
      }))
    : [];
  const allReporter = tickets
    ? Array.from(new Set(tickets.map((w) => w.reported_by))).map((value) => ({
        value,
        label: value,
      }))
    : [];

  const allAssigned = tickets
    ? Array.from(new Set(tickets.map((w) => w.assigned_to))).map((value) => ({
        value,
        label: value,
      }))
    : [];

  const endCustId = endCustomer
    ? endCustomer.map((e) => ({
        value: e.customer_id,
        label: e.customer_id,
      }))
    : [];

  const endCustType = [
    { value: "ORIGINAL", label: "ORIGINAL" },
    { value: "DUPLICATE", label: "DUPLICATE" },
  ];
  const endCustStatus = [
    { value: "A", label: "Active" },
    { value: "D", label: "Deactive" },
  ];

  const apiServiceType = [
    { value: "API", label: "API" },
    { value: "PORTAL", label: "PORTAL" },
  ];

  const changePageFunc = (targetPage) => {
    dispatch(
      changePage({
        page: targetPage,
      })
    );
    navigate("/", {});
  };

  return (
    <>
      <div
        className="mx-3 mt-4"
        style={{
          borderTop: "1.5px solid #D9D9D9",
          borderBottom: "2px solid #063E70",
        }}
      >
        <div
          className="px-3 mt-1 py-2 mb-4 d-flex align-items-center"
          style={{ backgroundColor: "#D9D9D9", borderTop: "1px solid #D9D9D9" }}
        >
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              color: "rgba(25, 66, 128, 1)",
            }}
            className="mb-0"
          >
            Filters
          </h3>
          <img className="ms-3" src={filter} alt="filter-icon" />
        </div>
        {(forPage === "UpdateBrand" ||
          forPage === "UpdateEmployee" ||
          forPage === "AllEmployeeRights" ||
          forPage === "StandardServiceList" ||
          forPage === "ServiceProviderList" ||
          forPage === "CommissionList" ||
          forPage === "ConsumerList" ||
          forPage === "AllMerchants" ||
          forPage === "ApiPortalList" ||
          forPage === "AllBusinessScheme" ||
          forPage === "RolesList" ||
          forPage === "WorkflowList" ||
          forPage === "MerchantApplications" ||
          forPage === "EndCustomerList" ||
          forPage === "AllTickets" ||
          forPage === "MenuList") && (
          <div className="row pb-3 px-3">
            {forPage === "UpdateBrand" && (
              <>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={brandIdOptions}
                    placeholder="Brand ID"
                    onChange={(selectedOption) =>
                      handleInputChange("brand_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={businessNameOptions}
                    placeholder="Business Name"
                    value={
                      filterValues.business_name
                        ? {
                            value: filterValues.business_name,
                            label: filterValues.business_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("business_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <input
                    type="email"
                    id="contact_email"
                    placeholder="Email-ID"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("contact_email", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "UpdateEmployee" && (
              <>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={employeeIdOptions}
                    placeholder="Employee ID"
                    onChange={(selectedOption) =>
                      handleInputChange("employee_code", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={employeeNameOptions}
                    placeholder="Employee Name"
                    value={
                      filterValues.employee_name
                        ? {
                            value: filterValues.employee_name,
                            label: filterValues.employee_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("employee_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <input
                    type="email"
                    id="email_id"
                    placeholder="Email-ID"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("email_id", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "AllEmployeeRights" && (
              <>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={userIdOptions}
                    placeholder="Employee ID"
                    onChange={(selectedOption) =>
                      handleInputChange("employee_code", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={userNameOptions}
                    placeholder="Employee Name"
                    value={
                      filterValues.employee_name
                        ? {
                            value: filterValues.employee_name,
                            label: filterValues.employee_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("employee_name", selectedOption.value)
                    }
                    isDisabled={filterValues.employee_code !== ""}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <input
                    type="email"
                    id="email_id"
                    placeholder="Email-ID"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("email_id", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "MenuList" && (
              <>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={menuIdOptions}
                    placeholder="Menu ID"
                    onChange={(selectedOption) =>
                      handleInputChange("menu_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={menuNameOptions}
                    placeholder="Menu Name"
                    value={
                      filterValues.menu_name
                        ? {
                            value: filterValues.menu_name,
                            label: filterValues.menu_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("menu_name", selectedOption.value)
                    }
                    isDisabled={filterValues.menu_id !== ""}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "RolesList" && (
              <>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={roleIdOptions}
                    placeholder="Role ID"
                    onChange={(selectedOption) =>
                      handleInputChange("role_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={roleNameOptions}
                    placeholder="Role Name"
                    value={
                      filterValues.role_name
                        ? {
                            value: filterValues.role_name,
                            label: filterValues.role_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("role_name", selectedOption.value)
                    }
                    isDisabled={filterValues.role_id !== ""}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "StandardServiceList" && (
              <>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={ssIdOptions}
                    placeholder="Service No"
                    onChange={(selectedOption) =>
                      handleInputChange("service_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={ssNameOptions}
                    placeholder="Service Name"
                    value={
                      filterValues.service_name
                        ? {
                            value: filterValues.service_name,
                            label: filterValues.service_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("service_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={ssActiveOptions}
                    placeholder="Service Status"
                    value={
                      filterValues.service_status
                        ? {
                            value: filterValues.service_status,
                            label: filterValues.service_status,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("service_status", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "ServiceProviderList" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={spIdOptions}
                    placeholder="SP ID"
                    onChange={(selectedOption) =>
                      handleInputChange("provider_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={spNameOptions}
                    placeholder="SP Name"
                    value={
                      filterValues.service_provider_name
                        ? {
                            value: filterValues.service_provider_name,
                            label: filterValues.service_provider_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "service_provider_name",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <input
                    type="text"
                    id="contact_person"
                    placeholder="Contact Person"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("contact_person", e.target.value)
                    }
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={spLegalOptions}
                    placeholder="Legal Name"
                    value={
                      filterValues.legal_name
                        ? {
                            value: filterValues.legal_name,
                            label: filterValues.legal_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("legal_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "CommissionList" && (
              <>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={commissionId}
                    placeholder="Commission Service ID"
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "consumer_service_id",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <input
                    type="text"
                    id="convenience_fees"
                    placeholder="Convenience Fee"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("convenience_fees", e.target.value)
                    }
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={commissionActive}
                    placeholder="Active Status"
                    onChange={(selectedOption) =>
                      handleInputChange("active_status", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "ApiPortalList" && (
              <>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={apiPortalIdOptions}
                    placeholder="Utility Service ID "
                    onChange={(selectedOption) =>
                      handleInputChange("service_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={apiServiceType}
                    placeholder="Service Type"
                    onChange={(selectedOption) =>
                      handleInputChange("service_type", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-3 p-0 pe-2">
                  <Select
                    options={apiPortalNameOptions}
                    placeholder="Utility Service Name"
                    value={
                      filterValues.utility_service_name
                        ? {
                            value: filterValues.utility_service_name,
                            label: filterValues.utility_service_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "utility_service_name",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col-2 p-0 pe-2">
                  <Select
                    options={commissionActive}
                    placeholder="Active Status"
                    onChange={(selectedOption) =>
                      handleInputChange("active_status", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "ConsumerList" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={consumerIdOptions}
                    placeholder="Consumer Service ID "
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "consumer_service_id",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={consumerNameOptions}
                    placeholder="Service Name"
                    value={
                      filterValues.consumer_service_name
                        ? {
                            value: filterValues.consumer_service_name,
                            label: filterValues.consumer_service_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "consumer_service_name",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>

                <div className="col p-0 pe-2">
                  <Select
                    options={commissionType}
                    placeholder="Commission Type"
                    onChange={(selectedOption) =>
                      handleInputChange(" commision_type", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={commissionActive}
                    placeholder="Service Status"
                    onChange={(selectedOption) =>
                      handleInputChange("active_status", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "MerchantApplications" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={merchAppId}
                    placeholder="Application Number"
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "application_number",
                        selectedOption.value
                      )
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={merchAppName}
                    placeholder="Merchant Name"
                    value={
                      filterValues.first_name
                        ? {
                            value: filterValues.first_name,
                            label: filterValues.first_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("first_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>

                <div className="col p-0 pe-2">
                  <Select
                    options={merchAppShopName}
                    placeholder="Shop Name"
                    onChange={(selectedOption) =>
                      handleInputChange("shop_area", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <input
                    type="text"
                    id="business_area"
                    placeholder="Business Area"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("business_area", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "AllMerchants" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={allMerchId}
                    placeholder="Merchant ID"
                    onChange={(selectedOption) =>
                      handleInputChange("merchant_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allMerchName}
                    placeholder="Merchant Name"
                    value={
                      filterValues.first_name
                        ? {
                            value: filterValues.first_name,
                            label: filterValues.first_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("first_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>

                <div className="col p-0 pe-2">
                  <Select
                    options={allMerchShopName}
                    placeholder="Shop Name"
                    onChange={(selectedOption) =>
                      handleInputChange("shop_area", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <input
                    type="text"
                    id="business_area"
                    placeholder="Business Area"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("business_area", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "AllBusinessScheme" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={allBSchemeId}
                    placeholder="Scheme ID"
                    onChange={(selectedOption) =>
                      handleInputChange("scheme_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allBSchemeName}
                    placeholder="Scheme Name"
                    value={
                      filterValues.scheme_name
                        ? {
                            value: filterValues.scheme_name,
                            label: filterValues.scheme_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("scheme_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <input
                    type="text"
                    id="active_status"
                    placeholder="Business Scheme Status"
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("active_status", e.target.value)
                    }
                  />
                </div>
              </>
            )}
            {forPage === "WorkflowList" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={allWorkflowName}
                    placeholder="Workflow Name"
                    value={
                      filterValues.workflow_name
                        ? {
                            value: filterValues.workflow_name,
                            label: filterValues.workflow_name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("workflow_name", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    placeholder="Type"
                    value={
                      filterValues.type
                        ? {
                            value: filterValues.type,
                            label: filterValues.type,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("type", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allWorkflowCategory}
                    placeholder="Category"
                    value={
                      filterValues.category
                        ? {
                            value: filterValues.category,
                            label: filterValues.category,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("category", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allWorkflowSubCategory}
                    placeholder="Sub Category"
                    value={
                      filterValues.subcategory
                        ? {
                            value: filterValues.subcategory,
                            label: filterValues.subcategory,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("subcategory", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "AllTickets" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={allTicketId}
                    placeholder="Ticket ID"
                    onChange={(selectedOption) =>
                      handleInputChange("ticket_sid", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allReporter}
                    placeholder="Reporter"
                    value={
                      filterValues.reported_by
                        ? {
                            value: filterValues.reported_by,
                            label: filterValues.reported_by,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("reported_by", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={allAssigned}
                    placeholder="Assigned to"
                    value={
                      filterValues.assigned_to
                        ? {
                            value: filterValues.assigned_to,
                            label: filterValues.assigned_to,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("assigned_to", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage === "EndCustomerList" && (
              <>
                <div className="col p-0 pe-2">
                  <Select
                    options={endCustId}
                    placeholder="Customer ID"
                    onChange={(selectedOption) =>
                      handleInputChange("customer_id", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={endCustType}
                    placeholder="Customer Type"
                    value={
                      filterValues.customer_type
                        ? {
                            value: filterValues.customer_type,
                            label: filterValues.customer_type,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("customer_type", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
                <div className="col p-0 pe-2">
                  <Select
                    options={endCustStatus}
                    placeholder="Status"
                    value={
                      filterValues.active_status
                        ? {
                            value: filterValues.active_status,
                            label: filterValues.active_status,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("active_status", selectedOption.value)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        width: "100%",
                        border: "none",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            {forPage != "MenuList" &&
              forPage != "RolesList" &&
              forPage != "CommissionList" &&
              forPage != "ApiPortalList" &&
              forPage != "AllBusinessScheme" &&
              forPage != "ConsumerList" &&
              forPage != "MerchantApplications" &&
              forPage != "AllMerchants" &&
              forPage != "WorkflowList" &&
              forPage != "AllTickets" &&
              forPage != "EndCustomerList" &&
              forPage != "StandardServiceList" && (
                <div className="col p-0 pe-2">
                  <input
                    type="number"
                    id="contact_no"
                    placeholder="Mobile No."
                    style={{
                      fontSize: "15px",
                      width: "100%",
                      padding: "8px 5px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                    }}
                    onChange={(e) =>
                      handleInputChange("contact_no", e.target.value)
                    }
                  />
                </div>
              )}
            <div className="col-1 p-0">
              <img
                onClick={handleApplyFilters}
                style={{ cursor: "pointer", pointerEvents: "all" }}
                src={apply}
                alt="apply"
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2 px-3">
        <ExportToExcel apiData={excelData} fileName={forPage} />
        {forPage !== "AllMerchants" &&
          forPage !== "AllTickets" &&
          forPage !== "AllEmployeeRights" && (
            <div
              style={{
                border: "1.3px solid black",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (forPage === "UpdateBrand") {
                  changePageFunc("addBusinessBrand");
                } else if (forPage === "UpdateEmployee") {
                  changePageFunc("newEmployee");
                } else if (forPage === "AllEmployeeRights") {
                  changePageFunc("newEmployee");
                } else if (forPage === "MenuList") {
                  changePageFunc("newMenu");
                } else if (forPage === "StandardServiceList") {
                  changePageFunc("addStandardService");
                } else if (forPage === "ServiceProviderList") {
                  changePageFunc("addServiceProvider");
                } else if (forPage === "CommissionList") {
                  changePageFunc("addCommission");
                } else if (forPage === "ApiPortalList") {
                  changePageFunc("addApiPortal");
                } else if (forPage === "ConsumerList") {
                  changePageFunc("addConsumerService");
                } else if (forPage === "MerchantApplications") {
                  changePageFunc("newMerchant");
                } else if (forPage === "AllBusinessScheme") {
                  changePageFunc("newBusinessPlan");
                } else if (forPage === "WorkflowList") {
                  changePageFunc("addWorkflow");
                } else if (forPage === "EndCustomerList") {
                  changePageFunc("newEndCustomer");
                } else if (forPage === "RolesList") {
                  changePageFunc("addRole");
                }
              }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "10px",
                }}
                className="mb-0 p-2"
              >
                {forPage === "UpdateBrand" && "ADD BRAND"}
                {forPage === "UpdateEmployee" && "ADD EMPLOYEE"}
                {forPage === "AllEmployeeRights" && "ADD EMPLOYEE"}
                {forPage === "MenuList" && "ADD MENU"}
                {forPage === "RolesList" && "ADD ROLE"}
                {forPage === "StandardServiceList" && "ADD Standard Service"}
                {forPage === "ServiceProviderList" && "ADD Service Provider"}
                {forPage === "CommissionList" && "ADD Commission"}
                {forPage === "ApiPortalList" && "ADD Utility Service"}
                {forPage === "ConsumerList" && "ADD Consumer Service"}
                {forPage === "MerchantApplications" && "ADD Application"}
                {forPage === "AllBusinessScheme" && "ADD Business Scheme"}
                {forPage === "WorkflowList" && "ADD Workflow"}
                {forPage === "EndCustomerList" && "ADD End Customer"}
              </p>
            </div>
          )}
      </div>
    </>
  );
};

export default Filter;
