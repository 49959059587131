import React, { useEffect, useState, useRef } from "react";
import Button from "../components/Button";
import API from "../components/api2";
import { verifyMailOTP, generateOTP } from "../urls";
import Input from "../components/Input";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { onVerifyLogin } from "../redux/slice/userSlice";
import ResetNav from "../components/ResetNav";

const OtpVerifyForget = ({ duid }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timerSeconds, setTimerSeconds] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRefs = useRef(Array(6).fill(null));
  const [reqId, setReqId] = useState(null);
  const { redirectPage, reqId: initialReqId } = location.state || {};
  const { name } = location.state || {};
  const typedEmail = location.state ? location.state.typedEmail : null;
  const [pageData, setPageData] = useState({ pinHelpText: [] });
  const { username } = location.state || {};

  useEffect(() => {
    let timerInterval;

    if (isResendDisabled) {
      timerInterval = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
            setIsResendDisabled(false);
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isResendDisabled]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (initialReqId) {
      setReqId(initialReqId);
    }
  }, [initialReqId]);

  useEffect(() => {
    const isFilled = otp.every((digit) => digit !== "");
    setIsOtpFilled(isFilled);
  }, [otp]);

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join("");
    API({
      ...verifyMailOTP,
      body: {
        duid,
        username,
        request_id: reqId,
        otp: enteredOTP,
      },
      onSuccess: (res) => {
        let successMessage = res.data.message;

        if (typedEmail) {
          successMessage = "Email verified successfully";
        }

        Swal.fire({
          icon: "success",
          title: successMessage,
        });

        changePageFunc();
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleResendOTP = () => {
    setIsResendDisabled(true);
    setTimerSeconds(30);
    API({
      ...generateOTP,
      onSuccess: (res) => {
        const newRequestId = res.data.data.request_id;
        setReqId(newRequestId);
        Swal.fire({
          icon: "success",
          title: `OTP sent again`,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsResendDisabled(false);
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "validateQuesForget",
      })
    );

    navigate("/", {
      state: {
        username,
        name,
      },
    });
  };

  const handleInputChange = (index, value, event) => {
    value = value.replace(/[^0-9]/g, "");

    const updatedOtp = [...otp];
    updatedOtp[index] = value.charAt(0);
    setOtp(updatedOtp);

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = value.charAt(0);
    }

    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (event.key === "Backspace" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleCancel = () => {
    dispatch(
      changePage({
        page: "login",
      })
    );
    navigate("/login");
  };

  return (
    <div className="px-4">
      <ResetNav />
      <div className="mx-auto text-center col-6 mt-4">
        <p
          style={{
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(79, 87, 91, 1)",
            letterSpacing: "1px",
          }}
        >
          Please enter the One-Time Password to <br /> verify account
        </p>
        <p
          className="mt-2"
          style={{
            fontWeight: "400",
            fontSize: "15px",
            color: "rgba(79, 87, 91, 1)",
            letterSpacing: "1px",
          }}
        >
          OTP sent to your <br />
          <span style={{ fontWeight: "600" }}>
            {typedEmail ? typedEmail : "registered email ID"}
          </span>
        </p>
        <div className="mt-5">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="number"
              maxLength="1"
              pattern="[0-9]*"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value, e)}
              onKeyDown={(e) => handleInputChange(index, digit, e)}
              style={{
                width: "30px",
                margin: "0 5px",
                textAlign: "center",
                fontSize: "20px",
                outline: "none",
              }}
              className="borderBottom"
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </div>
        <div className="text-center mt-4">
          <button
            type="submit"
            className="border-0 text-white py-1 px-4 mt-4 col-5"
            style={{
              backgroundColor: "#194280",
              borderRadius: "10px",
              fontSize: "18px",
              opacity: isOtpFilled ? 1 : 0.5,
              pointerEvents: isOtpFilled ? "auto" : "none",
            }}
            disabled={!isOtpFilled}
            onClick={handleVerifyOTP}
          >
            Verify
          </button>
          <p
            onClick={handleCancel}
            style={{
              fontWeight: "400",
              fontSize: "17px",
              color: "rgba(26, 25, 24, 1)",
              letterSpacing: "1px",
              cursor: "pointer",
            }}
            className="mb-0 mt-2"
          >
            Cancel
          </p>
          <div className="mt-2">
            {!isResendDisabled && (
              <>
                <button
                  className="bg-transparent border-0"
                  onClick={handleResendOTP}
                  disabled={isResendDisabled}
                  style={{
                    opacity: isResendDisabled ? 0.5 : 1,
                    cursor: "pointer",
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "400",
                  }}
                >
                  Didn't receive any code?{" "}
                  <span
                    style={{
                      color: "rgba(249, 124, 75, 1)",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    Resend OTP
                  </span>
                </button>
              </>
            )}
            {isResendDisabled && (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "rgba(118, 127, 131, 1)",
                }}
              >
                Resend OTP in {timerSeconds} seconds
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerifyForget;
