import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateBusinessScheme,
  allBusinessScheme,
  schemeActive,
  allotedServices,
  getBusinessScheme,
} from "../urls";
import API from "../components/api2";
import Button from "../components/Button";
import { active, circles, inactive } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Alert from "../components/Alert";
import Swal from "sweetalert2";

const BusinessSchemeInfo = () => {
  const location = useLocation();
  const { service } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [businessScheme, setBusinessScheme] = useState([]);
  const [schemeType, setSchemeType] = useState([]);
  const [allotedSchemes, setAllotedSchemes] = useState([]);
  const [availServicesList, setAvailServicesList] = useState([]);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [editableFields, setEditableFields] = useState({
    scheme_id: "",
    scheme_name: "",
    scheme_description: "",
  });

  // alert
  const hideAlert = () => {
    setShowAlertActive(false);
    setShowAlertUpdate(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  // fetch schemes by scheme id
  useEffect(() => {
    const fetchSchemes = () => {
      API({
        ...allBusinessScheme,
        params: { scheme_id: service.scheme_id },
        onSuccess: (res) => {
          const tableData = res.data.data;
          setBusinessScheme(tableData);
          setSchemeType(tableData.type);
          setEditableFields(tableData);
        },
        onError: (error) => {
          console.error("Error fetching business schemes: ", error);
        },
      });
    };

    fetchSchemes();
  }, []);
  useEffect(() => {
    fetchAllotedServices();
  }, [schemeType]);

  // get all available services
  useEffect(() => {
    const getAvailServices = () => {
      API({
        ...getBusinessScheme,
        onSuccess: (res) => {
          setAvailServicesList(res.data.data);
        },
        onError: (error) => {
          console.log("error");
        },
      });
    };

    getAvailServices();
  }, []);

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allBusinessScheme",
      })
    );

    navigate("/");
  };

  const { active_status } = businessScheme;
  const initialIsActive = active_status !== "A";

  const [isActive, setIsActive] = useState(initialIsActive);
  useEffect(() => {
    setIsActive(active_status !== "A");
  }, [active_status]);

  // active inactive
  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? 1 : 0;
    API({
      ...schemeActive,
      body: {
        scheme_id: service.scheme_id,
        active_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The service has been successfully ${
            isActive === 0 ? "Deactivate" : "Activate"
          } `,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };
  // fetch alloted services
  const fetchAllotedServices = () => {
    API({
      ...allotedServices,
      params: {
        scheme_id: service.scheme_id,
        type: schemeType,
      },
      onSuccess: (res) => {
        setAllotedSchemes(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching alloted services: ", error);
      },
    });
  };

  // handle update
  const handleUpdate = () => {
    const { created_by, created_on, updated_by, updated_on, ...updateData } =
      editableFields;

    const assignedServiceIds = allotedSchemes.map((s) => s.service_id);
    API({
      ...updateBusinessScheme,
      body: { ...updateData, api_service_ids: assignedServiceIds },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // sending scheme from avail to assign and vice versa
  const handleAssignScheme = (scheme) => {
    const schemeExists = allotedSchemes.some(
      (assignedScheme) =>
        assignedScheme.utility_service_name === scheme.utility_service_name
    );

    if (!schemeExists) {
      const updatedAssignedSchemes = [...allotedSchemes, scheme];
      setAllotedSchemes(updatedAssignedSchemes);

      const updatedAvailableSchemes = availServicesList.filter(
        (s) => s.utility_service_name !== scheme.utility_service_name
      );
      setAvailServicesList(updatedAvailableSchemes);

      setSelectedScheme(scheme);
    } else {
      const updatedAvailableSchemes = [...availServicesList, scheme];
      const updatedAssignedSchemes = allotedSchemes.filter(
        (assignedScheme) =>
          assignedScheme.utility_service_name !== scheme.utility_service_name
      );
      setAvailServicesList(updatedAvailableSchemes);
      setAllotedSchemes(updatedAssignedSchemes);

      // Resetting the selected scheme
      setSelectedScheme(null);
    }
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Business Scheme Information
          </h2>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "80px" }}
            onClick={handleClickActive}
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={isActive}
                onChange={handleToggleSwitch}
              />
              <span
                className="slider round"
                data-content={sliderContent}
                style={{
                  color: isActive ? "#AAAEB0" : "#AAAEB0",
                  fontWeight: "500",
                  paddingLeft: isActive ? "80px" : "10px",
                  paddingTop: "2px",
                }}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
            <img
              className="ms-2 toggleUser"
              src={imageSource}
              alt={isActive ? "Inactive" : "Active"}
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                pointerEvents: "all",
              }}
            />
          </div>
          <Alert
            show={showAlertActive}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            term="Business Scheme"
            forTerm=" "
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
        </div>

        {/* Information  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Scheme Info
          </h3>

          <div className="row justify-content-between mt-4 px-5">
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Scheme ID
              </p>

              <p
                className="mb-0 mt-1"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                {editableFields.scheme_id}
              </p>
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Scheme Name
              </p>
              <input
                type="text"
                name="scheme_name"
                className="p-2 w-100 mt-1"
                value={editableFields.scheme_name}
                onChange={(e) =>
                  handleInputChange("scheme_name", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Scheme Description
              </p>
              <input
                type="text"
                name="scheme_description"
                className="p-2 w-100 mt-1"
                value={editableFields.scheme_description}
                onChange={(e) =>
                  handleInputChange("scheme_description", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
          </div>
        </div>

        {/* assign activity  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="px-3 pb-4"
        >
          <div
            style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}
            className="d-flex py-1 px-3 mt-3 align-items-center justify-content-between mt-4"
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 "
            >
              Assigned Activity
            </p>
            <div className="px-3 py-1"></div>
          </div>
          <div className="row d-flex justify-content-between mx-2 mt-4">
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Available Services
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAvailable}
                    onChange={(e) => setSearchTermAvailable(e.target.value)}
                    placeholder="Search Available Services"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div
                  style={{ width: "100%", overflowY: "auto", height: "225px" }}
                >
                  {(() => {
                    const filteredSchemes = availServicesList
                      .filter((scheme) => {
                        return !allotedSchemes.some(
                          (assignedScheme) =>
                            assignedScheme.utility_service_name ===
                            scheme.utility_service_name
                        );
                      })
                      .filter((scheme) => {
                        const searchTermWords = searchTermAvailable
                          .toLowerCase()
                          .split(" ");
                        return searchTermWords.every((word) =>
                          scheme.utility_service_name
                            .toLowerCase()
                            .includes(word)
                        );
                      });

                    if (filteredSchemes.length === 0) {
                      return (
                        <div className="my-3 px-3" style={{ color: "red" }}>
                          No available services
                        </div>
                      );
                    }

                    return filteredSchemes.map((scheme, index) => (
                      <div
                        key={index}
                        onClick={() => handleAssignScheme(scheme)}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredSchemes.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {scheme.utility_service_name
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    ));
                  })()}
                </div>
              </div>
            </div>
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Assigned Services
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAssigned}
                    onChange={(e) => setSearchTermAssigned(e.target.value)}
                    placeholder="Search Assigned Services"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    height: "225px",
                  }}
                >
                  <div>
                    {allotedSchemes.length > 0 ? (
                      allotedSchemes
                        .filter((scheme) => {
                          const searchTermWords = searchTermAssigned
                            .toLowerCase()
                            .split(" ");
                          return searchTermWords.every((word) =>
                            scheme.utility_service_name
                              .toLowerCase()
                              .includes(word)
                          );
                        })
                        .map((scheme, index) => (
                          <div
                            key={index}
                            onClick={() => handleAssignScheme(scheme)}
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              borderBottom:
                                index !== allotedSchemes.length - 1
                                  ? "1px solid #D9D9D9"
                                  : "none",
                            }}
                          >
                            <p
                              className="mb-0 mt-2"
                              style={{
                                padding: "4px 8px",
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "13px",
                                color: "rgba(105, 102, 102, 1)",
                              }}
                            >
                              {scheme.utility_service_name
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </p>
                          </div>
                        ))
                    ) : (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No assigned services
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Audit info  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mx-1 mt-4 pb-4"
        >
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              color: "#696666",
            }}
            className="mb-0 py-1 px-3 mx-1"
          >
            Audit Info
          </p>
          <div className="row mt-3 mx-2">
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created By: {businessScheme.created_by}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created On: {formatDate(businessScheme.created_on)}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified By: {businessScheme.updated_by}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified On: {formatDate(businessScheme.updated_on)}
              </p>
            </div>
          </div>
        </div>

        {/* comment  */}
        <div className="mt-4 pb-3">
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Comment
          </h3>
          <div
            className="mt-4 mx-5 px-5 py-3"
            style={{
              backgroundColor: "rgba(231, 232, 233, 1)",
              height: "260px",
              borderRadius: "14px",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  User
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Comment
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Commented on
                </p>
              </div>
            </div>
          </div>
          <div className="px-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 mt-3 ms-3 mb-1"
            >
              Comment
            </p>
            <div
              style={{
                backgroundColor: "#FFF6D8",
                borderRadius: "14px",
                border: "1.5px solid #D9D9D9",
              }}
            >
              <div className="d-flex align-items-center py-2">
                <textarea
                  className="w-100 px-3"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "14px",
                  }}
                  name=""
                  placeholder="Write"
                  id=""
                  rows="4"
                ></textarea>
                <button
                  style={{
                    borderRadius: "24px",
                    backgroundColor: "#FFF6D8",
                    border: "1px solid black",
                  }}
                  className="mx-3 px-3 py-1"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-itmes-center mt-5 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button children={"Update"} onClick={handleClickUpdate} />
          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText="update"
            message={alertTextUpdate}
            walletColor={walletColor}
            onConfirm={handleUpdate}
            forTerm=" "
            term="Business Scheme"
          />
        </div>
      </div>
    </>
  );
};

export default BusinessSchemeInfo;
