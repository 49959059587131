import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateWorkflow,
  getWorkflow,
  workflowActive,
  helpDeskCategory,
  getAssignEmp,
} from "../urls";
import API from "../components/api2";
import Button from "../components/Button";
import {
  active,
  add,
  circles,
  dashed,
  dashed2,
  inactive,
  removeStep,
  stepAdd,
} from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Alert from "../components/Alert";
import Swal from "sweetalert2";

const WorkflowInfo = () => {
  const location = useLocation();
  const { service } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [businessScheme, setBusinessScheme] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [removedSteps, setRemovedSteps] = useState([]);
  const [editableFields, setEditableFields] = useState({
    workflow_name: "",
    description: "",
    type: "",
    category: "",
    subcategory: "",
    assigned_to: [],
    tat: "",
    escalation1: "",
    escalation2: "",
    escalation3: "",
    escalation1_tat: "",
    escalation2_tat: "",
    escalation3_tat: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [tabData, setTabData] = useState({
    0: {
      stepData: {
        escalation1: null,
        escalation1_tat: "",
        escalation2: null,
        escalation2_tat: "",
        escalation3: null,
      },
      selectedEmployees: [],
    },
  });

  // alert ***************************
  const hideAlert = () => {
    setShowAlertActive(false);
    setShowAlertUpdate(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  // ************************************************

  // category,subcategory and type dropdown ********
  const fetchCategories = () => {
    API({
      ...helpDeskCategory,
      params: { type: editableFields.type },
      onSuccess: (res) => {
        const categories = res.data.data.category_data.map((category) => ({
          value: category.category,
          label: category.category,
        }));
        setCategoryOptions([
          { value: "", label: "Select Category" },
          ...categories,
        ]);
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (category) => {
    API({
      ...helpDeskCategory,
      params: { type: editableFields.type, category },
      onSuccess: (res) => {
        const subcategories = res.data.data.subcategory_data.map(
          (subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          })
        );
        setSubcategoryOptions([
          { value: "", label: "Select Sub Category" },
          ...subcategories,
        ]);
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  useEffect(() => {
    if (editableFields?.type) {
      fetchCategories();
    }
  }, [editableFields?.type]);

  useEffect(() => {
    if (editableFields?.type && editableFields?.category) {
      fetchSubcategories(editableFields.category);
    }
  }, [editableFields?.type, editableFields?.category]);
  // get category and sub category
  useEffect(() => {
    API({
      ...helpDeskCategory,
      onSuccess: (res) => {
        const category = res.data.data.map((c) => ({
          value: c.category,
          label: c.category,
        }));
        setCategoryOptions(category);
        const subCategory = res.data.data.map((c) => ({
          value: c.subcategory,
          label: c.subcategory,
        }));
        setSubcategoryOptions(subCategory);
      },
      onError: (error) => {
        console.error("Error fetching categories: ", error);
      },
    });
  }, []);

  // ************************************************

  const handleInputChange = (fieldName, value, activeTab = false) => {
    if (activeTab !== false) {
      let newSteps = [...editableFields.steps];
      newSteps[activeTab] = { ...newSteps[activeTab], [fieldName]: value };

      setEditableFields((prevFields) => ({
        ...prevFields,
        steps: newSteps,
      }));
    } else {
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: value,
      }));
    }
  };

  // fetch workflow by workflow id *****************
  useEffect(() => {
    const fetchWorkflow = () => {
      API({
        ...getWorkflow,
        params: { workflow_sid: service.workflow_sid },
        onSuccess: (res) => {
          const workflowData = res.data.data;
          setBusinessScheme(workflowData);
          setEditableFields(workflowData);
          renderContent();
        },
        onError: (error) => {
          console.error("Error fetching workflow: ", error);
        },
      });
    };
    fetchWorkflow();
  }, []);

  // ************************************************

  // steps *****************************
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    if (
      editableFields &&
      editableFields.steps &&
      editableFields.steps.length > 0
    ) {
      const { steps } = editableFields;
      const newTabs = steps.map((_, index) => ({
        id: index + 1,
        title: `Step ${index + 1}`,
      }));
      setTabs(newTabs);
    }
  }, [editableFields]);

  // handle tab click
  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setTabData((prevData) => ({
        ...prevData,
        [activeTab]: {
          pageData: { ...editableFields },
          selectedEmployees: [...selectedEmployees],
        },
      }));

      setActiveTab(index);
      setSelectedEmployees(editableFields.steps[index]?.assigned_to || []);
    }
  };
  useEffect(() => {
    if (
      editableFields &&
      editableFields.steps &&
      editableFields.steps.length > 0
    ) {
      setSelectedEmployees(editableFields.steps[activeTab]?.assigned_to || []);
    }
  }, [editableFields, activeTab]);

  // add step
  const handleAddStep = () => {
    const newStepId = editableFields.steps.length + 1;
    const newStep = {
      step_id: newStepId,
      step_name: "",
      assigned_to: [],
      tat: "",
      escalation1: "",
      escalation1_tat: "",
      escalation2: "",
      escalation2_tat: "",
      escalation3: "",
      escalation3_tat: "",
    };

    setTabs((prevTabs) => [
      ...prevTabs,
      { id: newStepId, title: `Step ${newStepId}` },
    ]);

    setEditableFields((prevFields) => ({
      ...prevFields,
      steps: [...prevFields.steps, newStep],
    }));
  };

  // remove step
  const handleRemoveStep = (indexToRemove) => {
    setEditableFields((prevFields) => {
      const newSteps = prevFields.steps.filter(
        (_, index) => index !== indexToRemove
      );
      return { ...prevFields, steps: newSteps };
    });

    setTabs((prevTabs) => {
      const newTabs = prevTabs.map((tab, index) => {
        if (index >= indexToRemove) {
          return { ...tab, id: index + 1, title: `Step ${index + 1}` };
        }
        return tab;
      });
      return newTabs;
    });

    setTabData((prevData) => {
      const newData = { ...prevData };
      delete newData[indexToRemove];

      const updatedData = Object.keys(newData).reduce((acc, key) => {
        const newIndex = parseInt(key, 10);
        if (newIndex > indexToRemove) {
          acc[newIndex - 1] = newData[key];
        } else {
          acc[newIndex] = newData[key];
        }
        return acc;
      }, {});
      return updatedData;
    });

    setActiveTab((prevActiveTab) => {
      return Math.min(prevActiveTab, Math.max(0, indexToRemove - 1));
    });
  };

  // ************************************************

  //get employee options
  useEffect(() => {
    API({
      ...getAssignEmp,
      onSuccess: (res) => {
        const emp = res.data.data.map((e) => ({
          value: e.employee_code,
          label: `${e.employee_code}-${e.employee_name}`,
        }));
        setEmpOptions(emp);
      },
      onError: (error) => {
        console.error("Error employee: ", error);
      },
    });
  }, []);

  const handleAddEmployee = () => {
    setSelectedEmployees([...selectedEmployees, {}]);
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allWorkflows",
      })
    );

    navigate("/");
  };

  // active inactive **************************
  const { active_status } = businessScheme;
  const initialIsActive = active_status !== "A";

  const [isActive, setIsActive] = useState(initialIsActive);
  useEffect(() => {
    const { active_status } = businessScheme;
    setIsActive(active_status !== "A");
  }, [businessScheme]);

  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? 1 : 0;
    API({
      ...workflowActive,
      body: {
        workflow_name: service.workflow_name,
        active_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The workflow has been successfully ${
            isActive === 0 ? "Deactivate" : "Activate"
          } `,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };
  // ************************************************

  // handle update
  const handleUpdate = () => {
    const filteredSteps = editableFields.steps.filter(
      (_, index) => !removedSteps.includes(index)
    );
    const updatedSteps = filteredSteps.map((step, index) => ({
      ...step,
      assigned_to: [...step.assigned_to, ...selectedEmployees],
    }));

    const updatedData = {
      ...editableFields,
      steps: updatedSteps,
    };

    API({
      ...updateWorkflow,
      body: updatedData,
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // render content
  const renderContent = () => {
    const { steps } = editableFields;

    if (!editableFields || !steps || steps.length === 0) {
      return (
        <div
          style={{ fontFamily: "Montserrat", color: "rgba(105, 102, 102, 1)" }}
          className="pt-5 ms-5"
        >
          No steps available
        </div>
      );
    }

    const step = steps[activeTab];
    if (!step) {
      return (
        <div
          style={{ fontFamily: "Montserrat", color: "rgba(105, 102, 102, 1)" }}
          className="pt-5 ms-5"
        >
          Step not found
        </div>
      );
    }
    return (
      <>
        <div key={step.step_id}>
          {/* step name  */}
          <div className="mx-4 px-4 mt-4 col-4">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Step Name
            </p>
            <input
              type="text"
              name="step_name"
              className="p-2 w-100 mt-1"
              value={step.step_name}
              onChange={(e) =>
                handleInputChange("step_name", e.target.value, activeTab)
              }
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
            />
          </div>
          {/* / assign to  */}
          <div
            className="mt-5 px-5 py-4 mx-5 position-relative dash_line"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assign To
              </h3>
              <div className="col-6">
                <div
                  className="col-6 mx-auto"
                  style={{
                    border: "1.5px solid rgba(105, 102, 102, 1)",
                    borderRadius: "10px",
                  }}
                  onClick={handleAddEmployee}
                >
                  <div
                    className="d-flex align-items-center justify-content-center py-1 px-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={add} alt="add-employee" />
                    <p
                      className="mb-0 ms-2"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "rgba(105, 102, 102, 1)",
                      }}
                    >
                      {" "}
                      Add Employee
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginBottom: "-5px",
                }}
                htmlFor="assigned_to"
                className="form-label"
              >
                Select Employee
              </label>
              <div className="d-flex row justify-content-between">
                {selectedEmployees.map((employee, index) => (
                  <div className="col-6" key={index}>
                    <select
                      id={`assigned_to_${index}`}
                      name={`assigned_to_${index}`}
                      value={employee}
                      onChange={(e) => {
                        const updatedEmployees = [...selectedEmployees];
                        updatedEmployees[index] = e.target.value;
                        setSelectedEmployees(updatedEmployees);
                      }}
                      className="p-2 w-100 mt-3"
                      style={{
                        backgroundColor: "rgba(255, 231, 159,0.4)",
                        borderRadius: "10px",
                        marginTop: "8px",
                        color: "black",
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Montserrat",
                        outline: "none",
                        padding: "12px 8px",
                      }}
                    >
                      <option disabled value="">
                        Select employee
                      </option>
                      {empOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-4"></div>
          </div>

          {/* tat  */}
          <div
            className="mt-5 px-5 py-4 mx-5 position-relative dash_line2"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>
            <div className="row mt-4">
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                >
                  TAT (HH:MM)
                </p>
                <input
                  type="time"
                  name="tat"
                  className="p-2 w-100 mt-1"
                  value={step.tat}
                  onChange={(e) =>
                    handleInputChange("tat", e.target.value, activeTab)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
          </div>
          {/* escalation 1 */}
          <div className="position-relative ">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-5%",
                width: "420px",
              }}
              src={dashed}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 1
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative dash_line"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assgin To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation1`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <select
                    name="escalation1"
                    value={step.escalation1}
                    onChange={(e) =>
                      handleInputChange(
                        "escalation1",
                        e.target.value,
                        activeTab
                      )
                    }
                    className="p-2 w-100 mt-1"
                    style={{
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      marginTop: "8px",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      width: "100%",
                      fontFamily: "Montserrat",
                      outline: "none",
                      padding: "12px 8px",
                    }}
                  >
                    <option disabled value="">
                      Select employee
                    </option>
                    {empOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="col-8 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                If ticket status not closed in
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    TAT (HH:MM)
                  </p>
                  <input
                    type="time"
                    name="escalation1_tat"
                    className="p-2 w-100 mt-1"
                    value={step.escalation1_tat}
                    onChange={(e) =>
                      handleInputChange(
                        "escalation1_tat",
                        e.target.value,
                        activeTab
                      )
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* escalation 2  */}
          <div className="position-relative ">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-5%",
                width: "420px",
              }}
              src={dashed}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 2
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative dash_line"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assgin To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation2`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <select
                    name="escalation2"
                    value={step.escalation2}
                    onChange={(e) =>
                      handleInputChange(
                        "escalation2",
                        e.target.value,
                        activeTab
                      )
                    }
                    className="p-2 w-100 mt-1"
                    style={{
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      marginTop: "8px",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      width: "100%",
                      fontFamily: "Montserrat",
                      outline: "none",
                      padding: "12px 8px",
                    }}
                  >
                    <option disabled value="">
                      Select employee
                    </option>
                    {empOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="col-8 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                If ticket status not closed in
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    TAT (HH:MM)
                  </p>
                  <input
                    type="time"
                    name="escalation2_tat"
                    className="p-2 w-100 mt-1"
                    value={step.escalation2_tat}
                    onChange={(e) =>
                      handleInputChange(
                        "escalation2_tat",
                        e.target.value,
                        activeTab
                      )
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* escalation 3  */}
          <div className="position-relative">
            <img
              style={{
                position: "absolute",
                right: "25%",
                top: "-14%",
                width: "420px",
              }}
              src={dashed2}
              alt="dashed"
            />
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                right: "10%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              className="mb-0 position-absolute"
            >
              Escalation 3
            </p>
            <div
              className="col-8 px-5 py-4 mx-5 position-relative"
              style={{
                border: "1.5px dashed rgba(105, 102, 102, 1)",
                marginTop: "80px",
              }}
            >
              <h3
                className="mb-0"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Assgin To
              </h3>
              <div className="row mt-4">
                <div className="col-6">
                  <label
                    style={{
                      marginTop: "8px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                      fontFamily: "Montserrat",
                    }}
                    htmlFor={`escalation3`}
                    className="form-label"
                  >
                    Select Employee
                  </label>
                  <select
                    name="escalation3"
                    value={step.escalation3}
                    onChange={(e) =>
                      handleInputChange(
                        "escalation3",
                        e.target.value,
                        activeTab
                      )
                    }
                    className="p-2 w-100 mt-1"
                    style={{
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      marginTop: "8px",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                      width: "100%",
                      fontFamily: "Montserrat",
                      outline: "none",
                      padding: "12px 8px",
                    }}
                  >
                    <option disabled value="">
                      Select employee
                    </option>
                    {empOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-auto my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Edit Workflow
          </h2>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "80px" }}
            onClick={handleClickActive}
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={isActive}
                onChange={handleToggleSwitch}
              />
              <span
                className="slider round"
                data-content={sliderContent}
                style={{
                  color: isActive ? "#AAAEB0" : "#AAAEB0",
                  fontWeight: "500",
                  paddingLeft: isActive ? "80px" : "10px",
                  paddingTop: "2px",
                }}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
            <img
              className="ms-2 toggleUser"
              src={imageSource}
              alt={isActive ? "Inactive" : "Active"}
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                pointerEvents: "all",
              }}
            />
          </div>
          <Alert
            show={showAlertActive}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            term="workflow"
            forTerm=" "
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
        </div>

        {/* workflow details */}
        <div
          className="py-2 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
            marginLeft: "-12px",
          }}
        >
          Workflow Details
        </div>
        <div className="row justify-content-between mt-4 px-5">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Name
            </p>
            <input
              type="text"
              name="workflow_name"
              className="p-2 w-100 mt-1"
              value={
                editableFields && editableFields.workflow_name
                  ? editableFields.workflow_name
                  : ""
              }
              onChange={(e) =>
                handleInputChange("workflow_name", e.target.value)
              }
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
            />
          </div>
          <div className="col-12">
            <label
              className="mb-0 mt-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              className="w-100 p-2 mt-2"
              style={{
                outline: "none",
                backgroundColor: "#FFF6D8",
                resize: "none",
                border: "none",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                fontSize: "14px",
              }}
              spellCheck={false}
              name="description"
              id="description"
              value={
                editableFields && editableFields.description
                  ? editableFields.description
                  : ""
              }
              onChange={(e) => handleInputChange("description", e.target.value)}
              rows="7"
            ></textarea>
          </div>
        </div>

        {/* rules  */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
            marginLeft: "-12px",
          }}
        >
          Rules
        </div>

        {/* rules part 1  type, category, subcategory  */}
        <div className="m-4 px-5">
          <div className="row">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="type"
                className="form-label ms-2"
              >
                Type
              </label>
              <select
                name="type"
                value={
                  editableFields && editableFields.type
                    ? editableFields.type
                    : ""
                }
                onChange={(e) => handleInputChange("type", e.target.value)}
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value="Software">Software</option>
                <option value="Hardware">Hardware</option>
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="category"
                className="form-label ms-2"
              >
                Category
              </label>

              <select
                name="category"
                value={
                  editableFields && editableFields.category
                    ? editableFields.category
                    : ""
                }
                onChange={(e) => handleInputChange("category", e.target.value)}
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                {categoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="subcategory"
                className="form-label ms-2"
              >
                Sub Category
              </label>
              <select
                name="subcategory"
                value={
                  editableFields && editableFields.subcategory
                    ? editableFields.subcategory
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("subcategory", e.target.value)
                }
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                {subcategoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* worflow tabs  */}
        <div className="pt-4">
          <h2
            className="mx-4 px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontSize: "16px",
            }}
          >
            Workflow Steps:
          </h2>
          <div className="mx-4 mt-3 px-4">
            {tabs.map((tab, index) => (
              <button
                style={{
                  border: "1.2px solid rgba(105, 102, 102, 1)",
                  borderTop: "1.2px solid rgba(105, 102, 102, 1)",
                  borderLeft: "1.2px solid rgba(105, 102, 102, 1)",
                  borderRight: "1.2px solid rgba(105, 102, 102, 1)",
                  width: "120px",
                  borderBottom:
                    index === activeTab
                      ? "none"
                      : "1.2px solid rgba(105, 102, 102, 1)",
                  borderRadius: index === activeTab ? "5px 5px 0 0" : "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(105, 102, 102, 1)",
                  padding: index === activeTab ? "10px" : "6px 10px",
                }}
                key={index}
                className="me-2 bg-white position-relative"
                onClick={() => handleTabClick(index)}
              >
                {tab.title}

                {index > 0 || tabs.length > 1 ? (
                  <img
                    style={{
                      marginTop: "-5px",
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    src={removeStep}
                    className="ms-2"
                    alt="step-remove"
                    onClick={() => handleRemoveStep(index)}
                  />
                ) : null}

                {index === activeTab && (
                  <div className="workflow_selected_tab" />
                )}
              </button>
            ))}

            <img
              style={{
                height: "36px",
                cursor: "pointer",
                pointerEvents: "all",
                marginTop: "-5px",
              }}
              src={stepAdd}
              alt="add-step"
              onClick={() => handleAddStep()}
            />
          </div>
        </div>

        {/* content  */}
        <div>{renderContent()}</div>

        {/* buttons  */}
        <div className="d-flex justify-content-between align-itmes-center mt-5 pt-4 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button children={"Update"} onClick={handleClickUpdate} />
          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText="update"
            message={alertTextUpdate}
            walletColor={walletColor}
            onConfirm={handleUpdate}
            forTerm=" "
            term="Workflow"
          />
        </div>
      </div>
    </>
  );
};

export default WorkflowInfo;
