import React from "react";

const Button = ({ children, style, onClick, disabled }) => {
  const defaultStyles = {
    cursor: "pointer",
    appearance: "none",
    color: "white",
    backgroundColor: "rgba(22, 64, 127, 1)",
    border: "0",
    fontWeight: "500",
    fontSize: "16px",
    borderRadius: "10px",
    border: "1px solid #194280",
    fontFamily: "Montserrat",
  };
  const buttonStyles =
    children === "Back"
      ? {
          ...defaultStyles,
          backgroundColor: "white",
          color: "rgba(22, 64, 127, 1)",
        }
      : { ...defaultStyles, ...style };

  return (
    <button
      onClick={onClick}
      style={buttonStyles}
      className="py-1 px-4 text-center login_text"
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
