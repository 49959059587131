import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateQues, validateAns, resetPassword, logout } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import ResetNav from "../components/ResetNav";
import { resetPass } from "../images";
import { onLogout } from "../redux/slice/userSlice";

export const ValidateQuesForget = () => {
  const location = useLocation();
  const { username } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [requestId, setRequestId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const fetchData = () => {
    API({
      ...validateQues,
      params: { username },
      onSuccess: (res) => {
        const questionsData = res.data.data;
        const filteredQuestions = Object.entries(questionsData)
          .filter(([key]) => !key.startsWith("request_id"))
          .map(([_, value]) => value);
        setQuestions(filteredQuestions);
        setAnswers(Array(filteredQuestions.length).fill(""));
        setRequestId(questionsData.request_id);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleAnswerChange = (index, event) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);
    setIsButtonDisabled(newAnswers.some((answer) => answer.trim() === ""));
  };

  const handleSubmit = () => {
    if (answers.some((answer) => answer.trim() === "")) {
      Swal.fire({
        icon: "error",
        title: "Please provide answers for all questions.",
      });
      return;
    }

    const formattedAnswers = answers.reduce((acc, curr, index) => {
      acc[`answer${index + 1}`] = curr.trim();
      return acc;
    }, {});
    const payload = {
      username,
      request_id: requestId,
      ...formattedAnswers,
    };

    API({
      ...validateAns,
      body: payload,
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        changePageFunc();
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const changePageFunc = (reqId) => {
    dispatch(
      changePage({
        page: "forgetPassword",
        reqId: reqId,
      })
    );
    navigate("/", {
      state: {
        username,
        reqId,
      },
    });
  };
  const handleCancel = () => {
    dispatch(
      changePage({
        page: "login",
      })
    );
    navigate("/login");
  };

  // reset password
  const handleReset = () => {
    API({
      ...resetPassword,
      body: { username: username },
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        API({
          ...logout,
          onSuccess: (res) => {
            dispatch(onLogout());
            dispatch(changePage({ page: "login" }));
          },
          onError: (error) => {
            console.log("error");
          },
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };
  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <ResetNav />
        <div className="col-7 mx-auto mt-4">
          <h2
            className="px-5"
            style={{
              fontWeight: "500",
              fontSize: "18px",
              textAlign: "center",
              letterSpacing: "3px",
              color: "rgba(79, 87, 91, 1)",
            }}
          >
            Give the answer in the below
          </h2>
          <ul style={{ listStyleType: "none" }} className="mt-2 col-8 mx-auto">
            {questions.map((question, index) => (
              <>
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(255, 114, 94, 1)",
                    letterSpacing: "1px",
                  }}
                  className="mb-0 mt-2"
                >
                  Security Question {index + 1}:
                </p>
                <li
                  style={{
                    color: "#194280",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  key={index}
                >
                  {question}
                  <input
                    type="text"
                    className="w-100 p-2"
                    autoComplete="off"
                    placeholder="Answer"
                    style={{ backgroundColor: "rgba(217, 217, 217, 1)" }}
                    name={`answer${index + 1}`}
                    id={`answer${index + 1}`}
                    value={answers[index]}
                    onChange={(event) => handleAnswerChange(index, event)}
                    onClick={(event) =>
                      (event.target.style.backgroundColor = "white")
                    }
                  />
                </li>
              </>
            ))}
          </ul>

          <div className="text-center position-relative">
            <p
              className="mb-0 position-absolute px-2 py-1 reset_hvr"
              style={{
                fontWeight: "500",
                fontSize: "16px",
                left: "-30%",
                bottom: "34px",
                border: "1.5px solid black",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={handleReset}
            >
              Request Password Reset
              <div
                style={{
                  width: "250px",
                  left: "-25%",
                  top: "-200%",
                  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  opacity: "0",
                }}
                className="d-flex align-items-center justify-content-between position-absolute reset_desc"
              >
                <img
                  style={{ width: "60px" }}
                  src={resetPass}
                  alt="reset-password"
                />
                <p
                  style={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    lineHeight: "16px",
                  }}
                  className="mb-0 ms-2"
                >
                  "Forgot your security questions? Submit a ticket to reset your
                  password."
                </p>
              </div>
            </p>
            <button
              type="submit"
              className="border-0 text-white py-2 px-4 col-4"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity: isButtonDisabled ? 0.5 : 1,
              }}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              Submit
            </button>
            <p
              onClick={handleCancel}
              style={{
                fontWeight: "400",
                fontSize: "17px",
                color: "rgba(26, 25, 24, 1)",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              className="mb-0 mt-2"
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
