import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addCommissions, consumerDropdown } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const NewCommission = () => {
  const [pageData, setPageData] = useState({});
  const [consIdOption, setConsIdOption] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }
  useEffect(() => {
    API({
      ...consumerDropdown,
      onSuccess: (res) => {
        const combinedOptions = res.data.data.consumer_service_names.map(
          (c) => ({
            value: c.consumer_service_id,
            label: `${c.consumer_service_id} - ${c.consumer_service_name}`,
          })
        );
        setConsIdOption(combinedOptions);
      },
    });
  }, []);
  // validation
  const validationRules = {
    consumer_service_id: "consumer service id",
    convenience_fees: "convenience fees",
    slab_from_amount: "slab from amount",
    slab_to_amount: "slab to amount",
    net_commission: "net commission",
    net_expense: "net expense",
    additional_surcharge: "additional surcharge",
  };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      API({
        ...addCommissions,
        body: pageData,
        onSuccess: (res) => {
          Swal.fire({
            title: `${res.data.message}`,
          });
          setTimeout(() => {
            changePageFunc();
          }, 2000);
        },

        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "getCommissions",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Commission
        </h2>
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Commission Info
        </div>
        <div className="px-5 container-fluid">
          <div className="row mt-3">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="consumer_service_id"
                className="form-label"
              >
                Consumer Service ID
              </label>
              <Select
                id={"consumer_service_id"}
                name={"consumer_service_id"}
                placeholder="Select Consumer Service"
                options={consIdOption}
                valueState={pageData.consumer_service_id}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    consumer_service_id: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["consumer_service_id"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["consumer_service_id"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["consumer_service_id"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["consumer_service_id"]}
                </div>
              )}
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Convenience Fees"}
                id={"convenience_fees"}
                name="convenience_fees"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Slab From Amount"}
                id={"slab_from_amount"}
                name="slab_from_amount"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Slab To Amount"}
                id={"slab_to_amount"}
                name="slab_to_amount"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Net Commission"}
                id={"net_commission"}
                name="net_commissionn"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Net Expense"}
                id={"net_expense"}
                name="net_expense"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Additional Surcharge"}
                id={"additional_surcharge"}
                name="additional_surcharge"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <Button onClick={changePageFunc} children={"Back"} />
            <Button onClick={onSubmit} children={"Submit"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCommission;
