import React, { useEffect, useState } from "react";
import { actionIcon } from "../../../images";
import { IoPersonAddSharp } from "react-icons/io5";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { endCustDropdown } from "../../../urls";
import API from "../../../components/api2";
import loaderGif from "../../../gif/buffer.gif";

const CustomerList = ({ handleCustomerClick }) => {
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get end customer
  useEffect(() => {
    setLoading(true);
    API({
      ...endCustDropdown,
      onSuccess: (res) => {
        setLoading(false);
        setCustomer(res.data.data);
      },
      onError: (error) => {
        setLoading(false);
        console.log("error fetching end customers", error);
      },
    });
  }, []);

  // Filter customers based on search term
  const filteredCustomers = customer.filter(
    (c) =>
      c.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.contact_no.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "newEndCustomer",
      })
    );

    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-8 mx-auto">
          <div className="p-3 rounded shadow-sm border">
            <div
              style={{ backgroundColor: "rgba(25, 66, 128, 1)" }}
              className="px-3 py-2 rounded-top text-white"
            >
              <div className="d-flex row justify-content-between align-items-center">
                <div className="col-3">
                  <h2 className="fs-5 mb-0">Customer List</h2>
                </div>
                <div className="col-6">
                  <input
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Search customers..."
                    className="w-100"
                    style={{
                      borderRadius: "10px",
                      padding: "6px 12px",
                    }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="col-3 text-end">
                  <IoPersonAddSharp
                    onClick={changePageFunc}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <img
                className="position-absolute"
                style={{
                  height: "50px",
                  width: "50px",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                src={loaderGif}
                alt="Loading..."
              />
            ) : filteredCustomers.length === 0 ? (
              <div style={{ fontSize: "17px" }} className="text-center mt-3">
                No Customers found, Please add a customer
              </div>
            ) : (
              <div className="table-container overflow-y-auto">
                <table className="table table-striped mb-0">
                  <thead
                    style={{ border: "1px solid black" }}
                    className="bg-light"
                  >
                    <tr>
                      <th className="ps-4">Name</th>
                      <th className="ps-4">Mobile</th>
                      <th className="ps-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCustomers.map((c) => (
                      <tr key={c.customer_id}>
                        <td className="ps-4">{c.first_name}</td>
                        <td className="ps-4">{c.contact_no}</td>
                        <td style={{ paddingLeft: "35px" }}>
                          <img
                            style={{ pointerEvents: "all", cursor: "pointer" }}
                            src={actionIcon}
                            alt="Action"
                            className="action-icon"
                            onClick={() => handleCustomerClick(c.contact_no)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
