import React, { useEffect, useState } from "react";
import { getProfileRole, getAssignEmp } from "../../urls";
import API from "../../components/api2";
import { actionIcon, circles } from "../../images";
import PaginationTable from "../../components/PaginationTable";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import Filter2 from "../../components/Filter2";

const ManageProfileRole = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [dropdown, setDropdown] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchProfileRole = ({
    filterValues = {},
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...getProfileRole,
      params: { ...filteredObject },
      onSuccess: (res) => {
        if (resolve) {
          resolve(res.data.data);
        } else {
          if (append) {
            setFilteredTable((prev) => [...prev, ...res.data.data]);
          } else {
            setFilteredTable(res.data.data);
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching profile role: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    API({
      ...getAssignEmp,
      onSuccess: (res) => {
        setDropdown({
          employeeCodes: res.data.data.employee_code,
          employeeNames: res.data.data.employee_name,
        });
      },
      onError: (error) => {
        console.error("Error fetching profile role: ", error);
      },
    });
  }, []);

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "editProfileRole",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Profile Roles
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchProfileRole}
          filters={[
            {
              placeholder: "Profile Code",
              id: "profile_code",
              filterType: "select",
              inputType: null,
              options: dropdown?.employeeCodes?.map((code) => ({
                value: code,
                label: code,
              })),
            },
            {
              placeholder: "Profile Name",
              id: "profile_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.employeeNames?.map((name) => ({
                value: name,
                label: name,
              })),
            },
          ]}
          showExcelIcon={false}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addProfileRole" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD PROFILE ROLE"}
            </p>
          </div>
        </Filter2>

        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            { Header: "Profile Code", accessor: "profile_code" },
            { Header: "Profile Name", accessor: "profile_name" },
            { Header: "Profile Role", accessor: "profile_role" },
          ]}
          data={filteredTable}
          skipPageReset={true}
          setPagination={setPagination}
        />
      </div>
    </>
  );
};

export default ManageProfileRole;
