import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import API from "../components/api2";
import { changePassword } from "../urls";
import Swal from "sweetalert2";
import LoginNav from "../components/LoginNav";
import { useLocation, useNavigate } from "react-router-dom";

const ChangePassowrd = () => {
  const [pageData, setPageData] = useState({});
  const [formFilled, setFormFilled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { password } = location.state || {};
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    return setPageData((prev) => ({ ...prev, [id]: value }));
  }

  useEffect(() => {
    const isFilled = Object.values(pageData).every((val) => val.trim() !== "");
    setFormFilled(isFilled);

    if (pageData.new_password !== pageData.confirm_password) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  }, [pageData]);

  function onSubmit(e) {
    e.preventDefault();

    const { new_password, confirm_password } = pageData;

    if (new_password !== confirm_password) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match",
      });
      return;
    }

    API({
      ...changePassword,
      body: {
        new_password,
        confirm_password,
        old_password: password,
      },
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        navigate("/login");
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.res.data.message}`,
        });
      },
    });
  }

  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <LoginNav />

        <div className="mt-5 col-6 mx-auto">
          <h2
            style={{
              fontWeight: "500",
              fontSize: "26px",
              textAlign: "center",
              letterSpacing: "3px",
              color: "rgba(79, 87, 91, 1)",
            }}
          >
            Change your password
          </h2>
          <form onSubmit={onSubmit} className="pt-4 text-start">
            <div className="d-flex flex-column w-100">
              <Input
                type="password"
                name="new_password"
                id="new_password"
                autoComplete="off"
                showLabel={false}
                placeholder="New Password"
                valueState={pageData}
                onChange={pageDataFunc}
                className="p-2 mt-4 w-100"
                showPassIcon2={true}
              />
              <Input
                type="password"
                name="confirm_password"
                id="confirm_password"
                autoComplete="off"
                placeholder="Confirm New Password"
                valueState={pageData}
                onChange={pageDataFunc}
                className="p-2 mt-4 w-100"
                showLabel={false}
                showPassIcon2={true}
              />
              {!passwordsMatch && (
                <p style={{ color: "red" }}>Passwords do not match</p>
              )}
              <div className="text-center">
                <button
                  type="submit"
                  className="border-0 text-white py-2 px-4 mt-4 col-6"
                  style={{
                    backgroundColor: "#194280",
                    borderRadius: "10px",
                    opacity: formFilled && passwordsMatch ? 1 : 0.5,
                    pointerEvents:
                      formFilled && passwordsMatch ? "auto" : "none",
                  }}
                  disabled={!formFilled || !passwordsMatch}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ChangePassowrd;
