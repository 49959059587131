import React from "react";
import Input from "../components/Input";
import API from "../components/api2";
import { resetPassword } from "../urls";
import Button from "../components/Button";
import Select from "../components/Select";

const ResetPassword = () => {
  const [pageData, setPageData] = React.useState({});

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  function onSubmit(e) {
    e.preventDefault();

    API({
      ...resetPassword,
      body: pageData,
      onSuccess: (res) => {
        console.log("Password changed successfully");
      },
      onError: (error) => {
        alert(error.response.data.message);
      },
    });
  }
  return (
    <>
      <form onSubmit={onSubmit} className=" text-start">
        <div
          style={{ maxWidth: "600px" }}
          className="bg-worker mt-5 px-5 mx-auto py-4 table-container2"
        >
          <h2 className="fw-bold fs-4 text-center color-primary underline">
            Reset Password
          </h2>
          <Select
            id={"selectUsername"}
            name={"selectUsername"}
            value={pageData}
            onChange={pageDataFunc}
            placeholder={"Select Username"}
            style={{
              borderRadius: "7px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              border: "1px solid #ccc",
              padding: "12px 4px",
            }}
          />

          <Input
            type={"password"}
            id={"newPassword"}
            name={"newPassword"}
            valueState={pageData}
            placeholder={"New Password"}
            style={{
              borderRadius: "7px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              border: "1px solid #ccc",
              width: "100%",
            }}
            onChange={pageDataFunc}
          />
          <Input
            type={"password"}
            id={"newUpin"}
            name={"newUpin"}
            placeholder={"New UPIN"}
            valueState={pageData}
            onChange={pageDataFunc}
            style={{
              borderRadius: "7px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />

          <div className="text-end ">
            <Button children={"Save"} />
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
