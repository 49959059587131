import React from "react";
import Slider from "react-slick";
import { slider1, slider2 } from "../../../../images";
import leftArrow from "../../../../images/services/travel/busOfferLeftArrow.svg";
import rightArrow from "../../../../images/services/travel/busOfferRightArrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Previous Arrow
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={leftArrow} alt="prev" />
    </div>
  );
}

// Custom Next Arrow
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={rightArrow} alt="next" />
    </div>
  );
}

export default function SeasonalOffers() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings}>
      <div className="h-100">
        <img style={{ width: "230px" }} src={slider1} alt="1" />
      </div>
      <div className="h-100">
        <img style={{ width: "230px" }} src={slider2} alt="2" />
      </div>
      <div className="h-100">
        <img style={{ width: "230px" }} src={slider1} alt="1" />
      </div>
      <div className="h-100">
        <img style={{ width: "230px" }} src={slider2} alt="2" />
      </div>
    </Slider>
  );
}
