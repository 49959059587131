import React from "react";

const Select = ({
  id,
  name,
  onChange,
  options,
  value,
  required = false,
  style = { backgroundColor: "#FFF6D8", borderRadius: "10px" },
  placeholder,
}) => {
  const selectedValue = value !== undefined ? value : "";

  return (
    <div className="d-block mt-2">
      <label
        htmlFor={id}
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "rgba(105, 102, 102, 1)",
          fontFamily: "Montserrat",
        }}
      >
        {placeholder}
      </label>
      <div className="mt-2" style={style}>
        <select
          id={id}
          name={name}
          value={selectedValue}
          onChange={onChange}
          required={required}
          style={style}
          className="w-100"
        >
          <option value="" disabled={!required}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              style={{ fontFamily: "Montserrat" }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Select.defaultProps = {
  options: [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
  ],
};

export default Select;
