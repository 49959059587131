import React, { useEffect, useState } from "react";
import { newLogo } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mailVerification, mailCheck } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mail, setMail] = useState();
  const [verifiedMail, setVerifiedMail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const fetchMail = () => {
    API({
      ...mailVerification,
      onSuccess: (res) => {
        setMail(res.data.data.mail);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  useEffect(() => {
    fetchMail();
  }, []);

  const handleVerifyMail = () => {
    API({
      ...mailCheck,
      body: {
        is_email_verified: verifiedMail,
      },
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        changePageFunc(res.data.data.request_id);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };
  const changePageFunc = (reqId) => {
    console.log(reqId);
    dispatch(
      changePage({
        page: "verifyMailOtp",
        reqId: reqId,
      })
    );
    navigate("/", {
      state: {
        typedEmail: verifiedMail,
        reqId,
      },
    });
  };
  const handleInputChange = (e) => {
    setVerifiedMail(e.target.value);
    setIsButtonDisabled(e.target.value === "");
  };

  return (
    <section className="position-relative">
      <div className="px-4 d-flex justify-content-center">
        <img
          className="py-2 position-absolute"
          style={{ maxWidth: "200px", top: "0", left: "20px" }}
          src={newLogo}
          alt="logo"
        />
        <div style={{ backgroundColor: "#D9D9D9" }}>
          <div className="bg-white mx-5 mt-2 px-4 pb-5">
            <h2
              className="px-5"
              style={{
                fontWeight: "500",
                fontSize: "26px",
                textAlign: "center",
                letterSpacing: "3px",
                color: "rgba(79, 87, 91, 1)",
                paddingTop: "150px",
              }}
            >
              Verify your email address
            </h2>
            <hr />
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                letterSpacing: "1px",
                color: "rgba(142, 147, 158, 1)",
                width: "527px",
              }}
              className="mb-0 mt-4 text-center px-3"
            >
              Please confirm that you want to use this account email address.
              Once it’s done you will be get OTP.{" "}
            </p>
            <p
              style={{
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "1px",
              }}
              className="mb-0 mt-4 text-center px-3"
            >
              {mail}
            </p>
            <div className="mx-3" style={{ borderBottom: "1.5px solid black" }}>
              <input
                type="text"
                name="is_email_verified"
                id="is_email_verified"
                placeholder="Re-Type mail address"
                value={verifiedMail}
                onChange={handleInputChange}
                className="p-2 mt-4 w-100"
              />
            </div>
            <div className="text-center mt-4">
              <button
                type="submit"
                className="border-0 text-white py-2 px-4 mt-4 col-6"
                style={{
                  backgroundColor: "#194280",
                  borderRadius: "10px",
                  opacity: isButtonDisabled ? 0.5 : 1,
                }}
                disabled={isButtonDisabled}
                onClick={handleVerifyMail}
              >
                Verify Email Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
