import React, { useEffect, useState } from "react";
import { getCommissionStruct } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";

const CommissionStructure = () => {
  const [commissionData, setCommissionData] = useState([]);
  const [commissionKeys, setCommissionKeys] = useState([]);

  useEffect(() => {
    API({
      ...getCommissionStruct,
      onSuccess: (res) => {
        const data = res.data.data;
        setCommissionData(data);

        if (data.length > 0) {
          const keys = Object.keys(data[0]).filter(
            (key) => key !== "service_name"
          );
          setCommissionKeys(keys);
        }
      },
      onError: (error) => {
        console.error("Error fetching commission structure: ", error);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative shadow-sm"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Commission Structure
          </h2>
        </div>

        {/* Commission Table */}
        <div className="table-responsive mt-5 mx-4">
          <table
            style={{ border: "1px solid #D9D9D9" }}
            className="table table-striped table-bordered table-hover"
          >
            <thead>
              <tr>
                {commissionData.length > 0 && <th>SERVICE NAME</th>}
                {commissionKeys.map((key, index) => (
                  <th key={index}>{key.replace(/_/g, " ").toUpperCase()}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ border: "1px solid #D9D9D9" }}>
              {commissionData.length > 0 ? (
                commissionData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.service_name.replace(/_/g, " ")}</td>
                    {commissionKeys.map((key, subIndex) => (
                      <td key={subIndex}>
                        {item[key] ? (
                          item[key]
                        ) : (
                          <span className="text-muted">N/A</span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={commissionKeys.length + 1}
                    className="text-center"
                  >
                    <span className="text-muted">No data available</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CommissionStructure;
