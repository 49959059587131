import React, { useEffect, useState } from "react";
import Select from "react-select";
import { aepsOnBoardStatus } from "../../../urls";
import API from "../../../components/api2";

const PipeSelector = ({ onSelectBank }) => {
  const [pipe, setPipe] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  useEffect(() => {
    API({
      ...aepsOnBoardStatus,
      onSuccess: (res) => {
        const fetchedBanks = Object.keys(res.data.data.banklist)
          .map((key) => ({
            key,
            name: res.data.data.banklist[key].bank_name,
            onboardingStatus: res.data.data.banklist[key].onboarding_status,
            twoFA: res.data.data.banklist[key]["2fa"],
          }))
          .filter((bank) => bank.twoFA);

        const pipeOptions = fetchedBanks.map((bank) => ({
          value: bank.key,
          label: bank.name,
        }));

        setPipe(pipeOptions);

        if (pipeOptions.length > 0) {
          setSelectedBank(pipeOptions[1]);
          onSelectBank(pipeOptions[1]);
        }
      },
      onError: (error) => {
        console.log("Error fetching pipes: ", error);
      },
    });
  }, []);

  const handleBankSelection = (selectedOption) => {
    setSelectedBank(selectedOption);
    onSelectBank(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "none",
      backgroundColor: "#194280",
      color: "white",
      boxShadow: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#007bff" : "white",
      color: state.isFocused ? "white" : "black",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#0056b3",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(255, 255, 255, 0.7)",
    }),
  };

  return (
    <div>
      <label
        style={{ fontWeight: "500", color: "#194280", letterSpacing: "1px" }}
      >
        Select a Bank
      </label>
      <Select
        options={pipe}
        placeholder="Select a bank"
        onChange={handleBankSelection}
        styles={customStyles}
        value={selectedBank}
      />
    </div>
  );
};

export default PipeSelector;
