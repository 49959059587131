import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Button from "../components/Button";
import { connect, useDispatch } from "react-redux";
import { changePage, setBackPage } from "../redux/slice/dashboardSlice";
import { setAmount } from "../redux/slice/paymentSlice";

const RechargeMerchant = ({ name, username }) => {
  const [rechargeAmount, setRechargeAmount] = useState("");

  useEffect(() => {}, []);

  const handleRechargeAmountChange = (event) => {
    setRechargeAmount(event.target.value);
  };

  const dispatch = useDispatch();
  const handleRecharge = (event) => {
    dispatch(
      setBackPage({
        backPage: "rechargeMerchant",
      })
    );
    dispatch(
      changePage({
        page: "payment",
      })
    );

    dispatch(
      setAmount({
        amount: rechargeAmount,
      })
    );

    event.preventDefault();
    if (!rechargeAmount) {
      return;
    }

    setRechargeAmount("");
  };

  return (
    <>
      <Header />
      <section className="h-100 ">
        <div
          className="bg-worker my-2 flex-grow-1 w-100 overflow-y-scroll mx-auto px-3 pt-4 me-2 position-relative"
          style={{ height: "98%" }}
        >
          <img
            style={{ position: "absolute", right: "0", top: "0" }}
            src={circles}
            alt="circles"
          />
          <h2
            className="pb-4 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Recharge Merchant Wallet
          </h2>
          <form onSubmit={handleRecharge}>
            <div className="mt-5 col-5 mx-auto">
              <InputForm
                type={"number"}
                id={"merchant_id"}
                name="merchant_id"
                placeholder={"Merchant ID"}
                value={username}
                disabled={true}
              />

              <InputForm
                type={"text"}
                id={"name"}
                name="name"
                placeholder={"Merchant Name"}
                value={name}
                disabled={true}
              />

              <InputForm
                type={"number"}
                id={"recharge"}
                name="recharge"
                placeholder={"Amount"}
                value={rechargeAmount}
                onChange={handleRechargeAmountChange}
              />
              <div className="mt-4 text-center">
                <Button
                  type="submit"
                  disabled={!rechargeAmount}
                  style={{
                    opacity: rechargeAmount ? 1 : 0.5,
                    cursor: rechargeAmount ? "pointer" : "not-allowed",
                  }}
                >
                  Recharge
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  name: state.user.name,
  username: state.user.username,
});
export default connect(mapStateToProps)(RechargeMerchant);
