import React, { useEffect, useState } from "react";
import { getTicket } from "../urls";
import API from "../components/api2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import Filter from "../components/Filter";
import PaginationTable from "../components/PaginationTable";

const AllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTicket, setFilteredTicket] = useState([]);
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 20 });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();
  const [progress, setProgress] = useState();
  const [excelData, setExcelData] = useState({});
  const [includeExcel, setIncludeExcel] = useState(false);

  useEffect(() => {
    fetchTickets();
  }, [includeExcel]);

  const fetchTickets = () => {
    API({
      ...getTicket,
      params: { limit: 40 },
      onSuccess: (res) => {
        setTickets(res.data.data.table_data);
        setActive(res.data.data.open_count);
        setInActive(res.data.data.close_count);
        setProgress(res.data.data.progress_count);
        setExcelData(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching tickets: ", error);
      },
    });
  };

  const applyTicketFilters = (filters) => {
    const filtered = tickets.filter((t) => {
      return (
        (!filters.ticket_id || t.ticket_id === filters.ticket_id) &&
        (!filters.assigned_to || t.assigned_to === filters.assigned_to) &&
        (!filters.created_by || t.created_by === filters.created_by)
      );
    });
    setFilteredTicket(filtered);
    setFilteredTable(filtered);
    setIncludeExcel(filtered.length > 0);
  };

  const changePageFunc = (clickedService) => {
    dispatch(
      changePage({
        page: "ticketInfo",
        service: clickedService,
      })
    );

    navigate("/", { state: { service: clickedService } });
  };
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Support Tickets
          </h2>
          <div className="d-flex me-5 pe-3">
            <div
              className="d-flex align-items-center"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(25, 66, 128, 1)",
                }}
                className="mb-0"
              >
                In Progress
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {progress}
              </span>
            </div>
            <div
              className="d-flex align-items-center ms-3"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(20, 140, 67, 1)",
                }}
                className="mb-0"
              >
                Open
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {active}
              </span>
            </div>
            <div
              className="d-flex align-items-center ms-3"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(249, 124, 75, 1)",
                }}
                className="mb-0"
              >
                Closed
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {inActive}
              </span>
            </div>
          </div>
        </div>
        <Filter
          forPage="AllTickets"
          applyFilters={applyTicketFilters}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
          tickets={tickets}
          setPagination={setPagination}
          excelData={excelData}
        />
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() => changePageFunc(row.original)}
                  />
                );
              },
            },
            { Header: "Ticket ID", accessor: "ticket_sid" },
            { Header: "Ticket Title", accessor: "subject" },
            {
              Header: "Status",
              accessor: "ticket_status",
            },
            {
              Header: "Assigned To",
              accessor: "assigned_to",
            },
            {
              Header: "Reporter",
              accessor: "reported_by",
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default AllTickets;
