import React, { useEffect, useState } from "react";
import API from "../components/api2";
import { newMerchWallet, getMerchant, getBusinessBrand } from "../urls";
import Button from "../components/Button";
import Select from "../components/Select";

const NewMerchantWallet = () => {
  const [pageData, setPageData] = useState({});
  const [merchant, setMerchant] = useState([]);
  const [brand, setBrand] = useState([]);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  function onSubmit(e) {
    e.preventDefault();

    API({
      ...newMerchWallet,
      body: pageData,
      onSuccess: (res) => {
        console.log("New Merchant Wallet added successfully");
        alert(res.data.message);
      },

      onError: (error) => {
        console.log("error");
      },
    });
  }

  const fetchData = () => {
    API({
      ...getMerchant,
      params: pageData,
      onSuccess: (res) => {
        setMerchant(res.data.data);
        console.log(res.data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    API({
      ...getBusinessBrand,
      params: pageData,
      onSuccess: (res) => {
        setBrand(res.data.data);
        console.log(res.data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const merchantOption = merchant.map((item) => ({
    value: item.merchant_id,
    label: `${item.merchant_id}-${item.business_name_field}`,
  }));
  const brandOption = brand.map((item) => ({
    value: item.brand_id,
    label: `${item.brand_id}-${item.business_name}`,
  }));

  return (
    <div
      style={{ maxWidth: "600px" }}
      className="bg-worker my-4  mx-auto px-5 py-4"
    >
      <form onSubmit={onSubmit} className="text-start ">
        <h2 className="fw-bold fs-4 text-center pb-3 underline color-primary">
          New Merchant Wallet
        </h2>

        <Select
          placeholder={"Merchant ID"}
          id={"merchant_id"}
          name="merchant_id"
          value={pageData["merchant_id"]}
          onChange={pageDataFunc}
          options={merchantOption}
        />
        <Select
          placeholder={"Brand ID"}
          id={"brand_id"}
          name="brand_id"
          value={pageData["brand_id"]}
          onChange={pageDataFunc}
          options={brandOption}
        />

        <div className="text-end ">
          <Button children={"Save"} />
        </div>
      </form>
    </div>
  );
};

export default NewMerchantWallet;
