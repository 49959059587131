import React from "react";
import { circles2, credLogo, newLogo } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResetNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "login",
      })
    );
    navigate("/");
  };

  return (
    <div className="d-flex align-items-center justify-content-between position-relative ">
      <img
        onClick={changePageFunc}
        style={{
          maxWidth: "200px",
          cursor: "pointer",
          pointerEvents: "all",
          position: "absolute",
          top: "20px",
          left: "10px",
        }}
        src={newLogo}
        alt="logo"
      />
      <div className="text-center mx-auto">
        <img src={credLogo} alt="credentials-logo" />
        <p
          style={{
            fontWeight: "600",
            fontSize: "28px",
            color: "rgba(25, 66, 128, 1)",
          }}
          className="mb-0"
        >
          Reset your Credentials
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: "rgba(79, 87, 91, 1)",
            letterSpacing: "1px",
          }}
          className="mb-0"
        >
          Create new Password?
        </p>
      </div>
      <div className="position-relative" style={{ backgroundColor: "white" }}>
        <img
          style={{ right: "-23px", top: "-90px" }}
          className="position-absolute"
          src={circles2}
          alt="circles"
        />
      </div>
    </div>
  );
};

export default ResetNav;
