import React, { useEffect, useState } from "react";
import API from "../components/api2";
import {
  postBusinessPackage,
  getMerchantData,
  getAvailableScheme,
} from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import Select from "react-select";
import Swal from "sweetalert2";

const AllotBusinessScheme = () => {
  const [pageData, setPageData] = useState({});
  const [merchant, setMerchant] = useState([
    { value: "", label: "Select Merchant" },
  ]);
  const [schemeData, setSchemeData] = useState([
    { value: "", label: "Select Scheme" },
  ]);

  // merchant dropdown
  useEffect(() => {
    API({
      ...getMerchantData,
      onSuccess: (res) => {
        const getMerch = res.data.data.table_data.map((merchant) => ({
          value: merchant.merchant_id,
          label: `${merchant.merchant_id}-${merchant.first_name}`,
        }));
        setMerchant((prevMerchant) => [...prevMerchant, ...getMerch]);
      },
      onError: (error) => {
        console.error("Error fetching merchant dropdown: ", error);
      },
    });
  }, []);

  // scheme dropdown
  useEffect(() => {
    API({
      ...getAvailableScheme,
      onSuccess: (res) => {
        const getScheme = res.data.data.table_data.map((scheme) => ({
          value: scheme.scheme_id,
          label: `${scheme.scheme_id}-${scheme.platform_scheme_name}`,
          scheme_name: scheme.platform_scheme_name,
        }));
        setSchemeData((prevScheme) => [...prevScheme, ...getScheme]);
      },
      onError: (error) => {
        console.error("Error fetching scheme dropdown: ", error);
      },
    });
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    API({
      ...postBusinessPackage,
      body: {
        merchant_id: pageData.merchant_id,
        scheme: [pageData.scheme_id],
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  }

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto py-4 me-2 position-relative h-100">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-4 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Allot Business Scheme
        </h2>
        <div className="container-fluid mt-4">
          <div className="col-5 mx-auto">
            <label
              style={{
                marginTop: "8px",
                fontSize: "15px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="merchant_id"
              className="form-label"
            >
              Select Merchant
            </label>
            <Select
              placeholder="Select Merchant"
              id="merchant_id"
              name="merchant_id"
              options={merchant}
              value={merchant.find(
                (option) => option.value === pageData.merchant_id
              )}
              onChange={(selectedOption) =>
                setPageData({
                  ...pageData,
                  merchant_id: selectedOption.value,
                })
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </div>
          <div className="col-5 mx-auto mt-2">
            <label
              style={{
                marginTop: "8px",
                fontSize: "15px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="scheme_id"
              className="form-label"
            >
              Select Scheme
            </label>
            <Select
              placeholder="Select Scheme"
              id="scheme_id"
              name="scheme_id"
              options={schemeData}
              value={schemeData.find(
                (option) => option.value === pageData.scheme_id
              )}
              onChange={(selectedOption) =>
                setPageData({
                  ...pageData,
                  scheme_id: selectedOption.value,
                  scheme_name: selectedOption.scheme_name,
                })
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </div>
          <div className="mt-4 mx-auto text-center">
            <Button
              style={{ width: "35%" }}
              onClick={onSubmit}
              children={"Update"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllotBusinessScheme;
