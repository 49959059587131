import React, { useState, useRef } from "react";
import { passIcon2 } from "../../images";
import Header from "../../components/Header";
import { forgetUserPin, merchantVerifyOtp } from "../../urls";
import Swal from "sweetalert2";
import API from "../../components/api2";
import Modal from "react-modal"; // Assuming you're using react-modal
import { useDispatch } from "react-redux";
import { changePage, setBackPage } from "../../redux/slice/dashboardSlice";

const ResetUpin = () => {
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [reqId, setReqId] = useState("");

  const [formData, setFormData] = useState({ otp: "" });
  const inputRefs = useRef([]);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setIsButtonDisabled(value === "");
  };

  const handleVerify = () => {
    API({
      ...forgetUserPin,
      body: { password },
      onSuccess: (res) => {
        setReqId(res.data.data.request_id);
        setShowOtpPopup(true);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          text: error.response?.data?.message || "Something went wrong!",
        });
      },
    });
  };

  const handleOtpChange = (index, value, e) => {
    const otp = [...formData.otp];
    otp[index] = value;
    setFormData({ ...formData, otp });

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (e.key === "Backspace" && value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const isOtpValid = () => {
    return formData.otp.length === 6 && !formData.otp.includes("");
  };

  const verifyOtp = () => {
    console.log("Entered OTP:", formData.otp);
    const enteredOTP = formData.otp.join("");
    API({
      ...merchantVerifyOtp,
      body: { otp: enteredOTP, request_id: reqId },
      onSuccess: (res) => {
        console.log(res);
        setPassword("");
        setFormData({ otp: "" });
        dispatch(
          setBackPage({
            backPage: "resetUpin",
          })
        );
        dispatch(
          changePage({
            page: "manageUpin",
          })
        );
        setShowOtpPopup(false);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          text: error.response?.data?.message || "Something went wrong!",
        });
      },
    });
    setShowOtpPopup(false);
  };

  const closeModal = () => {
    setShowOtpPopup(false);
  };

  return (
    <>
      <Header />
      <div className="bg-worker text-center h-100">
        <div
          className="pt-1 mt-2 pb-5 col-9 mx-auto"
          style={{ backgroundColor: "#D9D9D9" }}
        >
          <div className="bg-white mx-5 mt-2 px-4">
            <h2
              className="px-5"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                textAlign: "center",
                letterSpacing: "1px",
                color: "rgba(79, 87, 91, 1)",
                paddingTop: "125px",
              }}
            >
              Please enter the Account Password to verify.
            </h2>
            <div
              className="mx-3 col-10 mx-auto position-relative"
              style={{ borderBottom: "1.5px solid black" }}
            >
              <img
                src={passIcon2}
                alt="password-icon"
                style={{
                  position: "absolute",
                  left: "0%",
                  bottom: "-5%",
                  transform: "translateY(-50%)",
                }}
              />
              <input
                type="password"
                placeholder="Verification Password"
                className="p-2 mx-4 mt-4 w-100"
                value={password}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-center col-7 mx-auto mt-5 py-5">
              <button
                type="submit"
                className="border-0 text-white py-2 px-4 mt-4 col-6"
                style={{
                  backgroundColor: "#194280",
                  borderRadius: "10px",
                  opacity: isButtonDisabled ? "0.5" : "1",
                  cursor: isButtonDisabled ? "not-allowed" : "pointer",
                }}
                onClick={handleVerify}
                disabled={isButtonDisabled}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showOtpPopup}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="OTP Verification"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            padding: "20px",
            borderRadius: "10px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div className="text-center py-4">
          <p
            className="login_text"
            style={{
              fontWeight: "600",
              fontSize: "20px",
              color: "rgba(79, 87, 91, 1)",
              letterSpacing: "1px",
            }}
          >
            Please enter the One-Time Password sent to your registered mobile
            number to verify account
          </p>
          <div className="mt-5 d-flex justify-content-center">
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="mx-1"
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleOtpChange(index, e.target.value, e)}
                onKeyDown={(e) => handleOtpChange(index, e.target.value, e)}
                value={formData.otp[index] || ""}
                style={{
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  fontSize: "20px",
                  borderBottom: "1px solid black",
                  borderRadius: "0px",
                }}
              />
            ))}
          </div>
          <div className="text-center mt-5">
            <button
              type="button"
              className="border-0 text-white py-1 px-4 mt-4 col-5 login_text"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                fontSize: "18px",
                opacity: isOtpValid() ? 1 : 0.5,
                cursor: isOtpValid() ? "pointer" : "not-allowed",
              }}
              onClick={verifyOtp}
              disabled={!isOtpValid()}
            >
              Verify
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResetUpin;
