import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../components/api2";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import Swal from "sweetalert2";
import {
  updateEmployee,
  designation,
  profileRole,
  getEmployee,
  brandDropdown,
  prayasPlatform,
} from "../urls";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Alert from "../components/Alert";

const EmployeeInformation = () => {
  const location = useLocation();
  const { employee } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [designationOptions, setDesignationOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [emp, setEmp] = useState([]);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [editableFields, setEditableFields] = useState({
    employee_name: "",
    email_id: "",
    contact_no: "",
    employee_type: "",
    employee_code: "",
    designation: "",
    profile_role_id: "",
    reference_id: "",
    contact_no: "",
    last_enabled_date: "",
    last_disabled_date: "",
  });

  // alert
  const hideAlert = () => {
    setShowAlertUpdate(false);
  };

  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  // designation dropdown api
  useEffect(() => {
    API({
      ...designation,
      onSuccess: (res) => {
        const desig = res.data.data.map((d) => ({
          value: d.designation,
          id: d.designation_id,
          label: `${d.designation}`,
        }));
        setDesignationOptions(desig);
      },
    });
  }, []);

  // profile role dropdown api
  useEffect(() => {
    API({
      ...profileRole,
      onSuccess: (res) => {
        const profile = res.data.data.map((p) => ({
          value: p.profile_role_id,
          id: p.profile_id,
          label: `${p.profile_role_id}`,
        }));
        setProfileOptions(profile);
      },
    });
  }, []);

  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));

    if (fieldName === "designation") {
      const selectedOption = designationOptions.find(
        (option) => option.value === value
      );
      if (selectedOption) {
        setEmp((prevEmp) => ({
          ...prevEmp,
          designation_id: selectedOption.id,
        }));
      }
    }
    if (fieldName === "profile_role_id") {
      const selectedOption = profileOptions.find(
        (option) => option.value === value
      );
      if (selectedOption) {
        setEmp((prevEmp) => ({
          ...prevEmp,
          profile_role_id: selectedOption.id,
        }));
      }
    }
  };

  // update
  const handleUpdate = () => {
    const { created_by, created_on, updated_by, updated_on, ...updateData } =
      editableFields;

    const reference_id =
      editableFields.employee_type === "BRAND" ||
      editableFields.employee_type === "MERCHANT"
        ? emp.brand_id
        : emp.platform_id;
    API({
      ...updateEmployee,
      body: {
        ...updateData,
        designation_id: emp.designation_id,
        profile_id: emp.profile_id,
        reference_id: reference_id,
      },
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // fetch employee by employee_code
  useEffect(() => {
    const fetchEmployee = () => {
      API({
        ...getEmployee,
        params: { employee_code: employee.employee_code },
        onSuccess: (res) => {
          setEmp(res.data.data);
          setEditableFields(res.data.data);
        },
        onError: (error) => {
          console.error("Error fetching employee: ", error);
        },
      });
    };
    fetchEmployee();
  }, []);

  const { employee_type, brand_id, platform_id } = emp;

  useEffect(() => {
    if (employee_type === "BRAND" || employee_type === "MERCHANT") {
      API({
        ...brandDropdown,
        onSuccess: (res) => {
          const brandOptions = res.data.data.map((brand) => ({
            value: brand.brand_id,
            label: `${brand.brand_id}-${brand.business_name}`,
          }));
          const filteredBrandOptions = brand_id
            ? brandOptions.filter((brand) => brand.value === brand_id)
            : brandOptions;
          setReferenceOptions(filteredBrandOptions);
        },
      });
    } else if (employee_type === "PLATFORM") {
      API({
        ...prayasPlatform,
        onSuccess: (res) => {
          const platformOption = res.data.data.map((p) => ({
            value: p.platform_id,
            label: `${p.platform_id}-${p.legal_name}`,
          }));
          setReferenceOptions(platformOption);
        },
      });
    }
  }, [employee_type, brand_id]);

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "updateEmployee",
      })
    );

    navigate("/");
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) return "";

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    return new Date(timestamp).toLocaleString("en-GB", options);
  }

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-auto container-fluid h-100"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="mx-4 pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Employee Information
          </h2>
        </div>

        {/* employee details  */}
        <p
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "16px",
            color: "#696666",
            backgroundColor: "#D9D9D9",
            borderRadius: "10px",
          }}
          className="mb-0 py-1 px-3"
        >
          Employee Detail
        </p>
        <div className="row mt-4 mx-3 px-3">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Employee Name
            </p>

            <input
              type="text"
              name="employee_name"
              className="p-2 w-100 mt-1"
              value={editableFields.employee_name}
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Employee Code
            </p>

            <input
              type="number"
              name="employee_code"
              className="p-2 w-100 mt-1"
              value={editableFields.employee_code}
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
        </div>
        <div className="row mt-3 mx-3 px-3">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Employee Type
            </p>

            <select
              name="employee_type"
              value={editableFields.employee_type}
              onChange={(e) =>
                handleInputChange("employee_type", e.target.value)
              }
              className="p-2 w-100 mt-1"
              style={{
                backgroundColor: "rgba(255, 231, 159,0.4)",
                borderRadius: "10px",
                marginTop: "8px",
                color: "black",
                border: "none",
                boxShadow: "none",
                width: "100%",
                fontFamily: "Montserrat",
                outline: "none",
                padding: "12px 8px",
              }}
              disabled
            >
              {" "}
              <option value="">Select Employee Type</option>
              <option value="PLATFORM">PLATFORM</option>
              <option value="BRAND">BRAND</option>
              <option value="MERCHANT">MERCHANT</option>
            </select>
          </div>
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              {employee_type
                ? employee_type === "BRAND" || employee_type === "MERCHANT"
                  ? "Brand ID"
                  : "Platform ID"
                : "Select Employee Type"}
            </p>
            <select
              name="reference_id"
              value={
                employee_type === "BRAND" || employee_type === "MERCHANT"
                  ? brand_id
                  : platform_id
              }
              onChange={(e) =>
                handleInputChange("reference_id", e.target.value)
              }
              className="p-2 w-100 mt-1"
              style={{
                backgroundColor: "rgba(255, 231, 159,0.4)",
                borderRadius: "10px",
                marginTop: "8px",
                color: "black",
                border: "none",
                boxShadow: "none",
                width: "100%",
                fontFamily: "Montserrat",
                outline: "none",
              }}
              disabled
            >
              <option value="">Select Reference ID</option>
              {referenceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3 mx-3 px-3">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Email
            </p>

            <input
              type="email"
              name="email_id"
              className="p-2 w-100 mt-1"
              value={editableFields.email_id}
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Contact No
            </p>

            <input
              type="number"
              name="contact_no"
              className="p-2 w-100 mt-1"
              value={editableFields.contact_no}
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
        </div>
        <div className="row mt-3 mx-3 px-3">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Designation
            </p>

            <select
              name="designation"
              value={editableFields.designation}
              onChange={(e) => handleInputChange("designation", e.target.value)}
              className="p-2 w-100 mt-1"
              style={{
                backgroundColor: "rgba(255, 231, 159,0.4)",
                borderRadius: "10px",
                marginTop: "8px",
                color: "black",
                border: "none",
                boxShadow: "none",
                width: "100%",
                fontFamily: "Montserrat",
                outline: "none",
                padding: "12px 8px",
              }}
            >
              {designationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Profile Role
            </p>

            <select
              name="profile_role_id"
              value={editableFields.profile_role_id}
              onChange={(e) =>
                handleInputChange("profile_role_id", e.target.value)
              }
              className="p-2 w-100 mt-1"
              style={{
                backgroundColor: "rgba(255, 231, 159,0.4)",
                borderRadius: "10px",
                marginTop: "8px",
                color: "black",
                border: "none",
                boxShadow: "none",
                width: "100%",
                fontFamily: "Montserrat",
                outline: "none",
                padding: "12px 8px",
              }}
            >
              {profileOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3 mx-3 px-3">
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Last Enabled Date
            </p>

            <input
              type="text"
              name="last_enabled_date"
              className="p-2 w-100 mt-1"
              value={
                editableFields.last_enabled_date
                  ? editableFields.last_enabled_date
                  : "N/A"
              }
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
          <div className="col-6">
            <p
              className="mb-0"
              style={{
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Last Disabled Date
            </p>

            <input
              type="text"
              name="last_disabled_date"
              className="p-2 w-100 mt-1"
              value={
                editableFields.last_disabled_date
                  ? editableFields.last_disabled_date
                  : "N/A"
              }
              onChange={handleInputChange}
              style={{
                backgroundColor: "rgba(255, 231, 159, 0.4)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              }}
              readOnly
            />
          </div>
        </div>

        {/* Audit info  */}
        <div className="mx-1 mt-5 pb-4">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "#696666",
            }}
            className="mb-0 py-1 px-3 mx-1"
          >
            Audit Info
          </p>
          <div className="row mt-3 mx-3 px-3">
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created By: {emp.created_by}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created On: {formatTimestamp(emp.created_on)}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified By: {emp.updated_by ? emp.updated_by : "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified On:
                {emp.updated_on ? formatTimestamp(emp.updated_on) : "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* button  */}
        <div className="d-flex justify-content-between align-itmes-center mt-5 px-3">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button children={"Update"} onClick={handleClickUpdate} />
          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText="update"
            message={alertTextUpdate}
            walletColor={walletColor}
            onConfirm={handleUpdate}
            forTerm=" "
            term="Employee"
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeInformation;
