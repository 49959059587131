import React from "react";
import { Modal } from "react-bootstrap";

const Alert = ({
  show,
  onHide,
  title,
  message,
  actionText,
  term = "Brand",
  forTerm = "wallet for",
  walletColor = "red",
  onConfirm,
}) => {
  const modalStyle = {
    fontFamily: "Montserrat",
    fontWeight: "500",
    textAlign: "center",
    width: "600px",
    marginLeft: "33%",
    marginTop: "150px",
  };

  const titleStyle = {
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "18px",
  };

  const messageStyle = {
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: "#696666",
    fontSize: "18px",
  };

  const brandStyle = {
    color: walletColor,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "18px",
  };

  const footerButtonStyle = {
    fontFamily: "Montserrat",
    fontWeight: "600",
  };

  const confirmButtonStyle = {
    backgroundColor: "#3D9189",
    borderRadius: "10px",
    fontFamily: "Montserrat",
  };

  return (
    <Modal style={modalStyle} show={show} onHide={onHide}>
      <Modal.Header style={{ borderBottom: "1px solid red" }} closeButton>
        <Modal.Title style={titleStyle}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <span style={messageStyle}>{message}</span>
          <span style={brandStyle}> {actionText}</span>{" "}
          <span style={messageStyle}>
            {" "}
            {forTerm} this <br /> {term} !
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="border-0" style={footerButtonStyle} onClick={onHide}>
          cancel
        </button>
        <button
          className="border-0 text-white py-2 px-3 ms-3"
          style={confirmButtonStyle}
          onClick={onConfirm}
        >
          confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Alert;
