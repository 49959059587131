import React from "react";
import { FaFileVideo } from "react-icons/fa";

const VideoViewer = ({ src }) => {
  const openVideo = () => {
    window.open(src, "_blank");
  };

  const fileName = src.substring(src.lastIndexOf("/") + 1);

  const boxStyles = {
    width: "244px",
    maxHeight: "158px",
    cursor: "pointer",
    border: "1px solid #122158",
    borderRadius: "26px",
    backgroundColor: "white",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyles = {
    fontSize: "3rem",
    marginBottom: "10px",
  };

  const textStyles = {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "0",
    padding: "0px 10px",
    fontSize: "14px",
  };

  return (
    <div style={boxStyles} onClick={openVideo}>
      <FaFileVideo size={60} className="text-warning" style={iconStyles} />
      <p style={textStyles}>{fileName}</p>
    </div>
  );
};

export default VideoViewer;
