import React from "react";
import { connect, useDispatch } from "react-redux";
import BarChart from "../components/BarChart";
import { useWs } from "../hooks/useWs";
import { setDUID, onLogin } from "../redux/slice/userSlice";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";

const QRPage = () => {
  const [qr, setQr] = React.useState(false);

  const [ready, val, send] = useWs({
    url: "ws://192.168.1.51:8000/ws/connect/",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (ready) {
      console.log("Connection established...");
    }
    let value = false;
    try {
      value = JSON.parse(val);
    } catch {}

    if (value?.data?.qr) {
      console.log(value, "val");
      setQr(value.data.qr);
    } else if (value?.data?.t) {
      console.log(value, "token ");
      if (value) {
        dispatch(
          onLogin({
            t: value.data.t,
            rt: value.data.rt,
          })
        );
        dispatch(setDUID({ duid: value.data.duid }));

        send(
          JSON.stringify({
            t: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExODg4MDg5LCJpYXQiOjE3MDkyOTYwODksImp0aSI6IjI5NWY0ZDM5ODQzOTQ1NGJiYTAyODcwODU1ODgxNDQwIiwidXNlcl9pZCI6MTExfQ.5BBsFxc0BoYhdrR8hAHYWqlgYBfUDktP7uKJwuMlY28",
          })
        );

        dispatch(
          changePage({
            page: "home",
          })
        );

        navigate("/");
      }
    }
  }, [ready, val]);

  return (
    <>
      <div className="d-flex">
        {qr && (
          <>
            <img src={`data:image/png;base64,${qr}`} alt={"qr"}></img>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  duid: state.user.duid,
  t: state.user.t,
});

export default connect(mapStateToProps)(QRPage);
