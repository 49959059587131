import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../components/api2";
import {
  getAllotBrandDetails,
  postSchemesInAssigned,
  getAvailableSchemeDropdown,
  postSchemesInAvailable,
} from "../urls";
import Header from "../components/Header";
import { circles } from "../images";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const AllotPackage = () => {
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [brandDetails, setBrandDetails] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [assignedschemes, setAssignedSchemes] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [showButtonAvail, setShowButtonAvail] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { brandData } = location.state || {};

  useEffect(() => {
    fetchAllotBrand();
    fetchAvailSchemes();
  }, []);

  // all schemes
  const fetchAvailSchemes = () => {
    API({
      ...getAvailableSchemeDropdown,
      onSuccess: (res) => {
        setSchemes(res.data.data);
      },

      onError: (error) => {
        console.log("No schemes available");
      },
    });
  };

  // brand details and assigned schemes to it
  const fetchAllotBrand = () => {
    API({
      ...getAllotBrandDetails,
      params: { brand_id: brandData.brand_id },
      onSuccess: (res) => {
        setBrandDetails(res.data.data);
        setAssignedSchemes(res.data.data.schemes);
      },

      onError: (error) => {
        console.log("No data available");
      },
    });
  };

  // sending scheme from avail to assign and vice versa
  const handleAssignScheme = (scheme) => {
    const schemeExists = assignedschemes.some(
      (assignedScheme) =>
        assignedScheme.platform_scheme_name === scheme.platform_scheme_name
    );

    if (!schemeExists) {
      const updatedAvailableSchemes = schemes.filter(
        (s) => s.platform_scheme_name !== scheme.platform_scheme_name
      );
      const updatedAssignedSchemes = [...assignedschemes, scheme];
      setSchemes(updatedAvailableSchemes);
      setAssignedSchemes(updatedAssignedSchemes);
      setSelectedScheme(scheme);
      setShowButton(true);
      setShowButtonAvail(false);
    } else {
      setSelectedScheme(null);

      const schemeAlreadyAvailable = schemes.some(
        (availableScheme) =>
          availableScheme.platform_scheme_name === scheme.platform_scheme_name
      );

      if (!schemeAlreadyAvailable) {
        const updatedAvailableSchemes = [...schemes, scheme];
        setSchemes(updatedAvailableSchemes);
      }

      const updatedAssignedSchemes = assignedschemes.filter(
        (assignedScheme) =>
          assignedScheme.platform_scheme_name !== scheme.platform_scheme_name
      );
      setAssignedSchemes(updatedAssignedSchemes);
      setShowButton(false);
      setShowButtonAvail(true);
      setSelectedScheme(scheme);
    }
  };

  const handleUpdateClick = () => {
    if (selectedScheme) {
      API({
        ...postSchemesInAssigned,
        body: {
          brand_id: brandData.brand_id,
          scheme_id: [selectedScheme.scheme_id],
        },
        onSuccess: (res) => {
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            window.location.reload();
          });
        },
        onError: (error) => {
          console.error("Error assigning scheme:", error);
        },
      });
    }
  };
  const handleUpdateClickAvail = () => {
    if (assignedschemes.length >= 0) {
      API({
        ...postSchemesInAvailable,
        body: {
          scheme_ids: assignedschemes.map((s) => s.scheme),
        },

        onSuccess: (res) => {
          Swal.fire({
            title: `${"Assigned scheme deleted successfully."}`,
          });
        },
        onError: (error) => {
          console.error("Error assigning scheme to available:", error);
        },
      });

      setSelectedScheme(null);
    }
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "updateBusinessBrand",
      })
    );

    navigate("/");
  };

  console.log(brandDetails);
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-auto container-fluid h-100"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="mx-4 pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Allot Package
          </h2>
        </div>

        {/* audit info  */}
        <div className="mx-4 mt-2">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
            className="mb-0"
          >
            Audit Info
          </p>
          <div className="row mt-3 px-3">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created By: {brandDetails.created_by}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created On:{" "}
                {brandDetails.created_on
                  ? new Date(brandDetails.created_on)
                      .toISOString()
                      .split("T")[0]
                  : ""}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified By: {brandDetails.modified_by}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified On:{" "}
                {brandDetails.modified_on
                  ? new Date(brandDetails.modified_on)
                      .toISOString()
                      .split("T")[0]
                  : ""}
              </p>
            </div>
          </div>
        </div>

        {/* brand detail  */}
        <div className="mx-2 mt-3">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
              backgroundColor: "#EBEDEE",
              borderRadius: "10px",
            }}
            className="mb-0 py-1 px-3"
          >
            Brand Detail
          </p>
          <div className="row mt-3 mx-2 px-3">
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Brand ID: {brandDetails.brand_id}
              </p>
            </div>
            <div className="col-5">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Email: {brandDetails.contact_email}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Last Disabled Date: {brandDetails.last_disabled_date}
              </p>
            </div>
          </div>
          <div className="row mt-2 mx-2 px-3">
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Brand Name: {brandDetails.business_name}
              </p>
            </div>
            <div className="col-5">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Mobile: {brandDetails.contact_no}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Last Enabled Date: {brandDetails.last_enabled_date}
              </p>
            </div>
          </div>

          {/* assign activity  */}
          <div
            style={{ backgroundColor: "#EBEDEE", borderRadius: "10px" }}
            className="d-flex py-1 px-3 mt-3 align-items-center justify-content-between"
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 "
            >
              Assigned Activity
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-between mx-2 mt-3">
          <div className="col-5 overflow-y-auto" style={{ height: "140px" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  Available Schemes
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAvailable}
                  onChange={(e) => setSearchTermAvailable(e.target.value)}
                  placeholder="Search Available Schemes"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%", overflowY: "scroll" }}>
                {(() => {
                  const filteredSchemes = schemes
                    .filter((scheme) => {
                      return !assignedschemes.some(
                        (assignedScheme) =>
                          assignedScheme.platform_scheme_name ===
                          scheme.platform_scheme_name
                      );
                    })
                    .filter((scheme) => {
                      const searchTermWords = searchTermAvailable
                        .toLowerCase()
                        .split(" ");
                      return searchTermWords.every((word) =>
                        scheme.platform_scheme_name.toLowerCase().includes(word)
                      );
                    });

                  if (filteredSchemes.length === 0) {
                    return (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No available schemes
                      </div>
                    );
                  }

                  return filteredSchemes.map((scheme, index) => (
                    <div
                      key={index}
                      onClick={() => handleAssignScheme(scheme)}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        borderBottom:
                          index !== filteredSchemes.length - 1
                            ? "1px solid #D9D9D9"
                            : "none",
                      }}
                    >
                      <p
                        className="mb-0 mt-2"
                        style={{
                          padding: "4px 8px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "13px",
                          color: "rgba(105, 102, 102, 1)",
                        }}
                      >
                        {scheme.platform_scheme_name
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                    </div>
                  ));
                })()}
              </div>
            </div>
          </div>

          <div className="col-5 overflow-y-auto" style={{ height: "140px" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  Assigned Schemes
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAssigned}
                  onChange={(e) => setSearchTermAssigned(e.target.value)}
                  placeholder="Search Assigned Schemes"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%", overflowY: "scroll" }}>
                <div>
                  {assignedschemes.length > 0 ? (
                    assignedschemes
                      .filter((scheme) => {
                        const searchTermWords = searchTermAssigned
                          .toLowerCase()
                          .split(" ");
                        return searchTermWords.every((word) =>
                          scheme.platform_scheme_name
                            .toLowerCase()
                            .includes(word)
                        );
                      })
                      .map((scheme, index) => (
                        <div
                          key={index}
                          onClick={() => handleAssignScheme(scheme)}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            borderBottom:
                              index !== assignedschemes.length - 1
                                ? "1px solid #D9D9D9"
                                : "none",
                          }}
                        >
                          <p
                            className="mb-0 mt-2"
                            style={{
                              padding: "4px 8px",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "rgba(105, 102, 102, 1)",
                            }}
                          >
                            {scheme.platform_scheme_name
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </p>
                        </div>
                      ))
                  ) : (
                    <div className="my-3 px-3" style={{ color: "red" }}>
                      No assigned schemes
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-5 px-3">
          <Button onClick={changePageFunc} children={"Back"} />
          {showButton && (
            <button
              type="submit"
              className="text-center px-2 py-1"
              style={{
                backgroundColor: "#194280",
                color: "white",
                borderRadius: "6px",
                cursor: "pointer",
                fontFamily: "Montserrat",
                fontSize: "14px",
                border: "none",
              }}
              onClick={handleUpdateClick}
            >
              Update Assign Scheme
            </button>
          )}
          {showButtonAvail && (
            <button
              type="submit"
              className="text-center px-2 py-1"
              style={{
                backgroundColor: "#194280",
                color: "white",
                borderRadius: "6px",
                cursor: "pointer",
                fontFamily: "Montserrat",
                fontSize: "14px",
                border: "none",
              }}
              onClick={handleUpdateClickAvail}
            >
              Update Available Scheme
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AllotPackage;
