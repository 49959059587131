import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { connect } from "react-redux";

const Protected = ({ duid, t }) => {
  return (
    <>
      {!t || !duid ? (
        <Navigate to={"login/"} />
      ) : (
        <>
          <Outlet></Outlet>{" "}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  duid: state.user.duid,
  t: state.user.t,
});

export default connect(mapStateToProps)(Protected);
