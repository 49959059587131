import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateQues, validateAns, forgetOtp } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import LoginNav from "../components/LoginNav";

export const ValidateQues = () => {
  const location = useLocation();
  const { username } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [requestId, setRequestId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const fetchData = () => {
    API({
      ...validateQues,
      params: { username },
      onSuccess: (res) => {
        const questionsData = res.data.data;
        const filteredQuestions = Object.entries(questionsData)
          .filter(([key]) => !key.startsWith("request_id"))
          .map(([_, value]) => value);
        setQuestions(filteredQuestions);
        setAnswers(Array(filteredQuestions.length).fill(""));
        setRequestId(questionsData.request_id);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleAnswerChange = (index, event) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);
    setIsButtonDisabled(newAnswers.some((answer) => answer.trim() === ""));
  };

  const handleSubmit = () => {
    if (answers.some((answer) => answer.trim() === "")) {
      Swal.fire({
        icon: "error",
        title: "Please provide answers for all questions.",
      });
      return;
    }

    const formattedAnswers = answers.reduce((acc, curr, index) => {
      acc[`answer${index + 1}`] = curr.trim();
      return acc;
    }, {});
    const payload = {
      username,
      request_id: requestId,
      ...formattedAnswers,
    };

    API({
      ...validateAns,
      body: payload,
      onSuccess: (res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        API({
          ...forgetOtp,
          params: { username },
          onSuccess: (res) => {
            const reqId = res.data.data.request_id;
            Swal.fire({
              icon: "success",
              title: `${res.data.message}`,
            });
            changePageFunc(reqId);
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const changePageFunc = (reqId) => {
    dispatch(
      changePage({
        page: "verifyOTP",
        reqId: reqId,
      })
    );
    navigate("/", {
      state: {
        username,
        reqId,
        redirectPage: "forgetPassword",
      },
    });
  };

  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <LoginNav />
        <div className="col-6 mx-auto mt-5">
          <h2
            className="px-5"
            style={{
              fontWeight: "500",
              fontSize: "26px",
              textAlign: "center",
              letterSpacing: "3px",
              color: "rgba(79, 87, 91, 1)",
            }}
          >
            Username: {username}
          </h2>
          <ul style={{ listStyleType: "none" }} className="mt-4">
            {questions.map((question, index) => (
              <li
                className="mt-3"
                style={{
                  borderBottom: "1px solid black",
                  color: "#194280",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                key={index}
              >
                {question}
                <input
                  type="text"
                  className="mt-2 w-100"
                  autoComplete="off"
                  name={`answer${index + 1}`}
                  id={`answer${index + 1}`}
                  value={answers[index]}
                  onChange={(event) => handleAnswerChange(index, event)}
                />
              </li>
            ))}
          </ul>

          <div className="text-center mt-4">
            <button
              type="submit"
              className="border-0 text-white py-2 px-4 mt-4 col-6"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity: isButtonDisabled ? 0.5 : 1,
              }}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
