import React, { useEffect, useState } from "react";
import API from "../../../components/api2";
import Select from "react-select";
import { serviceCircle, success } from "../../../images";
import Swal from "sweetalert2";

const PostPadiMobile = () => {
  const [formData, setFormData] = useState({
    mobile_number: "",
    billers: "",
    id_number: "",
  });

  const [billersList, setBillersList] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      nbi: selectedOption.value,
    }));
  };

  // handle continue
  const handleContinue = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="mx-5 mt-5 px-2">
        <div className="row mb-3">
          {/* withdrawal form  */}
          <div className="col-7">
            <div
              className="px-4 position-relative"
              style={{
                backgroundColor: "rgba(25, 66, 128, 1)",
                borderRadius: "20px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  width: "120px",
                }}
                src={serviceCircle}
                alt="circle"
              />

              <div className="mx-2 pt-2 pb-4 col-6">
                <label
                  htmlFor="mobile_number"
                  style={{
                    color: "#ADACAC",
                    transition: "all 0.4s ease-in-out",
                    fontSize: "14px",
                    opacity: formData.mobile_number ? 1 : 0,
                    marginTop: "8px",
                  }}
                  className="ms-2 login_text"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="mobile_number"
                  name="mobile_number"
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                  placeholder="Mobile Number"
                  style={{
                    backgroundColor: "transparent",
                    borderBottom: formData.mobile_number
                      ? "1.5px solid white"
                      : "1.5px solid #ADACAC",
                    width: "100%",
                    color: "white",
                    borderRadius: "0px",
                    padding: "0px 8px",
                  }}
                  autoComplete="off"
                  className="login_text"
                />
                <label
                  htmlFor="billers"
                  style={{
                    color: "#ADACAC",
                    transition: "all 0.4s ease-in-out",
                    fontSize: "14px",
                    opacity: formData.billers ? 1 : 0,
                    paddingTop: "10px",
                  }}
                  className="ms-2 login_text"
                >
                  Select Billers List
                </label>
                <Select
                  id="billers"
                  name="billers"
                  className="recharge_select login_text"
                  placeholder="Select Billers List"
                  value={billersList.find(
                    (option) => option.value === formData.billers
                  )}
                  options={billersList}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                      borderWidth: "0 0 1.5px 0",
                      borderBottom: formData.billers
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: formData.billers ? "white" : "#ADACAC",
                      borderRadius: "0px",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                        boxShadow: "none",
                      },
                      marginTop: "-8px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                  }}
                />
                <label
                  htmlFor="id_number"
                  style={{
                    color: "#ADACAC",
                    transition: "all 0.4s ease-in-out",
                    fontSize: "14px",
                    opacity: formData.id_number ? 1 : 0,
                    paddingTop: "10px",
                  }}
                  className="ms-2 login_text"
                >
                  ID Number
                </label>
                <input
                  type="number"
                  id="id_number"
                  name="id_number"
                  value={formData.id_number}
                  onChange={handleInputChange}
                  placeholder="ID Number"
                  style={{
                    backgroundColor: "transparent",
                    borderBottom: formData.id_number
                      ? "1.5px solid white"
                      : "1.5px solid #ADACAC",
                    width: "100%",
                    color: "white",
                    borderRadius: "0px",
                    padding: "0px 8px",
                  }}
                  autoComplete="off"
                  className="login_text"
                />

                <div className="mt-4">
                  <button
                    className="px-4 py-2 login_text"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "none",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={handleContinue}
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* transaction history  */}
          <div className="col-5">
            <div
              style={{
                boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
                borderRadius: "20px",
                border: "1px solid rgba(217, 217, 217, 1)",
              }}
            >
              <div
                className="py-3"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "20px 20px 0px 0px",
                }}
              >
                <h3
                  className="mb-0 login_text"
                  style={{ fontSize: "14px", textAlign: "center" }}
                >
                  Transaction History
                </h3>
              </div>
              <div style={{ height: "160px" }} className="py-3">
                <p
                  style={{ fontSize: "14px", textAlign: "center" }}
                  className="mb-0 login_text"
                >
                  No Record Found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostPadiMobile;
