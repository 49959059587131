import React, { useEffect, useState } from "react";
import { eye, eyeClosed, passIcon, userIcon, passIcon2 } from "../images";

const Input = ({
  type,
  id,
  name,
  placeholder,
  onChange,
  value,
  valueState,
  checkedValue = true,
  required = false,
  autocomplete = "off",
  errorText = "",
  errorState = {},
  style: customStyle = {
    fontFamily: "Montserrat",
    backgroundColor: "rgba(255, 231, 159, 0.4)",
    borderRadius: "10px",
  },
  showLabel = true,
  showIcon = false,
  showPassIcon = false,
  showPassIcon2 = false,
  showEyeIcon = true,
  disabled,
  maxLength = 150,
  maxInputLength = "",
  showAsterisk = false,
}) => {
  const [isPassword, setIsPassword] = useState(
    type === "password" ? false : true
  );

  const updatedStyle = {
    ...customStyle,
    backgroundColor: errorState[id] ? undefined : customStyle.backgroundColor,
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maxAllowedLength = maxInputLength !== "" ? maxInputLength : maxLength;

    if (type === "number") {
      const regex = /^[0-9]*$/;
      if (!regex.test(inputValue)) {
        return;
      }
    }

    if (inputValue.length <= maxAllowedLength) {
      onChange(e);
    }
  };

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  return (
    <div className="mt-2">
      <div className="position-relative d-block">
        {showLabel && (
          <label
            htmlFor={id}
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            className="trans_text"
          >
            {placeholder}
            {required && showAsterisk && (
              <span style={{ color: "red" }}> *</span>
            )}
          </label>
        )}
        {showIcon && (
          <img
            src={userIcon}
            alt="user-icon"
            style={{
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              left: "0%",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "all",
            }}
          />
        )}
        <div className="mt-2" style={{ ...updatedStyle }}>
          <input
            type={type === "password" && isPassword ? "text" : type}
            id={id}
            name={name}
            value={
              value ? value : valueState && valueState[id] ? valueState[id] : ""
            }
            checked={
              value === checkedValue
                ? true
                : valueState &&
                  valueState[id] &&
                  valueState[id] === checkedValue
                ? true
                : false
            }
            onChange={handleInputChange}
            className={
              errorState[id]
                ? "form-control is-invalid"
                : "flex-grow-1 bg-transparent p-2 w-100"
            }
            required={required}
            autoComplete={autocomplete}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
          />
          {errorState[id] && (
            <div className="invalid-feedback bg-white">{errorState[id]}</div>
          )}
        </div>
        {showEyeIcon && type === "password" && (
          <>
            <img
              src={isPassword ? eyeClosed : eye}
              alt="eye"
              style={{
                cursor: "pointer",
                height: "20px",
                color: "#122158",
                position: "absolute",
                left: "94%",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "all",
              }}
              onClick={() => {
                setIsPassword(!isPassword);
              }}
            />
            {showPassIcon && (
              <img
                src={passIcon}
                alt="passwoord-icon"
                style={{
                  cursor: "pointer",
                  height: "20px",
                  position: "absolute",
                  left: "0%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "all",
                }}
              />
            )}
            {showPassIcon2 && (
              <img
                src={passIcon2}
                alt="passwoord-icon"
                style={{
                  cursor: "pointer",
                  height: "20px",
                  position: "absolute",
                  left: "0%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "all",
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
