import React, { useEffect, useState } from "react";
import { getLedgerReport } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";
import Swal from "sweetalert2";

const LedgerReport = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 30 });
  const [filters, setFilters] = useState({});

  async function fetchApplicationExcel({ filterValues = {} } = {}) {
    if (
      !filterValues.merchant_id ||
      !filterValues.start_date ||
      !filterValues.end_date
    ) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Filters",
        text: "Please enter all filter fields before downloading the Excel report.",
      });
      return;
    }

    return await new Promise((resolve, reject) => {
      fetchLedgerReport({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  const fetchLedgerReport = ({
    filterValues = {},
    append = false,
    initial = false,
    resolve = false,
    reject = false,
  } = {}) => {
    const filteredObject = {};

    if (filterValues.start_date) {
      filteredObject["start_date"] = filterValues.start_date;
    }
    if (filterValues.end_date) {
      filteredObject["end_date"] = filterValues.end_date;
    }

    for (const key in filterValues) {
      if (key !== "start_date" && key !== "end_date") {
        filteredObject[key] = filterValues[key];
      }
    }

    setFilters(filteredObject);

    API({
      ...getLedgerReport,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
            }
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching ledger report: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchLedgerReport({ append: true, filterValues: filters });
    }
  }, [pagination]);

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchLedgerReport({ append: true, filterValues: filters });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Ledger Report
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchLedgerReport}
          excelFunc={fetchApplicationExcel}
          excelFileName={"Ledger Report"}
          filters={[
            {
              placeholder: "Merchant ID",
              id: "merchant_id",
              filterType: "input",
            },
            {
              placeholder: "Start Date",
              id: "start_date",
              filterType: "date",
              singleDate: true,
            },
            {
              placeholder: "End Date",
              id: "end_date",
              filterType: "date",
              singleDate: true,
            },
          ]}
        ></Filter2>
        <PaginationTable
          columns={[
            { Header: "Merchant ID", accessor: "merchant_id" },
            { Header: "Transaction ID", accessor: "transaction_id" },
            { Header: "Transaction Date", accessor: "transaction_date" },
            { Header: "Request Type", accessor: "request_type" },
            { Header: "Opening Balance", accessor: "opening_balance" },
            { Header: "Closing Balance", accessor: "closing_balance" },
            { Header: "Amount", accessor: "amount" },
            { Header: "CR DR", accessor: "cr_dr" },
            { Header: "Status", accessor: "status" },
            { Header: "Bank Operator", accessor: "bank_operator" },
            { Header: "Account No", accessor: "account_no" },
            { Header: "RRN UTR", accessor: "rrn_utr" },
            {
              Header: "Reverse Refund Status",
              accessor: "reverse_refund_status",
            },
            { Header: "Platform", accessor: "platform" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default LedgerReport;
