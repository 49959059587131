import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { add, circles, dashed, dashed2, removeStep, stepAdd } from "../images";
import InputForm from "../components/InputForm";
import Select from "react-select";
import API from "../components/api2";
import { helpDeskCategory, getAssignEmp, helpDeskPost } from "../urls";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddWorkflow = () => {
  const [pageData, setPageData] = useState({});
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([{ id: 1, title: "Step 1" }]);
  const [tabData, setTabData] = useState({
    0: {
      pageData: {
        escalation1: null,
        escalation1_tat: "",
        escalation2: null,
        escalation2_tat: "",
        escalation3: null,
      },
      selectedEmployees: [],
    },
  });

  const [stepIdMapping, setStepIdMapping] = useState({});

  // dropdowns for type, category, sub-category
  const handleTicketTypeChange = (selectedOption) => {
    setPageData({
      ...pageData,
      type: selectedOption.value,
      category: null,
      subcategory: null,
    });

    // Fetch categories based on type
    fetchCategories(selectedOption.value);
  };

  const handleCategoryChange = (selectedOption) => {
    setPageData({
      ...pageData,
      category: selectedOption.value,
      subcategory: null,
    });
    // Fetch subcategories based on category
    fetchSubcategories(pageData.type, selectedOption.value);
  };

  const handleSubcategoryChange = (selectedOption) => {
    setPageData({
      ...pageData,
      subcategory: selectedOption.value,
    });
  };

  const fetchCategories = (ticketType) => {
    API({
      ...helpDeskCategory,
      params: { type: ticketType },
      onSuccess: (res) => {
        setCategoryOptions(
          res.data.data.category_data.map((category) => ({
            value: category.category,
            label: category.category,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (type, category) => {
    API({
      ...helpDeskCategory,
      params: { type: type, category: category },
      onSuccess: (res) => {
        setSubcategoryOptions(
          res.data.data.subcategory_data.map((subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  // ************************************************

  // assign employess
  useEffect(() => {
    API({
      ...getAssignEmp,
      onSuccess: (res) => {
        const emp = res.data.data.map((e) => ({
          value: e.employee_code,
          label: `${e.employee_code}-${e.employee_name}`,
        }));
        setEmpOptions(emp);
      },
      onError: (error) => {
        console.error("Error employee: ", error);
      },
    });
  }, []);

  const handleAddEmployee = () => {
    setSelectedEmployees([...selectedEmployees, {}]);
  };

  // ************************************************

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allWorkflows",
      })
    );

    navigate("/");
  };

  // add workflow
  const handleSubmit = (e) => {
    e.preventDefault();
    const workflowSteps = tabs.map((tab, index) => ({
      step_id: index + 1,
      step_name: tabData[index]?.pageData.step_name || `Step ${index + 1}`,
      assigned_to:
        tabData[index]?.selectedEmployees.map((employee) => employee.value) ||
        [],
      tat: tabData[index]?.pageData.tat || "",
      escalation1: tabData[index]?.pageData.escalation1 || null,
      escalation1_tat: tabData[index]?.pageData.escalation1_tat || "",
      escalation2: tabData[index]?.pageData.escalation2 || null,
      escalation2_tat: tabData[index]?.pageData.escalation2_tat || "",
      escalation3: tabData[index]?.pageData.escalation3 || null,
    }));

    const {
      step_name,
      escalation1,
      escalation1_tat,
      escalation2,
      escalation2_tat,
      escalation3,
      tat,
      ...restPageData
    } = pageData;

    const submitData = {
      ...restPageData,
      steps: workflowSteps,
    };

    API({
      ...helpDeskPost,
      body: submitData,
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          changePageFunc();
        }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // steps *****************************

  // handle tab click
  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setTabData((prevData) => ({
        ...prevData,
        [activeTab]: {
          pageData,
          selectedEmployees,
        },
      }));
      const currentTabData = tabData[index] || {
        pageData: {},
        selectedEmployees: [],
      };
      setPageData(currentTabData.pageData);
      setSelectedEmployees(currentTabData.selectedEmployees);
      setActiveTab(index);
    }
  };

  // add steps
  const handleAddStep = () => {
    setTabs((prevTabs) => [
      ...prevTabs,
      { id: prevTabs.length + 1, title: `Step ${prevTabs.length + 1}` },
    ]);
  };

  // remove steps
  const handleRemoveStep = (indexToRemove) => {
    setTabs((prevTabs) => {
      const newTabs = prevTabs.filter((_, index) => index !== indexToRemove);
      return newTabs.map((tab, idx) => ({
        ...tab,
        id: idx + 1,
        title: `Step ${idx + 1}`,
      }));
    });

    setTabData((prevData) => {
      const newData = { ...prevData };
      delete newData[indexToRemove];
      const updatedData = Object.keys(newData).reduce((acc, key) => {
        const newIndex = parseInt(key, 10);
        if (newIndex > indexToRemove) {
          acc[newIndex - 1] = newData[key];
        } else if (newIndex < indexToRemove) {
          acc[newIndex] = newData[key];
        }
        return acc;
      }, {});
      return updatedData;
    });

    setActiveTab((prevActiveTab) => {
      if (prevActiveTab > indexToRemove) {
        return prevActiveTab - 1;
      } else if (prevActiveTab === indexToRemove) {
        return Math.max(0, prevActiveTab - 1);
      } else {
        return prevActiveTab;
      }
    });

    // Store the mapping of previous and new step IDs
    setStepIdMapping((prevMapping) => {
      const newMapping = { ...prevMapping };
      const removedId = indexToRemove + 1;
      const newId = Math.max(0, removedId - 1);
      newMapping[removedId] = newId;
      return newMapping;
    });

    const newIndex = Math.max(0, activeTab - 1);
    const currentTabData = tabData[newIndex] || {
      pageData: {},
      selectedEmployees: [],
    };
    setPageData(currentTabData.pageData);
    setSelectedEmployees(currentTabData.selectedEmployees);
  };

  // *************************************

  const renderContent = () => {
    const currentTabData = tabData[activeTab] || {
      pageData: {
        escalation1: null,
        escalation2: null,
        escalation3: null,
      },
      selectedEmployees: [],
    };
    const handlePageDataChange = (field, value) => {
      setPageData((prevPageData) => ({
        ...prevPageData,
        [field]: value,
      }));

      setTabData((prevTabData) => ({
        ...prevTabData,
        [activeTab]: {
          ...prevTabData[activeTab],
          pageData: {
            ...prevTabData[activeTab]?.pageData,
            [field]: value,
          },
          [field]: value,
        },
      }));
    };

    return (
      <>
        {/* step name  */}
        <div className="mx-4 px-4 mt-4 col-4">
          <InputForm
            type={"text"}
            placeholder={"Step Name"}
            id={"step_name"}
            name="step_name"
            valueState={pageData}
            onChange={(event) =>
              handlePageDataChange("step_name", event.target.value)
            }
          />
        </div>

        {/* Assign To */}
        <div
          className="mt-5 px-5 py-4 mx-5 position-relative dash_line"
          style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
          </div>
          <div className="row mt-4">
            {selectedEmployees.length === 0 && (
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`assigned_to`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`assigned_to`}
                  name={`assigned_to`}
                  options={empOptions}
                  value={selectedEmployees[0]}
                  onChange={(selectedOption) => {
                    setSelectedEmployees([selectedOption]);

                    setTabData((prevTabData) => ({
                      ...prevTabData,
                      [activeTab]: {
                        ...prevTabData[activeTab],
                        selectedEmployees: [selectedOption],
                      },
                    }));
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            )}
            {selectedEmployees.map((employee, index) => (
              <div className="col-6" key={index}>
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`assigned_to_${index}`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`assigned_to_${index}`}
                  name={`assigned_to_${index}`}
                  options={empOptions}
                  value={employee}
                  onChange={(selectedOption) => {
                    const updatedEmployees = [...selectedEmployees];
                    updatedEmployees[index] = selectedOption;
                    setSelectedEmployees(updatedEmployees);

                    setTabData((prevTabData) => ({
                      ...prevTabData,
                      [activeTab]: {
                        ...prevTabData[activeTab],
                        selectedEmployees: updatedEmployees,
                      },
                    }));
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            ))}
            <div className="col-6">
              <div
                className="col-6"
                style={{
                  border: "1.5px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  marginTop: "40px",
                }}
                onClick={handleAddEmployee}
              >
                <div
                  className="d-flex align-items-center py-1 px-2"
                  style={{ cursor: "pointer" }}
                >
                  <img src={add} alt="add-employee" />
                  <p
                    className="mb-0 ms-2"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    {" "}
                    Add Employee
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TAT */}
        <div
          className="mt-5 px-5 py-4 mx-5 position-relative dash_line2"
          style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
        >
          <h3
            className="mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            If ticket status not closed in
          </h3>
          <div className="row mt-4">
            <div className="col-6">
              <InputForm
                type={"time"}
                placeholder={"TAT (HH:MM)"}
                id={"tat"}
                name="tat"
                valueState={pageData}
                onChange={(event) =>
                  handlePageDataChange("tat", event.target.value)
                }
              />
            </div>
          </div>
        </div>

        {/* Escalation 1 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "25%",
              top: "-5%",
              width: "420px",
            }}
            src={dashed}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "10%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 1
          </p>
          <div
            className="col-8 px-5 py-4 mx-5 position-relative dash_line"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation1`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`escalation1`}
                  name={`escalation1`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation1
                      ? {
                          value: currentTabData.pageData.escalation1,
                          label: currentTabData.pageData.escalation1,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handlePageDataChange("escalation1", selectedOption.value)
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
          <div
            className="col-8 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <InputForm
                  type={"time"}
                  placeholder={"TAT (HH:MM)"}
                  id={"escalation1_tat"}
                  name="escalation1_tat"
                  valueState={pageData}
                  onChange={(event) =>
                    handlePageDataChange("escalation1_tat", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* Escalation 2 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "25%",
              top: "-5%",
              width: "420px",
            }}
            src={dashed}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "10%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 2
          </p>
          <div
            className="col-8 px-5 py-4 mx-5 position-relative dash_line"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation2`}
                  className="form-label"
                >
                  Select Employee
                </label>

                <Select
                  placeholder="Select Employee ID"
                  id={`escalation2`}
                  name={`escalation2`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation2
                      ? {
                          value: currentTabData.pageData.escalation2,
                          label: currentTabData.pageData.escalation2,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    handlePageDataChange("escalation2", selectedOption.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
          <div
            className="mt-5 col-8 px-5 py-4 mx-5 position-relative dash_line2"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <InputForm
                  type={"time"}
                  placeholder={"TAT (HH:MM)"}
                  id={"escalation2_tat"}
                  name="escalation2_tat"
                  valueState={pageData}
                  onChange={(event) =>
                    handlePageDataChange("escalation2_tat", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* Escalation 3 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "25%",
              top: "-14%",
              width: "420px",
            }}
            src={dashed2}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "10%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 3
          </p>
          <div
            className="col-8 px-5 py-4 mx-5 position-relative"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation3`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`escalation3`}
                  name={`escalation3`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation3
                      ? {
                          value: currentTabData.pageData.escalation3,
                          label: currentTabData.pageData.escalation3,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    handlePageDataChange("escalation3", selectedOption.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Workflow
        </h2>

        {/* workflow details */}
        <div
          className="py-2 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
          }}
        >
          Workflow Details
        </div>
        <div className="px-5 mt-3">
          <div className="col-6">
            <InputForm
              type={"text"}
              placeholder={"Name"}
              id={"workflow_name"}
              name="workflow_name"
              valueState={pageData}
              onChange={(event) =>
                setPageData((prevData) => ({
                  ...prevData,
                  [event.target.name]: event.target.value,
                }))
              }
            />
          </div>
          <label
            className="mb-0 mt-4"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="w-100 p-2 mt-2"
            style={{
              outline: "none",
              backgroundColor: "#FFF6D8",
              resize: "none",
              border: "none",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
            spellCheck={false}
            name="description"
            id="description"
            placeholder="Write workflow description here"
            valueState={pageData}
            onChange={(event) =>
              setPageData((prevData) => ({
                ...prevData,
                [event.target.name]: event.target.value,
              }))
            }
            rows="7"
          ></textarea>
        </div>

        {/* rules */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
          }}
        >
          Rules
        </div>

        {/* rules part 1  */}
        <div className="m-4 px-5">
          <div className="row">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="type"
                className="form-label ms-2"
              >
                Type
              </label>
              <Select
                id="type"
                name="type"
                placeholder="Select Type"
                value={
                  pageData.type
                    ? {
                        value: pageData.type,
                        label: pageData.type,
                      }
                    : null
                }
                onChange={handleTicketTypeChange}
                options={[
                  { value: "Software", label: "Software" },
                  { value: "Hardware", label: "Hardware" },
                ]}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="category"
                className="form-label ms-2"
              >
                Category
              </label>
              <Select
                id="category"
                name="category"
                placeholder="Select Category"
                valueState={
                  pageData.category
                    ? {
                        value: pageData.category,
                        label: pageData.category,
                      }
                    : null
                }
                onChange={handleCategoryChange}
                options={categoryOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
              />
            </div>
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="subcategory"
                className="form-label ms-2"
              >
                Sub Category
              </label>
              <Select
                id="subcategory"
                name="subcategory"
                placeholder="Select Sub Category"
                valueState={
                  pageData.subcategory
                    ? {
                        value: pageData.subcategory,
                        label: pageData.subcategory,
                      }
                    : null
                }
                onChange={handleSubcategoryChange}
                options={subcategoryOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
              />
            </div>
          </div>
        </div>

        {/* workflow steps  */}
        <div className="pt-4">
          <h2
            className="mx-4 px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontSize: "16px",
            }}
          >
            Workflow Steps:
          </h2>
          <div className="mx-4 mt-3 px-4">
            {tabs.map((tab, index) => (
              <button
                style={{
                  border: "1.2px solid rgba(105, 102, 102, 1)",
                  borderTop: "1.2px solid rgba(105, 102, 102, 1)",
                  borderLeft: "1.2px solid rgba(105, 102, 102, 1)",
                  borderRight: "1.2px solid rgba(105, 102, 102, 1)",
                  width: "120px",
                  borderBottom:
                    index === activeTab
                      ? "none"
                      : "1.2px solid rgba(105, 102, 102, 1)",
                  borderRadius: index === activeTab ? "5px 5px 0 0" : "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(105, 102, 102, 1)",
                  padding: index === activeTab ? "10px" : "6px 10px",
                }}
                key={index}
                className="me-2 bg-white position-relative"
                onClick={() => handleTabClick(index)}
              >
                {tab.title}

                {index > 0 || tabs.length > 1 ? (
                  <img
                    style={{
                      marginTop: "-5px",
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    src={removeStep}
                    className="ms-2"
                    alt="step-remove"
                    onClick={() => handleRemoveStep(index)}
                  />
                ) : null}

                {index === activeTab && (
                  <div className="workflow_selected_tab" />
                )}
              </button>
            ))}

            <img
              style={{
                height: "36px",
                cursor: "pointer",
                pointerEvents: "all",
                marginTop: "-5px",
              }}
              src={stepAdd}
              alt="add-step"
              onClick={() => handleAddStep()}
            />
          </div>
          <div>{renderContent()}</div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5 mx-5 pt-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button onClick={handleSubmit} children={"Submit"} />
        </div>
      </div>
    </>
  );
};

export default AddWorkflow;
