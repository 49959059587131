import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getBusinessBrand,
  updateBusinessBrand,
  addComments,
  getComments,
  addWallet,
  getBrandWallet,
  freezeWallet,
  actionBrand,
  activeWallet,
} from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import {
  active,
  brandWalletSuccess,
  circles,
  inactive,
  lock,
  pdfViewIcon,
  unlock,
} from "../images";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import ImageViewer from "../components/ImageViewer";
import Swal from "sweetalert2";
import Alert from "../components/Alert";
import { Modal } from "react-bootstrap";
import tickMark from "../gif/tickMark.gif";

const BrandInformation = ({ roles }) => {
  const location = useLocation();
  const brand_id = location.state?.brand_id;
  const [brandData, setBrandData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFreeze, setShowAlertFreeze] = useState(false);
  const [showAlertVerify, setShowAlertVerify] = useState(false);
  const [showAlertReject, setShowAlertReject] = useState(false);
  const [showAlertApprove, setShowAlertApprove] = useState(false);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [walletColor, setWalletColor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTextFreeze, setAlertTextFreeze] = useState("");
  const [alertTextVerify, setAlertTextVerify] = useState("");
  const [alertTextReject, setAlertTextReject] = useState("");
  const [alertTextApprove, setAlertTextApprove] = useState("");
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [isClickFreezeTriggered, setIsClickFreezeTriggered] = useState(false);
  const [walletStatus, setWalletStatus] = useState({});
  const [editableFields, setEditableFields] = useState({
    business_name: "",
    legal_name: "",
    pan_number: "",
    tan_number: "",
    cinn_llpin: "",
    gst_number: "",
    contact_person: "",
    contact_no: "",
    mailing_city: "",
    contact_email: "",
    mailing_pin: "",
    mailing_state: "",
    mailing_address: "",
    kyc_contact_person: "",
    kyc_contact_no: "",
    kyc_mailing_city: "",
    kyc_contact_email: "",
    kyc_mailing_pin: "",
    kyc_mailing_state: "",
    kyc_mailing_address: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBrandData();
    fetchComments();
    fetchWalletData();
  }, []);

  const hasRole = (role) => roles.includes(role);

  // alert
  const hideAlert = () => {
    setShowAlert(false);
    setShowAlertFreeze(false);
    setShowAlertVerify(false);
    setShowAlertReject(false);
    setShowAlertApprove(false);
    setShowAlertActive(false);
    setShowAlertUpdate(false);
  };

  const showAlertWithText = (text, walletColor) => {
    setAlertText(text);
    setShowAlert(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextFreeze = (text, walletColor) => {
    setAlertTextFreeze(text);
    setShowAlertFreeze(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextVerify = (text, walletColor) => {
    setAlertTextVerify(text);
    setShowAlertVerify(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextReject = (text, walletColor) => {
    setAlertTextReject(text);
    setShowAlertReject(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextApprove = (text, walletColor) => {
    setAlertTextApprove(text);
    setShowAlertApprove(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };

  const handleClick = () => {
    showAlertWithText("Are you sure you want to", "green");
  };
  const handleClickFreeze = () => {
    setIsClickFreezeTriggered(true);
    showAlertWithTextFreeze("Are you sure you want to", "blue");
  };
  const handleClickVerify = () => {
    showAlertWithTextVerify("Are you sure you want to", "green");
  };
  const handleClickReject = () => {
    showAlertWithTextReject("Are you sure you want to", "red");
  };
  const handleClickApprove = () => {
    showAlertWithTextApprove("Are you sure you want to", "green");
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  // create wallet
  const handleConfirm = () => {
    API({
      ...addWallet,
      body: { platform_id: "1", brand_id: brand_id },
      onSuccess: (res) => {
        setShowModal(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlert(false);
  };

  // verify brand
  const handleConfirmVerify = () => {
    API({
      ...actionBrand,
      body: { brand_id: brand_id, brand_status: "VERIFIED" },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertVerify(false);
  };
  // reject brand
  const handleConfirmReject = () => {
    API({
      ...actionBrand,
      body: { brand_id: brand_id, brand_status: "REJECT" },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The wallet has been rejected `,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertReject(false);
  };
  // approve brand
  const handleConfirmApprove = () => {
    API({
      ...actionBrand,
      body: { brand_id: brand_id, brand_status: "APPROVED" },
      onSuccess: (res) => {
        Swal.fire({
          title: ` ${res.data.message} `,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertApprove(false);
  };
  // fetch brand
  const fetchBrandData = () => {
    API({
      ...getBusinessBrand,
      params: {
        brand_id: brand_id,
      },
      onSuccess: (res) => {
        setBrandData(res.data.data);
        setEditableFields(res.data.data);
        setWalletStatus(res.data.data.wallet_status);
        setIsLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching brand data: ", error);
        setIsLoading(false);
      },
    });
  };

  const { brand_status, wallet_status, active_status } = brandData;
  const initialIsActive = active_status != "A";
  const initialIsFreeze = wallet_status != "ACTIVE";

  const [isActive, setIsActive] = useState(initialIsActive);
  const [isLocked, setIsLocked] = useState(initialIsFreeze);
  useEffect(() => {
    setIsActive(active_status != "A");
  }, [active_status]);

  useEffect(() => {
    setIsLocked(wallet_status != "ACTIVE");
  }, [wallet_status]);

  // freeze wallet

  const handleConfirmFreeze = (actionText) => {
    const walletConfirm = actionText === "Freeze Wallet" ? 0 : 1;
    API({
      ...freezeWallet,
      body: { wallet_id: walletData, wallet_status: walletConfirm },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The wallet has been successfully ${
            walletConfirm === 0 ? "froze" : "unfroze"
          } `,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertFreeze(false);
    setIsClickFreezeTriggered(false);
  };
  const toggleLock = () => {
    setIsLocked((prev) => !prev);
  };

  // active/inactive
  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? 1 : 0;
    API({
      ...activeWallet,
      body: { brand_id: brand_id, active_status: isActive },
      onSuccess: (res) => {
        Swal.fire({
          title: `The wallet has been successfully ${
            isActive === 0 ? "Deactivated" : "Activated"
          }`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => !prev);
  };

  // fetch wallet
  const fetchWalletData = () => {
    API({
      ...getBrandWallet,
      params: {
        brand_id: brand_id,
      },
      onSuccess: (res) => {
        setWalletData(res.data.data.wallet_id);
      },
      onError: (error) => {
        console.error("Error fetching wallet: ", error);
      },
    });
  };

  // update
  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    if (type === "text") {
      const regex = /^[a-zA-Z0-9\s]*$/;
      if (!regex.test(sanitizedValue)) {
        return;
      }
    }

    if (type === "number") {
      const regex = /^[0-9]*$/;
      if (!regex.test(sanitizedValue)) {
        return;
      }
      sanitizedValue = sanitizedValue.slice(0, 10);
    }

    // Update state
    setEditableFields((prevFields) => ({
      ...prevFields,
      [name]: sanitizedValue,
    }));
  };

  const handleUpdate = () => {
    API({
      ...updateBusinessBrand,
      body: editableFields,
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // comments
  const fetchComments = () => {
    API({
      ...getComments,
      params: {
        application_no: brand_id,
        user_type: "BRAND",
      },
      onSuccess: (res) => {
        setComments(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching comments: ", error);
      },
    });
  };
  const handleAddComment = () => {
    const comment = document.getElementById("comments").value;
    if (!comment.trim()) {
      Swal.fire({
        icon: "error",
        title: `comment cannot be empty`,
      });
      return;
    }
    API({
      ...addComments,
      body: {
        application_no: brand_id,
        user_type: "BRAND",
        comments: comment,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        console.error("Error adding comment: ", error);
      },
    });
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allotPackage",
        brand_id: brand_id,
      })
    );

    navigate("/", {
      state: { brandData: brandData },
    });
  };
  const handleBack = () => {
    dispatch(
      changePage({
        page: "updateBusinessBrand",
      })
    );

    navigate("/");
  };

  const [buttonWalletText, setButtonWalletText] = useState("Create Wallet");
  const [buttonVerifyText, setButtonVerifyText] = useState("Verify");
  const [buttonApproveText, setButtonApproveText] = useState("Approve");
  const [buttonRejectText, setButtonRejectText] = useState("Reject");

  useEffect(() => {
    if (wallet_status === "ACTIVE") {
      setButtonWalletText("Wallet Created");
    }
    if (
      brand_status === "VERIFIED" ||
      brand_status === "ACTIVE" ||
      brand_status === "REJECT"
    ) {
      setButtonVerifyText("Verified");
    }
    if (brand_status === "ACTIVE") {
      setButtonApproveText("Approved");
    }
    if (brand_status === "REJECT") {
      setButtonRejectText("Rejected");
    }
  }, [brand_status, wallet_status]);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between px-3 pb-4"
        >
          <h2
            className="mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Brand Information: {brand_id}
          </h2>

          <div
            style={{ marginRight: "60px" }}
            className="d-flex align-items-center"
          >
            {brand_status != "NEW" && (
              <>
                {/* freeze/unfreeze  */}
                {hasRole("BRAND_FREEZE") && (
                  <div
                    className="p-2"
                    style={{
                      backgroundColor: "rgba(25, 66, 128, 1)",
                      borderRadius: "10px",
                      width: "160px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={handleClickFreeze}
                    >
                      <img
                        style={{ cursor: "pointer", pointerEvents: "all" }}
                        src={!isLocked ? lock : unlock}
                        alt={!isLocked ? "freeze-wallet" : "unfreeze-wallet"}
                      />
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: !isLocked
                            ? "rgba(195, 208, 224, 0.6)"
                            : "white",
                        }}
                        className="mb-0 ms-2"
                        onClick={toggleLock}
                      >
                        {!isLocked ? "Freeze Wallet" : "Unfreeze Wallet"}
                      </p>
                    </div>
                  </div>
                )}

                {/* active/inactive  */}
                {hasRole("BRAND_ACTIVE") && (
                  <div
                    onClick={handleClickActive}
                    className="d-flex align-items-center ms-4"
                  >
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isActive}
                        onChange={handleToggleSwitch}
                      />
                      <span
                        className="slider round"
                        data-content={sliderContent}
                        style={{
                          color: isActive ? "#AAAEB0" : "#AAAEB0",
                          fontWeight: "500",
                          paddingLeft: isActive ? "80px" : "10px",
                          paddingTop: "2px",
                        }}
                      >
                        {isActive ? "Active" : "Inactive"}
                      </span>
                    </label>
                    <img
                      className="ms-2 toggleUser"
                      src={imageSource}
                      alt={isActive ? "Inactive" : "Active"}
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        pointerEvents: "all",
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <Alert
            show={showAlertActive}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
          <Alert
            show={showAlertFreeze}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isLocked ? "freeze" : "unfreeze"}
            message={alertTextFreeze}
            walletColor={walletColor}
            onConfirm={() =>
              handleConfirmFreeze(
                isLocked ? "Freeze Wallet" : "Unfreeze Wallet"
              )
            }
          />
        </div>
        {/* Information  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-3 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Brand Information
          </h3>
          <div className="row align-items-center mt-4 px-5">
            <div className="col-8">
              <div className="row justify-content-between">
                <div className="col-6">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Brand Name
                  </p>
                  <input
                    type="text"
                    name="business_name"
                    className="p-2 w-100 mt-1"
                    value={editableFields.business_name}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                  />
                </div>
                <div className="col-6">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Legal Name
                  </p>
                  <input
                    type="text"
                    name="legal_name"
                    className="p-2 w-100 mt-1"
                    value={editableFields.legal_name}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                  />
                </div>
                <div className="col-6 mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    PAN
                  </p>
                  <input
                    type="text"
                    name="pan_number"
                    maxLength={10}
                    className="p-2 w-100 mt-1"
                    value={editableFields.pan_number}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      fontFamily: "Montserrat",
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                  />
                </div>
                <div className="col-6 mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    TAN
                  </p>
                  <input
                    type="text"
                    name="tan_number"
                    className="p-2 w-100 mt-1"
                    maxLength={10}
                    value={editableFields.tan_number}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                  />
                </div>
                <div className="col-6 mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    CIN
                  </p>
                  <input
                    type="text"
                    name="cinn_llpin"
                    className="p-2 w-100 mt-1"
                    value={editableFields.cinn_llpin}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                    maxLength={21}
                  />
                </div>
                <div className="col-6 mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    GST
                  </p>
                  <input
                    type="text"
                    name="gst_number"
                    className="p-2 w-100 mt-1"
                    maxLength={15}
                    value={editableFields.gst_number}
                    onChange={(e) => handleInputChange(e, "text")}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    disabled={!hasRole("BRAND_EDIT")}
                  />
                </div>
              </div>
            </div>
            <div className="col-4 text-center">
              <img
                style={{
                  border: "1.5px solid rgba(217, 217, 217, 1)",
                  borderRadius: "22px",
                  width: "150px",
                  height: "150px",
                }}
                src={brandData?.company_logo}
                alt="demo"
              />
            </div>
          </div>
        </div>

        {/* mailing address  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Mailing Address
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact Person
                    </p>

                    <input
                      type="text"
                      name="contact_person"
                      className="p-2 w-100 mt-1"
                      value={editableFields.contact_person}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact No
                    </p>
                    <input
                      type="number"
                      name="contact_no"
                      className="p-2 w-100 mt-1"
                      value={editableFields.contact_no}
                      onChange={(e) => handleInputChange(e, "number")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      City
                    </p>
                    <input
                      type="text"
                      name="mailing_city"
                      className="p-2 w-100 mt-1"
                      value={editableFields.mailing_city}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact Mail
                    </p>
                    <input
                      type="text"
                      name="contact_email"
                      className="p-2 w-100 mt-1"
                      value={editableFields.contact_email}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Pin
                    </p>
                    <input
                      type="text"
                      name="mailing_pin"
                      className="p-2 w-100 mt-1"
                      value={editableFields.mailing_pin}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      maxLength={6}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      State
                    </p>
                    <input
                      type="text"
                      name="mailing_state"
                      className="p-2 w-100 mt-1"
                      value={editableFields.mailing_state}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 text-center">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>

                <textarea
                  className="w-100 p-2"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("BRAND_EDIT")}
                  spellCheck={false}
                  value={editableFields.mailing_address}
                  onChange={(e) => handleInputChange(e, "text")}
                  name="mailing_address"
                  id="mailing_address"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        {/* support contact  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Support Contact Details
          </h3>
          <div className="row mt-4 px-5 justify-content-between">
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact Person
                    </p>
                    <input
                      type="text"
                      name="kyc_contact_person"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_contact_person}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact No
                    </p>
                    <input
                      type="number"
                      name="kyc_contact_no"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_contact_no}
                      onChange={(e) => handleInputChange(e, "number")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      City
                    </p>

                    <input
                      type="text"
                      name="kyc_mailing_city"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_mailing_city}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Contact Mail
                    </p>
                    <input
                      type="text"
                      name="kyc_contact_email"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_contact_email}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      Pin
                    </p>
                    <input
                      type="text"
                      name="kyc_mailing_pin"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_mailing_pin}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      maxLength={6}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                  <div className="mt-4">
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        color: "rgba(173, 131, 82, 1)",
                      }}
                    >
                      State
                    </p>
                    <input
                      type="text"
                      name="kyc_mailing_state"
                      className="p-2 w-100 mt-1"
                      value={editableFields.kyc_mailing_state}
                      onChange={(e) => handleInputChange(e, "text")}
                      style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                      }}
                      disabled={!hasRole("BRAND_EDIT")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4 text-center">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>

                <textarea
                  className="w-100 p-2"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  disabled={!hasRole("BRAND_EDIT")}
                  spellCheck={false}
                  value={editableFields.kyc_mailing_address}
                  onChange={(e) => handleInputChange(e, "text")}
                  name="kyc_mailing_address"
                  id="kyc_mailing_address"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        {/* Attachment  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Attachment
          </h3>
          <div className="row mx-4 mt-4">
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute zoom"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  GST Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="110px"
                  src={brandData?.gstn_certificate}
                  alt="gstn_certificate"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute zoom"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  CIN Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="110px"
                  className="px-3 py-2"
                  src={brandData?.cinn_certificate}
                  alt="cinn_certificate"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute zoom"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  PAN Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="110px"
                  className="w-100 px-3 py-2"
                  src={brandData?.pan_docs}
                  alt="pan_docs"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr img_viewer_container"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute zoom"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  TAN Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="110px"
                  className="w-100 px-3 py-2"
                  src={brandData?.tan_docs}
                  alt="tan_docs"
                />
              </div>
            </div>
          </div>
        </div>

        {/* comments  */}
        <div className="mt-4 pb-3">
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Comment
          </h3>
          <div
            className="mt-4 mx-5 px-5 py-3"
            style={{
              backgroundColor: "rgba(231, 232, 233, 1)",
              height: "260px",
              borderRadius: "14px",
              overflowY: "auto",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  User
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Comment
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Commented on
                </p>
              </div>
            </div>
            {comments.map((comment) => (
              <div
                key={comment.comment_id}
                className="row d-flex justify-content-around"
              >
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.username}
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.comments}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {formatDate(comment.created_on)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 mt-3 ms-3"
            >
              Comment
            </p>
            <div
              style={{
                backgroundColor: "#FFF6D8",
                borderRadius: "14px",
                border: "1.5px solid #D9D9D9",
              }}
            >
              <div className="d-flex align-items-center py-2">
                <textarea
                  className="w-100 px-3"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "14px",
                  }}
                  name="comments"
                  placeholder="Write"
                  id="comments"
                  rows="4"
                ></textarea>
                <button
                  style={{
                    borderRadius: "24px",
                    backgroundColor: "#FFF6D8",
                    border: "1px solid black",
                  }}
                  className="mx-3 px-3 py-1"
                  onClick={handleAddComment}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* buttons  */}
        <div className="mt-5 d-flex justify-content-between align-items-center px-5">
          <div>
            <Button onClick={handleBack} children={"Back"}></Button>
          </div>
          <div>
            {hasRole("BRAND_WALLET") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                disabled={
                  brand_status != "ACTIVE" || wallet_status === "ACTIVE"
                }
                onClick={handleClick}
              >
                {buttonWalletText}
              </button>
            )}
            {hasRole("BP_ALLOT") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                disabled={brand_status != "ACTIVE"}
                onClick={changePageFunc}
              >
                Allot Package
              </button>
            )}
            <Alert
              show={showAlert}
              onHide={hideAlert}
              title="Confirmation"
              actionText="create"
              message={alertText}
              walletColor={walletColor}
              onConfirm={handleConfirm}
            />
            <Alert
              show={showAlertUpdate}
              onHide={hideAlert}
              title="Confirmation"
              actionText="update"
              message={alertTextUpdate}
              walletColor={walletColor}
              onConfirm={handleUpdate}
            />
            {hasRole("BRAND_EDIT") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                  width: "118px",
                }}
                onClick={handleClickUpdate}
              >
                Update
              </button>
            )}
            {hasRole("VERIFY_BRAND") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                  width: "118px",
                }}
                disabled={
                  brand_status === "VERIFIED" ||
                  brand_status === "ACTIVE" ||
                  brand_status === "REJECT"
                }
                onClick={handleClickVerify}
              >
                {buttonVerifyText}
              </button>
            )}
            <Alert
              show={showAlertVerify}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"verify"}
              message={alertTextVerify}
              walletColor={walletColor}
              onConfirm={handleConfirmVerify}
            />
            <Alert
              show={showAlertReject}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"reject"}
              message={alertTextReject}
              walletColor={walletColor}
              onConfirm={handleConfirmReject}
            />
            <Alert
              show={showAlertApprove}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"approve"}
              message={alertTextApprove}
              walletColor={walletColor}
              onConfirm={handleConfirmApprove}
            />
            {hasRole("REJECT_BRAND") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  borderRadius: "5px",
                  border: "0",
                  fontFamily: "Montserrat",
                  backgroundColor: "rgba(249, 124, 75, 1)",
                  fontWeight: "500",
                  fontSize: "16px",
                  width: "118px",
                  color: "white",
                  opacity: brand_status === "ACTIVE" ? "0.5" : "1",
                }}
                disabled={
                  brand_status === "ACTIVE" || brand_status != "VERIFIED"
                }
                onClick={handleClickReject}
              >
                {buttonRejectText}
              </button>
            )}
            {hasRole("APPROVE_BRAND") && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1.5px solid rgba(98, 192, 140, 1)",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "16px",
                  width: "118px",
                  backgroundColor: "rgba(98, 192, 140, 1)",
                  opacity: brand_status === "REJECT" ? "0.5" : "1",
                }}
                disabled={
                  brand_status === "ACTIVE" || brand_status != "VERIFIED"
                }
                onClick={handleClickApprove}
              >
                {buttonApproveText}
              </button>
            )}
          </div>
        </div>
        <Modal
          className="custom-modal"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <div className="position-relative">
            <img
              onClick={() => setShowModal(false)}
              style={{
                width: "300px",
                cursor: "pointer",
                pointerEvents: "all",
                margin: "150px 0 0 330px",
              }}
              src={brandWalletSuccess}
              alt="brand-wallet"
            />
            <img
              style={{ width: "65px", left: "56%", top: "51%" }}
              className="position-absolute"
              src={tickMark}
              alt="tick"
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(BrandInformation);
