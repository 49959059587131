import React, { useState } from "react";
import API from "../components/api2";
import { addMenus } from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";

const NewMenu = () => {
  const [menuName, setMenuName] = useState("");

  function onSubmit(e) {
    e.preventDefault();

    API({
      ...addMenus,
      body: { menu_name: menuName },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start">
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add Menu
          </h2>
          <div className="mt-4 col-6">
            <InputForm
              type={"text"}
              id={"menu_name"}
              name="menu_name"
              placeholder={"Menu Name"}
              value={menuName}
              onChange={(event) => setMenuName(event.target.value)}
            />
          </div>
          <div className="mt-4 d-flex justify-content-between col-6">
            <Button children={"Back"} />
            <Button type="submit" children={"Add"} />
          </div>
        </form>
      </div>
    </>
  );
};

export default NewMenu;
