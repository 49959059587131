import React, { useEffect, useState } from "react";
import API from "../components/api2";
import { circles } from "../images";
import Header from "../components/Header";
import { aepsToMain, getWallet } from "../urls";
import Button from "../components/Button";
import Swal from "sweetalert2";
import loaderGif from "../gif/buffer.gif";

const AepsWalletTransfer = () => {
  const [aepsBalance, setAepsBalance] = useState(0);
  const [transferOption, setTransferOption] = useState("");
  const [amount, setAmount] = useState("");
  const [isAmountInputVisible, setIsAmountInputVisible] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Fetch balance
  useEffect(() => {
    API({
      ...getWallet,
      onSuccess: (res) => {
        const aepsClosingBalance =
          parseFloat(res.data.data.aeps_closing_balance) || 0;
        setAepsBalance(aepsClosingBalance);
      },
      onError: (error) => {
        console.error("Error fetching balance: ", error);
      },
    });
  }, []);

  const handleTransferOptionChange = (e) => {
    setTransferOption(e.target.value);
  };

  const handleSelectClick = () => {
    setIsAmountInputVisible(true);
  };

  const handleAmountChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    setAmount(e.target.value);
    setIsConfirmEnabled(enteredAmount > 100 && enteredAmount <= aepsBalance);
  };

  const handleConfirmTransfer = () => {
    Swal.fire({
      title: "Please confirm to continue",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter your 6-digit UPIN",
          input: "password",
          inputAttributes: {
            maxlength: 6,
            minlength: 6,
            inputMode: "numeric",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: (upin) => {
            if (!/^\d{6}$/.test(upin)) {
              Swal.showValidationMessage("Please enter a valid 6-digit UPIN");
              return false;
            }
            return upin;
          },
        }).then((result) => {
          if (result.isConfirmed && result.value) {
            transferToMain(result.value);
          }
        });
      }
    });
  };

  // Transfer to main wallet API call
  const transferToMain = (upin) => {
    setIsLoading(true);
    API({
      ...aepsToMain,
      body: { transaction_amount: amount, upin: upin },
      onSuccess: (res) => {
        setIsLoading(false);
        Swal.fire({
          title: `Transfer Successful!`,
          text: `₹ ${amount} has been transferred from your AEPS Wallet to your Main Wallet.`,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            AEPS Wallet Transfer
          </h2>
        </div>
        {isLoading ? (
          <div
            className="loading-container"
            style={{ textAlign: "center", marginTop: "100px" }}
          >
            <img
              src={loaderGif}
              alt="loading"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        ) : (
          <>
            {/* Wallet info */}
            <div className="mt-5 px-5">
              <h3
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                AEPS Wallet Information :
              </h3>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "15px",
                }}
                className="mb-0 mt-4"
              >
                AEPS Wallet Balance :{" "}
                <span
                  className="ms-2"
                  style={{ color: "#AD8352", fontFamily: "Montserrat" }}
                >
                  Rs {aepsBalance}
                </span>
              </p>
            </div>

            {/* Transfer money option */}
            <div className="mt-5 px-5">
              {!isAmountInputVisible ? (
                <>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                    className="mb-0 mt-4"
                  >
                    Select where to transfer the money :
                  </p>
                  <div className="mt-4">
                    <label
                      style={{
                        color: "#696666",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center"
                    >
                      <input
                        type="radio"
                        value="bank"
                        checked={transferOption === "bank"}
                        onChange={handleTransferOptionChange}
                        className="me-3"
                        disabled
                      />
                      AEPS Wallet to Bank
                    </label>
                    <label
                      style={{
                        color: "#696666",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center mt-3"
                    >
                      <input
                        type="radio"
                        value="mainWallet"
                        checked={transferOption === "mainWallet"}
                        onChange={handleTransferOptionChange}
                        className="me-3"
                      />
                      AEPS Wallet to Main Wallet
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "15px",
                      color: "#696666",
                    }}
                    className="mb-0 mt-4"
                  >
                    Enter Amount to Transfer
                  </label>
                  <input
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    className="form-control mt-2"
                    placeholder="Amount"
                    style={{
                      maxWidth: "300px",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#ACA1A1",
                    }}
                    className="mt-3"
                  >
                    Entered amount should be &gt; 100 and &le; AEPS Wallet
                    Balance
                  </p>
                </>
              )}

              <div className="d-flex justify-content-between align-items-center mt-4">
                <Button
                  children={
                    isAmountInputVisible ? "Confirm Transfer" : "Select"
                  }
                  onClick={
                    isAmountInputVisible
                      ? handleConfirmTransfer
                      : handleSelectClick
                  }
                  disabled={!isConfirmEnabled && isAmountInputVisible}
                  style={{
                    opacity:
                      (!isAmountInputVisible && transferOption) ||
                      isConfirmEnabled
                        ? "1"
                        : "0.5",
                    cursor:
                      (!isAmountInputVisible && transferOption) ||
                      isConfirmEnabled
                        ? "pointer"
                        : "not-allowed",
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AepsWalletTransfer;
