import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../components/api2";
import {
  getMenusDropdown,
  getAssignedMenus,
  saveAvailableMenus,
  saveAssignedMenus,
} from "../urls";
import Header from "../components/Header";
import { circles } from "../images";
import Swal from "sweetalert2";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";

const RoleInformation = ({ username }) => {
  const [availMenus, setAvailMenus] = useState([]);
  const [assignMenus, setAssignMenus] = useState([]);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [isSystemDefined, setIsSystemDefined] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { role } = location.state || {};

  const fetchAvailMenus = () => {
    API({
      ...getMenusDropdown,
      onSuccess: (res) => {
        setAvailMenus(res.data.data);
      },
      onError: (error) => {
        console.log("No menus available");
      },
    });
  };
  const fetchAssignMenus = () => {
    API({
      ...getAssignedMenus,
      params: {
        role_id: role.role_id,
      },
      onSuccess: (res) => {
        setAssignMenus(res.data.data.menus);
        setIsSystemDefined(res.data.data.issystem_defined);
      },
      onError: (error) => {
        console.log("No menus assigned");
      },
    });
  };

  const handleMenuClick = (menu) => {
    const isAssigned = assignMenus.some(
      (assignedMenu) => assignedMenu.menu_id === menu.menu_id
    );

    if (isAssigned) {
      const updatedAssignedMenus = assignMenus.filter(
        (assignedMenu) => assignedMenu.menu_id !== menu.menu_id
      );
      setAssignMenus(updatedAssignedMenus);

      const updatedAvailMenus = [...availMenus, menu];
      setAvailMenus(updatedAvailMenus);
    } else {
      const updatedAvailMenus = availMenus.filter(
        (availMenu) => availMenu.menu_id !== menu.menu_id
      );
      setAvailMenus(updatedAvailMenus);

      const updatedAssignMenus = [...assignMenus, menu];
      setAssignMenus(updatedAssignMenus);
    }
  };

  const deleteAssignedMenu = (menuToDelete) => {
    const menusToSend = assignMenus.filter(
      (menu) => menu.menu_name !== menuToDelete.menu_name
    );

    setAssignMenus(menusToSend);
  };

  const handleUpdateClick = (menuToDelete) => {
    const clickedMenu = assignMenus[assignMenus.length - 1];

    if (menuToDelete) {
      const menusToSend = assignMenus.filter(
        (menu) => menu.menu_id !== menuToDelete.menu_id
      );
      setAssignMenus(menusToSend);

      API({
        ...saveAssignedMenus,
        body: {
          role: role.role_id,
          menu: menusToSend.map((menu) => menu.menu_id),
          issystem_defined: isSystemDefined,
        },
        onSuccess: (res) => {
          Swal.fire({
            icon: "success",
            title: `${res.data.message}`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
          console.error("Error deleting assigned menu");
        },
      });
    } else if (clickedMenu) {
      API({
        ...saveAvailableMenus,
        body: {
          role_id: role.role_id,
          menu_name: clickedMenu.menu_name,
          issystem_defined: isSystemDefined,
        },
        onSuccess: (res) => {
          console.log("Assigned menu updated successfully");
          Swal.fire({
            icon: "success",
            title: `${res.data.message}`,
          });
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
          console.error("Error updating assigned menu");
        },
      });
    }
  };

  const filteredAvailMenus = availMenus.filter((menu) => {
    const menuNameMatches = menu.menu_name
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());
    const menuIdMatches = menu.menu_id
      .toString()
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());

    return menuNameMatches || menuIdMatches;
  });
  const filteredAssignedRoles = Array.isArray(assignMenus)
    ? assignMenus.filter(
        (menu) =>
          menu.menu_name
            .toLowerCase()
            .includes(searchTermAssigned.toLowerCase()) ||
          (menu.menu_id &&
            menu.menu_id
              .toString()
              .toLowerCase()
              .includes(searchTermAssigned.toLowerCase()))
      )
    : [];

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "manageRoles",
      })
    );

    navigate("/");
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const handleCheckboxChange = (event) => {
    setIsSystemDefined(event.target.checked);
  };

  useEffect(() => {
    if (role) {
      setIsSystemDefined(role.issystem_defined);
    }
    fetchAvailMenus();
    fetchAssignMenus();
  }, [role]);
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-3 position-relative overflow-y-scroll container-fluid h-100"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="mx-4 pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Assign Menus
          </h2>
        </div>
        <div className="mx-4 mt-3">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
            className="mb-0"
          >
            Role Details
          </p>
          <div className="row mt-3 px-3">
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Role ID : {role.role_id}
              </p>
            </div>
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Role Name : {role.role_name}
              </p>
            </div>
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created On : {formatDate(role.created_on)}
              </p>
            </div>
            <div className="col-4 mt-3">
              <input
                type="checkbox"
                id="issystem_defined"
                name="issystem_defined"
                checked={isSystemDefined}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="issystem_defined"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
              >
                System Defined
              </label>
            </div>
          </div>
        </div>

        {/* assign menus  */}
        {!isSystemDefined && (
          <div className="row d-flex justify-content-between mx-2 mt-5">
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Available Menus
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAvailable}
                    onChange={(e) => setSearchTermAvailable(e.target.value)}
                    placeholder="Search Available Menus"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ overflowY: "auto", height: "300px" }}>
                    {filteredAvailMenus
                      .filter(
                        (menu) =>
                          !assignMenus.find(
                            (assignedMenu) =>
                              assignedMenu.menu_id === menu.menu_id
                          )
                      )
                      .map((menu, index) => (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(menu)}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            borderBottom:
                              index !== filteredAvailMenus.length - 1
                                ? "1px solid #D9D9D9"
                                : "none",
                          }}
                        >
                          <p
                            className="mb-0 mt-2"
                            style={{
                              padding: "4px 8px",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "rgba(105, 102, 102, 1)",
                            }}
                          >
                            {menu.menu_name
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </p>
                        </div>
                      ))}
                    {filteredAvailMenus.filter(
                      (menu) =>
                        !assignMenus.find(
                          (assignedMenu) =>
                            assignedMenu.menu_id === menu.menu_id
                        )
                    ).length === 0 && (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No available menus
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Assigned Menus
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAssigned}
                    onChange={(e) => setSearchTermAssigned(e.target.value)}
                    placeholder="Search Assigned Menus"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ overflowY: "auto", height: "300px" }}>
                    {filteredAssignedRoles.length === 0 ? (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No assigned menus
                      </div>
                    ) : (
                      filteredAssignedRoles.map((menu, index) => (
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            borderBottom:
                              index !== filteredAssignedRoles.length - 1
                                ? "1px solid #D9D9D9"
                                : "none",
                          }}
                          key={index}
                          onClick={() => deleteAssignedMenu(menu)}
                        >
                          <p
                            className="mb-0 mt-2"
                            style={{
                              padding: "4px 8px",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "rgba(105, 102, 102, 1)",
                            }}
                          >
                            {menu.menu_name
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-itmes-center mt-5 mx-3">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button children={"Update"} onClick={handleUpdateClick} />
        </div>
      </div>
    </>
  );
};

export default RoleInformation;
