import React from "react";

export const useWs = ({ url }) => {
  const [isReady, setIsReady] = React.useState(false);
  const [val, setVal] = React.useState(null);

  const ws = React.useRef(null);
  console.log(url, "url ");
  React.useEffect(() => {
    const socket = new WebSocket(url);

    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => setVal(event.data);

    ws.current = socket;
    return () => {
      socket.close();
    };
  }, []);

  return [isReady, val, ws.current?.send.bind(ws.current)];
};
