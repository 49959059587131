import React, { useState, useRef, useEffect } from "react";
import LoginNav from "../components/LoginNav";
import { dots, greenTick, liDot, upinDesc, upinIcon } from "../images";
import API from "../components/api2";
import { userPin } from "../urls";
import tick from "../gif/tick.gif";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import drone from "../gif/drone.gif";
import Swal from "sweetalert2";

const SetUpin = () => {
  const [upin, setUpin] = useState(["", "", "", "", "", ""]);
  const [confirmUpin, setConfirmUpin] = useState(["", "", "", "", "", ""]);
  const upinRefs = useRef([]);
  const confirmUpinRefs = useRef([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    new_user_pin: "",
    confirm_user_pin: "",
    isSuccess: false,
    pinHelpText: [
      {
        text: "The PIN must be a 6-digit number.",
        matched: false,
      },
      {
        text: "The PIN cannot contain sequential digits (e.g. 123456).",
        matched: false,
      },
      {
        text: "The PIN cannot contain repeated digits (e.g 111111,112233).",
        matched: false,
      },
      {
        text: "The PIN cannot be the same as the user's date of birth.",
        matched: false,
      },
    ],
  });

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Set UPIN");

  useEffect(() => {
    const isValid =
      upin.join("") === confirmUpin.join("") && validatePin() === null;

    setSubmitDisabled(!isValid);
  }, [upin, confirmUpin]);

  useEffect(() => {
    validatePin();
  }, [upin]);

  const validatePin = () => {
    const newPin = pageData.new_user_pin;

    if (newPin === "") {
      const emptyCriteria = pageData.pinHelpText.map((item) => ({
        ...item,
        matched: false,
      }));

      setPageData((prev) => ({
        ...prev,
        pinHelpText: emptyCriteria,
      }));

      return "PIN cannot be empty";
    }

    let pinHelpText = [
      {
        text: "The PIN must be a 6-digit number.",
        matched: /^\d{6}$/.test(newPin),
      },
      {
        text: "The PIN cannot contain sequential digits.",
        matched:
          !/(\d)\1{2}|(?:012|123|234|345|456|567|678|789|890|901|987|876|765|654|543|432|321|210)\d{3}/.test(
            newPin
          ),
      },
      {
        text: "The PIN cannot contain repeated digits (e.g 111111,112233).",
        matched: !/(.)\1{2}/.test(newPin),
      },
      {
        text: "The PIN cannot be the same as the user's date of birth.",
        matched: newPin !== "010190",
      },
    ];

    setPageData((prev) => ({ ...prev, pinHelpText }));

    return pinHelpText.every((item) => item.matched)
      ? null
      : "PIN does not match the criteria";
  };

  const handleUpinChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newUpin = [...upin];
    newUpin[index] = value;
    setUpin(newUpin);
    const newPin = newUpin.join("");
    setPageData((prev) => ({ ...prev, new_user_pin: newPin }));

    if (newPin === "") {
      setPageData((prev) => ({
        ...prev,
        pinHelpText: prev.pinHelpText.map((item) => ({
          ...item,
          matched: false,
        })),
      }));
    } else {
      const validationResult = validatePin();
      if (validationResult !== null) {
        setPageData((prev) => ({
          ...prev,
          pinHelpText: prev.pinHelpText.map((item, index) => ({
            ...item,
            matched: index === 0,
          })),
        }));
      }
    }

    if (value !== "" && index < upin.length - 1) {
      upinRefs.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      upinRefs.current[index - 1].focus();
    }
  };

  const handleConfirmUpinChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newConfirmUpin = [...confirmUpin];
    newConfirmUpin[index] = value;
    setConfirmUpin(newConfirmUpin);

    if (value !== "" && index < confirmUpin.length - 1) {
      confirmUpinRefs.current[index + 1].focus();
    }
  };

  const handleUpinKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && upin[index] === "") {
      const newUpin = [...upin];
      newUpin[index - 1] = "";
      setUpin(newUpin);

      const newPinValue = newUpin.join("");
      if (newPinValue === "") {
        setPageData((prev) => ({
          ...prev,
          pinHelpText: prev.pinHelpText.map((item, i) => ({
            ...item,
            matched: false,
          })),
        }));
      }

      upinRefs.current[index - 1].focus();
    }
  };

  const handleConfirmUpinKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && confirmUpin[index] === "") {
      const newConfirmUpin = [...confirmUpin];
      newConfirmUpin[index - 1] = "";
      setConfirmUpin(newConfirmUpin);
      setPageData((prev) => ({
        ...prev,
        pinHelpText: prev.pinHelpText.map((item, i) => ({
          ...item,
          matched: i === 0 ? false : item.matched,
        })),
      }));
      confirmUpinRefs.current[index - 1].focus();
    }
  };

  const handleUpinPaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 1);
    handleUpinChange(index, pasteData);
  };

  const handleConfirmUpinPaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 1);
    handleConfirmUpinChange(index, pasteData);
  };

  const handleSubmit = () => {
    const newUpinValue = upin.join("");
    const confirmUpinValue = confirmUpin.join("");

    const additionalConditionMet = true;

    API({
      ...userPin,
      body: {
        new_user_pin: newUpinValue,
        confirm_user_pin: confirmUpinValue,
      },
      onSuccess: (res) => {
        setButtonText("Successful");

        setTimeout(() => {
          changePageFunc();
        }, 2000);

        setPageData((prevPageData) => ({
          ...prevPageData,
          isSuccess: true,
        }));
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });

        setPageData((prevPageData) => ({
          ...prevPageData,
          isSuccess: false,
        }));
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "getQuestions",
      })
    );
    navigate("/");
  };

  return (
    <section className="position-relative">
      <img
        style={{ position: "absolute", top: "15%", left: "2%" }}
        src={drone}
        alt="drone"
      />
      <img
        className="position-absolute"
        style={{ right: "20px", bottom: "-20px" }}
        src={dots}
        alt="dots"
      />
      <div className="px-4">
        <LoginNav />
        <div style={{ overflowY: "auto", height: "100%" }}>
          <div className="mt-3 text-center position-relative">
            <img
              className="position-absolute"
              style={{ left: "35.5%", top: "24%" }}
              src={upinIcon}
              alt="upin-icon"
            />
            <label
              style={{
                color: "rgba(105, 102, 102, 1)",
                marginLeft: "-220px",
              }}
              className="d-block mb-1"
              htmlFor="new_user_pin"
            >
              New UPIN
            </label>
            {upin.map((value, index) => (
              <input
                key={index}
                ref={(el) => (upinRefs.current[index] = el)}
                id="new_user_pin"
                name="new_user_pin"
                type="number"
                maxLength={1}
                value={value}
                onChange={(e) => handleUpinChange(index, e.target.value)}
                onKeyDown={(e) => handleUpinKeyDown(index, e)}
                onPaste={(e) => handleUpinPaste(index, e)}
                style={{
                  width: "40px",
                  height: "35px",
                  backgroundColor: "#D9D9D9",
                  marginRight: "8px",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              />
            ))}
            <label
              style={{
                color: "rgba(105, 102, 102, 1)",
                marginLeft: "-195px",
              }}
              className="d-block mt-3 mb-1"
              htmlFor="confirm_user_pin"
            >
              Confirm UPIN
            </label>
            {confirmUpin.map((value, index) => (
              <input
                key={index}
                ref={(el) => (confirmUpinRefs.current[index] = el)}
                type="number"
                name="confirm_user_pin"
                id="confirm_user_pin"
                maxLength={1}
                value={value}
                onChange={(e) => handleConfirmUpinChange(index, e.target.value)}
                onKeyDown={(e) => handleConfirmUpinKeyDown(index, e)}
                onPaste={(e) => handleConfirmUpinPaste(index, e)}
                style={{
                  width: "40px",
                  height: "35px",
                  backgroundColor: "#D9D9D9",
                  marginRight: "8px",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              />
            ))}
          </div>
          <div className="text-center position-relative">
            <button
              className="border-0 text-white py-2 px-4 mt-3 col-3"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity: submitDisabled ? 0.5 : 1,
              }}
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              {buttonText}
            </button>
            {pageData.isSuccess && (
              <img
                style={{ maxWidth: "60px", right: "43%", top: "13px" }}
                className="position-absolute"
                src={tick}
                alt="tick"
              />
            )}
          </div>
          <div
            className="mt-3 p-1 col-6 mx-auto"
            style={{
              backgroundColor: "rgba(217, 217, 217, 1)",
              borderRadius: "10px",
            }}
          >
            <div className="d-flex align-items-center">
              <img
                src={upinDesc}
                alt="description"
                style={{ height: "30px" }}
              />
              <p
                className="mb-0 ms-2"
                style={{
                  fontSize: "15px",
                  color: "rgba(25, 66, 128, 1)",
                  width: "580px",
                }}
              >
                This UPIN will be required at the time of authentication of the
                wallet transactions.
              </p>
            </div>
          </div>
          <div
            className="py-2 px-3 mx-auto my-3 mb-0"
            style={{
              border: "1px solid #B0B8CF",
              borderRadius: "10px",
              width: "600px",
            }}
          >
            {pageData.pinHelpText.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center mx-auto mt-1"
              >
                <img
                  src={item.matched ? greenTick : liDot}
                  alt={item.matched ? "tick" : "dot"}
                  style={{ marginRight: "10px", width: "10px" }}
                />
                <span
                  style={{
                    color: "rgba(18, 33, 88, 1)",
                    fontSize: "15px",
                  }}
                >
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SetUpin;
