import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    page: "home",
    pageTitle: "Login",
    scrollToId: null,
    scrollToHighlight: null,
    backPage: "",
    isRegistered: false,
  },
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload.page;
      state.pageTitle = action.payload.pageTitle;
      state.scrollToId = action.payload.service || null;
    },
    setscrollToId: (state, action) => {
      state.scrollToId = action.payload;
    },
    setscrollToHighlight: (state, action) => {
      state.scrollToHighlight = action.payload;
    },
    setBackPage: (state, action) => {
      state.backPage = action.payload.backPage;
    },
    setRegistered(state, action) {
      state.isRegistered = action.payload.isRegistered;
    },
  },
});

export const {
  changePage,
  setscrollToId,
  setscrollToHighlight,
  setBackPage,
  setRegistered,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
