import React, { useEffect, useState } from "react";
import { discoverAvdm, CaptureAvdm } from "../utils/rd";
import InputForm from "../components/InputForm";
import API from "../components/api2";
import { aepsRegistration, aepsMerchantAuthenticity } from "../urls";
import Swal from "sweetalert2";
import axios from "axios";

const AepsRegister = () => {
  const [rd, setRD] = useState();
  const [fingerData, setFingerData] = useState();
  const [data, setData] = useState({
    aadhar: 594864836815,
    mobile_number: 9135069494,
  });

  navigator.geolocation.getCurrentPosition(function (position) {
    setData((prev) => ({
      ...prev,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
  });

  useEffect(() => {
    discoverAvdm().then((data) => {
      setRD(data);
    });
  }, []);

  const captureFingerData = () => {
    CaptureAvdm(rd).then((xml) => {
      setFingerData(xml);
    });
  };

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(data);

    API({
      ...aepsRegistration,
      body: { ...data, fingerdata: fingerData },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        fetchMerchantAuthenticity();
        // setTimeout(() => {
        //   changePageFunc();
        // }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        const ipAddress = response.data.ip;
        setData((prev) => ({
          ...prev,
          ip: ipAddress,
        }));
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  const fetchMerchantAuthenticity = () => {
    API({
      ...aepsMerchantAuthenticity,
      body: { ...data, fingerdata: fingerData },
      onSuccess: (res) => {
        console.log(res);
        Swal.fire({
          title: `${res.data.message}`,
        });
        // setTimeout(() => {
        //   changePageFunc();
        // }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <>
      <div>
        <InputForm
          type={"number"}
          id={"aadhar"}
          name="aadhar"
          placeholder={"Aadhar Number"}
          maxInputLength="12"
          valueState={data}
          onChange={handleChange}
        />

        <InputForm
          type={"number"}
          id={"mobile_number"}
          name="mobile_number"
          placeholder={"Mobile Number"}
          maxInputLength="10"
          valueState={data}
          onChange={handleChange}
        />

        {rd ? (
          <>
            <h1>DEVICE DETECTED</h1>
            <button onClick={captureFingerData}>
              {fingerData ? "Captured" : "Capture"}
            </button>
          </>
        ) : (
          <>NO DEVICE DETECTED</>
        )}

        {fingerData && (
          <button className="d-block m-2" onClick={submitForm}>
            Continue
          </button>
        )}
      </div>
    </>
  );
};

export default AepsRegister;
