import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "./api2";
import { getQuestions, submitAns } from "../urls";
import Swal from "sweetalert2";
import Button from "../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import LoginNav from "./LoginNav";
import { dots } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";

const FirstLoginQuestions = () => {
  const [pageData, setPageData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [showSelectedQuestions, setShowSelectedQuestions] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchQuestions = () => {
    API({
      ...getQuestions,
      onSuccess: (res) => {
        setQuestions(res.data.data);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const indexedAnswers = selectedQuestions.reduce((acc, question, index) => {
      acc[`secret_answer${index + 1}`] = answers[question.value] || "";
      return acc;
    }, {});
    const indexedQuestions = selectedQuestions.reduce(
      (acc, question, index) => {
        acc[`secret_question${index + 1}`] = question.value;
        return acc;
      },
      {}
    );

    const postData = {
      ...indexedQuestions,
      ...indexedAnswers,
    };

    // POST request
    API({
      ...submitAns,
      body: postData,
      onSuccess: (res) => {
        Swal.fire("Success!", "Answers submitted successfully!", "success");
        // navigate("/login");
        changePageFunc();
      },
      onError: (error) => {
        Swal.fire("Error!", "Failed to submit answers.", "error");
      },
    });
  };

  const handleQuestionSelect = (selectedOptions) => {
    setSelectedQuestions(selectedOptions);
    if (selectedOptions.length === 3) {
      setDisableSelect(true);
      setShowResetButton(true);
    } else {
      setDisableSelect(false);
      setShowResetButton(false);
    }
  };

  const handleReset = () => {
    setSelectedQuestions([]);
    setDisableSelect(false);
    setShowResetButton(false);
    setResetKey(resetKey + 1);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setShowSelectedQuestions(true);
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "verifyMail",
      })
    );
    navigate("/", {
      state: {},
    });
  };

  return (
    <section className="position-relative">
      {/* <img
        className="position-absolute"
        style={{ right: "40px", bottom: "-120%" }}
        src={dots}
        alt="dots"
      /> */}
      <div className="px-4">
        <LoginNav />
        <h2
          className="fw-semibold text-center mt-4"
          style={{ color: "rgba(79, 87, 91, 1)", fontSize: "20px" }}
        >
          {showSelectedQuestions
            ? "Provide Answers for Selected Questions"
            : "Select any 3 Questions and have some Answer"}
        </h2>
        <div className="col-6 mx-auto mt-4">
          {!showSelectedQuestions && (
            <form onSubmit={handleNext}>
              <Select
                key={resetKey}
                options={questions.map((question) => ({
                  value: question.questions,
                  label: question.questions,
                }))}
                isMulti
                placeholder="Select any 3 questions"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "lightgray",
                    outline: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#D9D9D9",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "black" : "#D9D9D9",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }),
                }}
                onChange={handleQuestionSelect}
                isDisabled={disableSelect}
              />
              {showResetButton && (
                <div className="text-end mt-3">
                  <button
                    type="button"
                    className="border-0 text-white py-2 px-4 mt-4 col-2"
                    style={{
                      backgroundColor: "#FF0000",
                      borderRadius: "10px",
                    }}
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
              )}
              {selectedQuestions.length === 3 && (
                <div className="text-end mt-3">
                  <button
                    type="submit"
                    className="border-0 text-white py-2 px-4 mt-4 col-2"
                    style={{
                      backgroundColor: "#194280",
                      borderRadius: "10px",
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
            </form>
          )}
          {showSelectedQuestions && (
            <div className="pt-3">
              {selectedQuestions.map((question, index) => (
                <div
                  style={{
                    borderBottom: "1px solid black",
                    color: "#194280",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  key={question.value}
                >
                  <p className="mb-0 mt-3">{question.label}</p>
                  <input
                    type="text"
                    autoComplete="off"
                    className="p-2"
                    name={`answer${index + 1}`}
                    id={`answer${index + 1}`}
                    style={{ width: "100%" }}
                    value={answers[question.value] || ""}
                    onChange={(e) => {
                      handleAnswerChange(question.value, e.target.value);
                    }}
                  />
                </div>
              ))}
              <div className="text-center mt-2">
                <button
                  type="submit"
                  className="border-0 text-white py-2 px-4 mt-4 col-3"
                  style={{
                    backgroundColor: "#194280",
                    borderRadius: "10px",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FirstLoginQuestions;
