import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addApiPortal, getServiceDropdown, serviceDropdown } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const NewApiPortal = () => {
  const [pageData, setPageData] = useState({});
  const [providerOptions, setProviderOptions] = useState([]);
  const [standardServiceOptions, setStandardServiceOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [serviceTypeOptions] = useState([
    { value: "API", label: "API" },
    { value: "PORTAL", label: "PORTAL" },
  ]);

  useEffect(() => {
    const fetchServiceProviders = () => {
      API({
        ...getServiceDropdown,
        onSuccess: (res) => {
          const providers = res.data.data.service_providers.map((provider) => ({
            value: provider.provider_id,
            label: `${provider.provider_id}-${provider.service_provider_name}`,
          }));
          setProviderOptions(providers);
        },

        onError: (error) => {
          console.log("error");
        },
      });
    };

    fetchServiceProviders();
  }, []);
  useEffect(() => {
    const fetchStandardService = () => {
      API({
        ...serviceDropdown,
        onSuccess: (res) => {
          const standard = res.data.data.services.map((s) => ({
            value: s.service_id,
            label: `${s.service_id}-${s.service_name}`,
          }));
          setStandardServiceOptions(standard);
        },

        onError: (error) => {
          console.log("error");
        },
      });
    };

    fetchStandardService();
  }, []);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  //  validation
  const validationRules = {
    utility_service_name: "service name",
    service_type: "service type",
    provider_id: "provider id",
    standard_service_id: "standard service id",
  };
  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      API({
        ...addApiPortal,
        body: pageData,
        onSuccess: (res) => {
          Swal.fire({
            title: `${res.data.message}`,
          });
          setTimeout(() => {
            changePageFunc();
          }, 2000);
        },

        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "getApiPortal",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add API & Portal Based Utility Service:
        </h2>
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Utility Service Info
        </div>
        <div className="px-5 container-fluid">
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Utility Service Name"}
                id={"utility_service_name"}
                name="utility_service_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="service_type"
                className="form-label"
              >
                Service Type
              </label>
              <Select
                options={serviceTypeOptions}
                placeholder="Select Service Type"
                id={"service_type"}
                name={"service_type"}
                valueState={pageData["service_type"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    service_type: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["service_type"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["service_type"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["service_type"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["service_type"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="provider_id"
                className="form-label"
              >
                Service Provider Id
              </label>
              <Select
                options={providerOptions}
                placeholder="Select Service Provider ID"
                id={"provider_id"}
                name={"provider_id"}
                valueState={pageData["provider_id"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    provider_id: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["provider_id"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["provider_id"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["provider_id"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["provider_id"]}
                </div>
              )}
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="standard_service_id"
                className="form-label"
              >
                Standard Service Id
              </label>
              <Select
                options={standardServiceOptions}
                placeholder="Select Standard Service ID"
                id={"standard_service_id"}
                name={"standard_service_id"}
                valueState={pageData["standard_service_id"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    standard_service_id: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["standard_service_id"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["standard_service_id"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["standard_service_id"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["standard_service_id"]}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-5">
            <Button onClick={changePageFunc} children={"Back"} />
            <Button onClick={onSubmit} children={"Submit"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewApiPortal;
