import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editProfileRole } from "../../urls";
import API from "../../components/api2";
import Button from "../../components/Button";
import { circles } from "../../images";
import Header from "../../components/Header";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import loaderGif from "../../gif/buffer.gif";

const UpdateProfileRole = () => {
  const location = useLocation();
  const { service } = location.state || {};

  const [formData, setFormData] = useState({
    profile_id: service?.profile || "",
    profile_code: service?.profile_code || "",
    profile_name: service?.profile_name || "",
    profile_role: service?.profile_role || "",
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(changePage({ page: "manageProfileRole" }));
    navigate("/");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    setLoading(true);
    API({
      ...editProfileRole,
      body: formData,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire("Profile Role updated successfully!").then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire("Error", "Failed to update profile role.", "error");
        console.error("Error updating profile role: ", error);
      },
    });
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid d-flex flex-column">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Profile Role Information
          </h2>
        </div>

        <div className="px-5 flex-grow-1">
          <div className="row mt-4">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Profile ID
              </p>
              <input
                type="text"
                name="profile_id"
                className="p-2 w-100 mt-1"
                value={formData.profile_id}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>

            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Profile Code
              </p>
              <input
                type="text"
                name="profile_code"
                className="p-2 w-100 mt-1"
                value={formData.profile_code}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Profile Name
              </p>
              <input
                type="text"
                name="profile_name"
                className="p-2 w-100 mt-1"
                value={formData.profile_name}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Profile Role
              </p>
              <input
                type="text"
                name="profile_role"
                className="p-2 w-100 mt-1"
                value={formData.profile_role}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-auto px-5 pb-4">
          <Button onClick={changePageFunc}>Back</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <img
                style={{ height: "30px", width: "30px" }}
                src={loaderGif}
                alt="Loading..."
              />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateProfileRole;
