import React, { useState, useEffect } from "react";

export default function LangSelector(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLangText, setSelectedLangText] = useState(
    localStorage.getItem("selectedLang") || "ENG"
  );

  useEffect(() => {
    localStorage.setItem("selectedLang", selectedLangText);
  }, [selectedLangText]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLang = (lang, langName) => {
    props.setLang(lang);
    setSelectedLangText(langName);
    setIsOpen(false);
  };

  return (
    <div
      className="ms-3 mt-2"
      style={styles.container}
      onClick={toggleDropdown}
    >
      <div style={styles.selectedLang}>{selectedLangText}</div>
      <div style={styles.dropdownHeader}>
        <span style={styles.dropdownIcon}></span>
      </div>
      {isOpen && (
        <div style={styles.dropdown}>
          <div style={styles.option} onClick={() => selectLang("en", "ENG")}>
            English
          </div>
          <div style={styles.option} onClick={() => selectLang("hi", "हिंदी")}>
            हिंदी
          </div>
          <div
            style={styles.option}
            onClick={() => selectLang("guj", "ગુજરાતી")}
          >
            ગુજરાતી
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "inline-block",
    position: "relative",
  },
  selectedLang: {
    marginBottom: "4px",
    textAlign: "center",
    color: "white",
    cursor: "pointer",
  },
  dropdownHeader: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropdownIcon: {
    width: "0",
    height: "0",
    borderTop: "6px solid #fff",
    borderRight: "5px solid transparent",
    borderLeft: "5px solid transparent",
  },
  dropdown: {
    position: "absolute",
    left: "-70%",
    zIndex: "1",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#fff",
    top: "40px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  option: {
    padding: "8px",
    cursor: "pointer",
    width: "80px",
  },
};
