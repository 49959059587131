import React, { useEffect, useState } from "react";
import { allBusinessScheme, getBusinessSchemeDropdown } from "../urls";
import API from "../components/api2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import Filter from "../components/Filter";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";

const AllBusinessScheme = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 100 });
  const [dropdown, setDropdown] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();

  useEffect(() => {
    fetchServices({ initial: true });
    fetchServicesDropdown();
  }, []);

  async function fetchServiceExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchServices({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }
  const fetchServicesDropdown = () => {
    API({
      ...getBusinessSchemeDropdown,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching standard service dropdown: ", error);
      },
    });
  };

  const fetchServices = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...allBusinessScheme,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        if (initial) {
          setActive(res.data.data.active_count);
          setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
            }
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching standard service: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "businessSchemeInfo",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchServices({ append: true });
    }
  }, [pagination]);
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Business Schemes
          </h2>
          <div className="d-flex me-5 pe-3">
            <div
              className="d-flex align-items-center"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(20, 140, 67, 1)",
                }}
                className="mb-0"
              >
                Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {active}
              </span>
            </div>
            <div
              className="d-flex align-items-center ms-3"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(249, 124, 75, 1)",
                }}
                className="mb-0"
              >
                In Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {inActive}
              </span>
            </div>
          </div>
        </div>
        <Filter2
          getDataFunc={fetchServices}
          excelFunc={fetchServiceExcel}
          excelFileName={"Standard Service Data"}
          filters={[
            {
              placeholder: "Scheme Name",
              id: "scheme_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.scheme_names?.map((item) => ({
                value: item.scheme_name,
                label: item.scheme_name,
              })),
            },
            {
              placeholder: "Active Status",
              id: "active_status",
              filterType: "select",
              inputType: null,
              options: dropdown?.active_statuses?.map((item) => ({
                value: item.active_status,
                label: item.active_status,
              })),
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "newBusinessPlan" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD BUSINESS SCHEME"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            {
              Header: "Business Scheme ID",
              accessor: "scheme_id",
            },
            {
              Header: "Business Scheme Name",
              accessor: "scheme_name",
            },
            {
              Header: "Created By",
              accessor: "created_by",
            },
            {
              Header: "Scheme Description",
              accessor: "scheme_description",
            },
            {
              Header: "Business Scheme Status",
              accessor: "active_status",
              Cell: ({ value }) => (
                <span>{value === "A" ? "Active" : "Deactive"}</span>
              ),
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default AllBusinessScheme;
