import React from "react";
import { credLogo, newLogo } from "../images";
import drone from "../gif/drone.gif";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LoginNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "login",
      })
    );
    navigate("/");
  };

  return (
    <div className="d-flex align-items-center justify-content-between position-relative">
      <img
        onClick={changePageFunc}
        style={{
          maxWidth: "220px",
          cursor: "pointer",
          pointerEvents: "all",
          position: "absolute",
          top: "20px",
          left: "10px",
        }}
        src={newLogo}
        alt="logo"
      />
      <div className="text-center mx-auto">
        <img src={credLogo} alt="credentials-logo" />
        <p
          style={{
            fontWeight: "600",
            fontSize: "25px",
            color: "rgba(25, 66, 128, 1)",
          }}
          className="mb-0"
        >
          Set your Credentials
        </p>
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="position-relative top"
      >
        <img
          style={{ height: "300px", top: "-155px" }}
          className="position-absolute end-0"
          src={drone}
          alt="drone"
        />
      </div>
    </div>
  );
};

export default LoginNav;
