import React from "react";
import { connect } from "react-redux";
import BarChart from "../components/BarChart";

const ChartPage = () => {
  const [hideChart, setHideChart] = React.useState(true);

  const data = [
    {
      Services: "AEPS",
      Credit: 1800,
      CreditColor: "#98D8AA",
      Debit: 270,
      DebitColor: "#FF6D60",
    },
    {
      Services: "E-Commerce",
      Credit: 603,
      CreditColor: "#98D8AA",
      Debit: 307,
      DebitColor: "#FF6D60",
    },
    {
      Services: "Travel",
      Credit: 803,
      CreditColor: "#98D8AA",
      Debit: 700,
      DebitColor: "#FF6D60",
    },
    {
      Services: "DMT",
      Credit: 630,
      CreditColor: "#98D8AA",
      Debit: 1200,
      DebitColor: "#FF6D60",
    },
  ];
  return (
    <>
      <div className="d-flex">
        <div
          className="p-1 border border-black"
          style={{ backgroundColor: hideChart ? "red" : "", cursor: "pointer" }}
          onClick={() => setHideChart(true)}
        >
          Table View
        </div>

        <div
          className="p-1 border border-black"
          style={{ backgroundColor: hideChart ? "" : "red", cursor: "pointer" }}
          onClick={() => setHideChart(false)}
        >
          Chart View
        </div>
      </div>
      <div className="w-100" style={{ height: "300px" }}>
        {hideChart ? (
          <>
            <table border="1">
              <caption className="text-center">Services C/D</caption>
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Credit</th>
                  <th>Debit</th>
                </tr>
              </thead>
              <tbody>
                {data.map((elem, key) => {
                  return (
                    <tr>
                      <td>{elem["Services"]}</td>
                      <td style={{ backgroundColor: elem["CreditColor"] }}>
                        {elem["Credit"]}
                      </td>
                      <td style={{ backgroundColor: elem["DebitColor"] }}>
                        {elem["Debit"]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <BarChart data={data}></BarChart>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  duid: state.user.duid,
  t: state.user.t,
});

export default connect(mapStateToProps)(ChartPage);
