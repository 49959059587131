import React, { useEffect, useState } from "react";
import { discoverAvdm, CaptureAvdm } from "../utils/rd";
import InputForm from "../components/InputForm";
import API from "../components/api2";
import { aepsRegistration, aepsMerchantAuthenticity } from "../urls";
import Swal from "sweetalert2";
import axios from "axios";

const AepsOnboardingPage = () => {
  const [rd, setRD] = useState();
  const [fingerData, setFingerData] = useState();
  const [data, setData] = useState({
    aadhar: 594864836815,
    mobile_number: 9135069494,
  });

  navigator.geolocation.getCurrentPosition(function (position) {
    setData((prev) => ({
      ...prev,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
  });

  useEffect(() => {
    discoverAvdm().then((data) => {
      setRD(data);
    });
  }, []);

  const captureFingerData = () => {
    CaptureAvdm(rd).then((xml) => {
      setFingerData(xml);
    });
  };

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(data);

    API({
      ...aepsRegistration,
      body: { ...data, fingerdata: fingerData },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        fetchMerchantAuthenticity();
        // setTimeout(() => {
        //   changePageFunc();
        // }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        const ipAddress = response.data.ip;
        setData((prev) => ({
          ...prev,
          ip: ipAddress,
        }));
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  const fetchMerchantAuthenticity = () => {
    API({
      ...aepsMerchantAuthenticity,
      body: { ...data, fingerdata: fingerData },
      onSuccess: (res) => {
        console.log(res);
        Swal.fire({
          title: `${res.data.message}`,
        });
        // setTimeout(() => {
        //   changePageFunc();
        // }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <>
      <div className="" style={{ height: "100vh" }}>
        <iframe
          src="https://paysprint.co.in/onboarding?env=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyaWQiOiIyMDE4MDEwMCIsIm1lcmNoYW50Y29kZSI6IjEwMDczOCIsIm1vYmlsZSI6IjkxMzUwNjk0OTQiLCJpc19uZXciOiIxIiwiZW1haWwiOiJwYXBwdS5ndXB0YUBwcmF5YXNmaW5hbmNlLmNvbSIsImZpcm1uYW1lIjoiUHJheWFzIEZpbmFuY2UiLCJyZXFpZCI6IjE3MTg2MTc5NDU3MDkiLCJjYWxsYmFjayI6Imh0dHBzOlwvXC9hZXNrY2xvdWQucHJheWFzZmluYW5jZS5pblwvYXBpXC9hXC90cmFuc2FjdGlvbi1jYWxsYmFjay11cmxcLyIsInBpcGUiOjEsImN1cnJlbnRfdGltZSI6MTcxODYxNzk0NX0.Ga3s8wWVDPynl0m5T2bgOs51PrCpNz-xTwActHwEVCI"
          width="100%"
          height="100%"
          style={{ border: "2px solid #ccc" }}
          title="Embedded Example Website"
        ></iframe>
      </div>
    </>
  );
};

export default AepsOnboardingPage;
