import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    name: "",
    registered_no: "",
    registered_id: "",
    t: "",
    rt: "",
    duid: "",
    menus: [],
    roles: [],
    aepsReg: false,
    aepsMobile: "",
    aepsAadhar: "",
  },
  reducers: {
    setDUID: (state, action) => {
      state.duid = action.payload.duid;
    },
    onLogin: (state, action) => {
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.registered_no = action.payload.registered_no;
      state.registered_id = action.payload.registered_id;
      state.t = action.payload.t;
      state.rt = action.payload.rt;
    },
    onLogout: (state) => {
      state.username = "";
      state.name = "";
      state.registered_no = "";
      state.registered_id = "";
      state.t = "";
      state.rt = "";
      state.duid = "";
      state.menus = [];
      state.roles = [];
      state.aepsReg = false;
      state.aepsMobile = "";
      state.aepsAadhar = "";
    },
    updateT: (state, action) => {
      state.t = action.payload.t;
    },
    onVerifyLogin: (state, action) => {
      state.t = action.payload.t;
      state.rt = action.payload.rt;
    },
    menuList: (state, action) => {
      state.menus = action.payload.menus;
      state.roles = action.payload.roles;
    },

    isRegEnabled: (state, action) => {
      state.aepsReg = action.payload.aepsReg;
    },
    aepsUserData: (state, action) => {
      state.aepsMobile = action.payload.aepsMobile;
      state.aepsAadhar = action.payload.aepsAadhar;
    },
  },
});

export const {
  setDUID,
  onLogin,
  onLogout,
  updateT,
  onVerifyLogin,
  menuList,
  isRegEnabled,
  aepsUserData,
} = userSlice.actions;

// export const getUser = (state) => state.counter.value

export default userSlice.reducer;
