import React, { useEffect, useState } from "react";
import { eye, eyeClosed, passIcon, userIcon, passIcon2 } from "../images";

const Input = ({
  type,
  id,
  name,
  placeholder,
  onChange,
  value,
  valueState,
  checkedValue = true,
  required = false,
  autocomplete = "off",
  errorText = "",
  errorState = {},
  style = {
    borderBottom: "1.5px solid rgba(105, 102, 102, 1)",
    paddingLeft: "30px",
  },
  showLabel = true,
  showIcon = false,
  showPassIcon = false,
  showPassIcon2 = false,
  isEmpty = false,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const inputStyle = {
    ...style,
    borderBottom: isEmpty ? "1.5px solid red" : style.borderBottom,
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("wheel", handleWheel);
    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className="mt-3">
      <div
        className="d-flex align-items-center position-relative"
        style={inputStyle}
      >
        {showLabel && (
          <label
            htmlFor={id}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#194280",
              width: "135px",
            }}
          >
            {placeholder}
          </label>
        )}
        {showIcon && (
          <img
            src={userIcon}
            alt="user-icon"
            style={{
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              left: "0%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
        <input
          type={type === "password" && isPasswordVisible ? "text" : type}
          id={id}
          name={name}
          placeholder={placeholder}
          value={
            value ? value : valueState && valueState[id] ? valueState[id] : ""
          }
          checked={
            value === checkedValue
              ? true
              : valueState && valueState[id] && valueState[id] === checkedValue
              ? true
              : false
          }
          onChange={onChange}
          className="flex-grow-1 bg-transparent p-2 login_text"
          required={required}
          autoComplete={autocomplete}
        />

        {type === "password" && (
          <>
            <img
              src={isPasswordVisible ? eye : eyeClosed}
              alt="eye"
              style={{
                cursor: "pointer",
                height: "20px",
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "all",
              }}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            {showPassIcon && (
              <img
                src={passIcon}
                alt="password-icon"
                style={{
                  height: "20px",
                  position: "absolute",
                  left: "0%",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
            {showPassIcon2 && (
              <img
                src={passIcon2}
                alt="password-icon"
                style={{
                  height: "20px",
                  position: "absolute",
                  left: "0%",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
