import React, { useEffect, useState, startTransition, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import Page from "../layout/Page";
import { Provider } from "react-redux";
import { store, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Protected from "./protected";
import ChartPage from "../pages/ChartPage";
import QRPage from "../pages/QRPage";
import i18next from "i18next";
import AepsRegister from "../pages/AepsRegister";
import AepsOnboardingPage from "../pages/AepsOnboardingPage";

function AppRoutes(props) {
  const [username, setUsername] = useState();

  // language translation
  useEffect(() => {
    const lang = localStorage.getItem("language");
    startTransition(() => {
      i18next.changeLanguage(lang);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/" element={<Protected />}>
                <Route path="/" element={<Page />}></Route>
              </Route>
              <Route
                path="login/"
                element={<LoginPage setUsername={setUsername} />}
              />
              <Route
                path="chart/"
                element={<ChartPage setUsername={setUsername} />}
              />
              <Route
                path="qr/"
                element={<QRPage setUsername={setUsername} />}
              />
              <Route
                path="fs/"
                element={<AepsRegister setUsername={setUsername} />}
              />
              <Route
                path="tr/"
                element={<AepsOnboardingPage setUsername={setUsername} />}
              />
              <Route path="*" element={<h1>/404</h1>} />
            </Routes>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default AppRoutes;
