import React, { useState } from "react";
import Select from "react-select";
import { apply, filter } from "../images";
import { ExportToExcel } from "./ExportToExcel";
import { GrPowerReset } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

// Date input component
const DateInputComponent = ({ onChange, id, value, singleDate }) => {
  const [startDate, setStartDate] = useState(value ? new Date(value[0]) : null);
  const [endDate, setEndDate] = useState(value ? new Date(value[1]) : null);

  const handleDateChange = (dates) => {
    if (singleDate) {
      const selectedDate = dates;
      setStartDate(selectedDate);
      const formattedDate = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : "";
      onChange(id, formattedDate);
    } else {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        const formattedStart = format(start, "yyyy-MM-dd");
        const formattedEnd = format(end, "yyyy-MM-dd");
        onChange(id, [formattedStart, formattedEnd]);
      } else if (start) {
        const formattedStart = format(start, "yyyy-MM-dd");
        onChange(id, [formattedStart, formattedStart]);
      }
    }
  };

  return (
    <DatePicker
      showIcon
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange={!singleDate}
      selectsStart={!singleDate}
      selectsEnd={!singleDate}
      isClearable
      minDate={id === "end_date" && startDate ? startDate : null}
      placeholderText={
        singleDate
          ? id === "start_date"
            ? "Select Start Date"
            : "Select End Date"
          : "Select date range"
      }
      dateFormat="yyyy-MM-dd"
      className="form-control"
      style={{
        fontSize: "15px",
        width: "100%",
        padding: "8px 51px",
        borderRadius: "10px",
      }}
    />
  );
};

const InputComponent = ({ onChange, id, placeholder, type, value }) => (
  <input
    type={type}
    id={id}
    placeholder={placeholder}
    style={{
      fontSize: "15px",
      width: "100%",
      padding: "8px 5px",
      borderRadius: "10px",
      backgroundColor: "rgba(255, 231, 159, 0.4)",
    }}
    onChange={(e) => onChange(id, e.target.value)}
    value={value || ""}
  />
);

const SelectComponent = ({ onChange, id, placeholder, options, value }) => (
  <Select
    options={options}
    placeholder={placeholder}
    value={value ? { value: value, label: value } : null}
    onChange={(selectedOption) => onChange(id, selectedOption.value)}
    styles={{
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "rgba(255, 231, 159, 0.4)",
        borderRadius: "10px",
        width: "100%",
        border: "none",
        boxShadow: state.isFocused ? "none" : provided.boxShadow,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000",
      }),
    }}
  />
);

const Filter2 = ({
  filters = [],
  getDataFunc,
  excelFunc,
  excelFileName,
  showExcelIcon = true,
  children = <></>,
}) => {
  const [filterValues, setFilterValues] = useState({});

  const handleInputChange = (id, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const resetFilters = () => {
    setFilterValues({});
    getDataFunc({ filterValues: {} });
  };

  return (
    <>
      <div
        className="mx-3 mt-4"
        style={{
          borderTop: "1.5px solid #D9D9D9",
          borderBottom: "2px solid #063E70",
        }}
      >
        <div
          className="px-3 mt-1 py-2 mb-4 d-flex align-items-center"
          style={{ backgroundColor: "#D9D9D9", borderTop: "1px solid #D9D9D9" }}
        >
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              color: "rgba(25, 66, 128, 1)",
            }}
            className="mb-0"
          >
            Filters
          </h3>
          <img className="ms-3" src={filter} alt="filter-icon" />
        </div>

        <div className="row pb-3 px-3">
          {filters.map((filter, key) => {
            return (
              <div className="col p-0 pe-2" key={key}>
                {filter.filterType === "select" ? (
                  <SelectComponent
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                    placeholder={filter.placeholder}
                    options={filter.options}
                    id={filter.id}
                    onChange={handleInputChange}
                  />
                ) : filter.filterType === "input" ? (
                  <InputComponent
                    id={filter.id}
                    placeholder={filter.placeholder}
                    type={filter.inputType}
                    onChange={handleInputChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                  />
                ) : filter.filterType === "date" ? (
                  <DateInputComponent
                    id={filter.id}
                    onChange={handleInputChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                    singleDate={filter.singleDate || false}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          <div className="col-1 p-0">
            <img
              onClick={() => {
                getDataFunc({ filterValues: filterValues });
              }}
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={apply}
              alt="apply"
            />
          </div>
          <div className="col-1 p-0">
            <GrPowerReset
              style={{
                marginTop: "9px",
                marginLeft: "8px",
                fontSize: "18px",
                cursor: "pointer",
                color: "#194280",
              }}
              onClick={resetFilters}
            />
          </div>
        </div>
      </div>
      <div
        className={`d-flex align-items-center ${
          showExcelIcon ? "justify-content-between" : "justify-content-end"
        } mt-2 px-3`}
      >
        {showExcelIcon && (
          <ExportToExcel
            fetchOnClickFunc={() => excelFunc({ filterValues })}
            fileName={excelFileName}
          />
        )}
        {children}
      </div>
    </>
  );
};

export default Filter2;
