import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editDesignation } from "../../urls";
import API from "../../components/api2";
import Button from "../../components/Button";
import { circles } from "../../images";
import Header from "../../components/Header";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import loaderGif from "../../gif/buffer.gif";

const UpdateDesignation = () => {
  const location = useLocation();
  const { service } = location.state || {};
  const [formData, setFormData] = useState({
    designation_id: service?.designation_id || "",
    designation_name: service?.designation_name || "",
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(changePage({ page: "manageDesignation" }));
    navigate("/");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    setLoading(true);
    API({
      ...editDesignation,
      body: formData,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire("Designation updated successfully!").then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire("Error", "Failed to update designation.", "error");
        console.error("Error updating designation: ", error);
      },
    });
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Designation Information
          </h2>
        </div>

        <div className="col-7 mt-4 px-5">
          <p
            className="mb-0"
            style={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "rgba(173, 131, 82, 1)",
            }}
          >
            Designation ID
          </p>
          <input
            type="text"
            name="designation_id"
            className="p-2 w-100 mt-1"
            value={formData.designation_id}
            onChange={handleInputChange}
            style={{
              backgroundColor: "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
            }}
          />

          <p
            className="mb-0 mt-4"
            style={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "rgba(173, 131, 82, 1)",
            }}
          >
            Designation Name
          </p>
          <input
            type="text"
            name="designation_name"
            className="p-2 w-100 mt-1"
            value={formData.designation_name}
            onChange={handleInputChange}
            style={{
              backgroundColor: "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
            }}
          />

          <div className="d-flex justify-content-between align-itmes-center mt-5">
            <Button onClick={changePageFunc}>Back</Button>
            <Button onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <img
                  style={{ height: "30px", width: "30px" }}
                  src={loaderGif}
                  alt="Loading..."
                />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDesignation;
