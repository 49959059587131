import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import { firstLogin } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { credLogo, dots, greenTick, liDot } from "../images";
import tick from "../gif/tick.gif";
import LoginNav from "../components/LoginNav";

const FirstLogin = () => {
  const [pageData, setPageData] = useState({
    new_password: "",
    confirm_password: "",
    isSuccess: false,
    passwordHelpText: [
      {
        text: "Your password can’t be too similar to your other personal information.",
        matched: false,
      },
      {
        text: "Your password must contain at least 8 characters.",
        matched: false,
      },
      {
        text: "Your password can’t be a commonly used password.",
        matched: false,
      },
      { text: "Your password can’t be entirely numeric.", matched: false },
      {
        text: "Your password must contain at least 1 uppercase letter, A-Z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 lowercase letter, a-z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 symbol: ()[]{}|\\`~!@#$%^&*_-+=;:'\",<>./?",
        matched: false,
      },
      {
        text: "The new password cannot be the same as the old password.",
        matched: false,
      },
    ],
  });
  const [passwordError, setPasswordError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const password = location.state ? location.state.password : null;

  useEffect(() => {
    validatePassword();
  }, [pageData.new_password]);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
    setPasswordError(null);

    if (id === "new_password" && value === "") {
      const updatedPasswordHelpText = pageData.passwordHelpText.map((item) => ({
        ...item,
        matched: false,
      }));
      setPageData((prev) => ({
        ...prev,
        passwordHelpText: updatedPasswordHelpText,
      }));
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "setUpin",
      })
    );
    navigate("/");
  };

  const validatePassword = () => {
    const { new_password } = pageData || {};
    if (!new_password) return "New password is required";

    let passwordHelpText = [
      {
        text: "Your password can’t be too similar to your other personal information.",
        matched: false,
      },
      {
        text: "Your password must contain at least 8 characters.",
        matched: false,
      },
      {
        text: "Your password can’t be a commonly used password.",
        matched: false,
      },
      { text: "Your password can’t be entirely numeric.", matched: false },
      {
        text: "Your password must contain at least 1 uppercase letter, A-Z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 lowercase letter, a-z.",
        matched: false,
      },
      {
        text: "Your password must contain at least 1 symbol: ()[]{}|\\`~!@#$%^&*_-+=;:'\",<>./?",
        matched: false,
      },
      {
        text: "The new password cannot be the same as the old password.",
        matched: false,
      },
    ];

    if (new_password.length >= 8) passwordHelpText[1].matched = true;
    if (!/^[0-9]+$/.test(new_password)) passwordHelpText[3].matched = true;
    if (/[a-z]/.test(new_password)) passwordHelpText[5].matched = true;
    if (/[A-Z]/.test(new_password)) passwordHelpText[4].matched = true;
    const specialCharactersRegex = /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/;
    if (specialCharactersRegex.test(new_password))
      passwordHelpText[6].matched = true;

    if (!new_password.includes("personal information")) {
      passwordHelpText[0].matched = true;
    }

    const commonPasswords = ["password", "123456", "qwerty"];
    if (!commonPasswords.includes(new_password.toLowerCase())) {
      passwordHelpText[2].matched = true;
    }

    if (new_password !== password) {
      passwordHelpText[7].matched = true;
    }

    setPageData((prev) => ({ ...prev, passwordHelpText }));

    let allCriteriaMatched = true;

    for (let i = 0; i < passwordHelpText.length; i++) {
      if (!passwordHelpText[i].matched) {
        allCriteriaMatched = false;
        break;
      }
    }

    return allCriteriaMatched ? null : "Password does not match the criteria";
  };

  function onSubmit(e) {
    e.preventDefault();

    const { new_password, confirm_password } = pageData;

    const passwordError = validatePassword();
    if (passwordError) {
      setPasswordError(passwordError);
      return;
    }

    if (new_password !== confirm_password) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match!",
        text: "Please make sure the passwords match.",
      });
      return;
    }

    API({
      ...firstLogin,
      body: pageData,
      onSuccess: (res) => {
        setPageData((prev) => ({
          ...prev,
          isSuccess: true,
        }));
        setTimeout(() => {
          changePageFunc();
        }, 2000);
      },

      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: "Password does not match the criteria",
        });
      },
    });
  }

  return (
    <section>
      <div className="px-4 position-relative">
        <img
          className="position-absolute"
          style={{ right: "30px", bottom: "10px" }}
          src={dots}
          alt="dots"
        />
        <LoginNav />
        <form onSubmit={onSubmit} className="col-5 mx-auto mt-4">
          <Input
            type={"password"}
            placeholder={"New Password"}
            id={"new_password"}
            name="new_password"
            valueState={pageData}
            onChange={pageDataFunc}
            showLabel={false}
            showPassIcon={true}
          />
          {passwordError && (
            <span
              style={{
                color: "red",
              }}
            >
              {passwordError}
            </span>
          )}
          <Input
            type={"password"}
            placeholder={"Confirm Password"}
            id={"confirm_password"}
            name="confirm_password"
            valueState={pageData}
            onChange={pageDataFunc}
            showLabel={false}
            showPassIcon2={true}
          />
          <div className="text-center position-relative mb-4">
            <button
              className="border-0 text-white py-2 px-4 mt-4 col-6"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity:
                  !pageData.new_password ||
                  !pageData.confirm_password ||
                  pageData.new_password !== pageData.confirm_password ||
                  passwordError
                    ? "0.5"
                    : "1",
                pointerEvents:
                  !pageData.new_password ||
                  !pageData.confirm_password ||
                  pageData.new_password !== pageData.confirm_password ||
                  passwordError
                    ? "no-drop"
                    : "auto",
              }}
              disabled={
                !pageData.new_password ||
                !pageData.confirm_password ||
                pageData.new_password !== pageData.confirm_password ||
                passwordError
              }
            >
              {pageData.isSuccess ? "Update Successfull" : "Update Password"}
            </button>
            {pageData.isSuccess && (
              <img
                style={{ maxWidth: "60px", right: "140px", top: "21px" }}
                className="position-absolute"
                src={tick}
                alt="tick"
              />
            )}
          </div>
          <div
            className="py-2 px-3"
            style={{
              border: "1px solid #B0B8CF",
              borderRadius: "10px",
              width: "600px",
              marginLeft: "-50px",
            }}
          >
            {pageData.passwordHelpText.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center mx-auto mt-1"
              >
                <img
                  src={item.matched ? greenTick : liDot}
                  alt="tick"
                  style={{ position: "absolute" }}
                />
                <span
                  style={{
                    color: "rgba(18, 33, 88, 1)",
                    fontSize: "15px",
                    paddingLeft: "30px",
                  }}
                >
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </form>
      </div>
    </section>
  );
};

export default FirstLogin;
