import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateConsumer,
  getServiceProvider,
  serviceList,
  activeConsumer,
  getConsumer,
  addConsumerComments,
  getConsumerComments,
} from "../urls";
import API from "../components/api2";
import Button from "../components/Button";
import { active, circles, inactive } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Alert from "../components/Alert";
import Swal from "sweetalert2";

const ConsumerInfo = () => {
  const location = useLocation();
  const { service } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [providerOptions, setProviderOptions] = useState([]);
  const [standardServiceOptions, setStandardServiceOptions] = useState([]);
  const [serviceData, setServiceData] = useState({});
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [comments, setComments] = useState([]);
  const [editableFields, setEditableFields] = useState({
    consumer_service_id: "",
    service_id: "",
    service_type: "",
    consumer_service_name: "",
    commision_type: "",
    commission_fixed: "",
    additional_surcharge: "",
    commission_percent: "",
    applied_commision: "",
  });

  useEffect(() => {
    fetchDropdownData();
    fetchService();
    fetchComments();
  }, []);

  // alert
  const hideAlert = () => {
    setShowAlertActive(false);
    setShowAlertUpdate(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  // fetch select data
  const fetchDropdownData = () => {
    API({
      ...getServiceProvider,
      onSuccess: (res) => {
        const providers = res.data.data.table_data.map((provider) => ({
          value: provider.provider_id,
          label: `${provider.provider_id}-${provider.service_provider_name}`,
        }));
        setProviderOptions(providers);
      },
      onError: (error) => {
        console.log("error");
      },
    });
    API({
      ...serviceList,
      onSuccess: (res) => {
        const standard = res.data.data.table_data.map((s) => ({
          value: s.service_id,
          label: `${s.service_id}-${s.service_name}`,
        }));
        setStandardServiceOptions(standard);
      },

      onError: (error) => {
        console.log("error");
      },
    });
  };

  // update
  const handleUpdate = () => {
    const {
      created_by,
      created_on,
      updated_by,
      updated_on,
      service,
      ...updateData
    } = editableFields;
    API({
      ...updateConsumer,
      body: {
        ...updateData,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        Swal.fire({
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "getConsumer",
      })
    );

    navigate("/");
  };

  const { active_status } = serviceData;
  const initialIsActive = active_status !== "A";

  const [isActive, setIsActive] = useState(initialIsActive);
  useEffect(() => {
    setIsActive(active_status !== "A");
  }, [active_status]);

  // active inactive
  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? 1 : 0;
    API({
      ...activeConsumer,
      body: {
        consumer_service_id: service.consumer_service_id,
        active_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: ` The service has been successfully ${
            isActive === 0 ? "Deactivate" : "Activate"
          } `,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };

  // fetch service
  const fetchService = () => {
    API({
      ...getConsumer,
      params: {
        consumer_service_id: service.consumer_service_id,
      },
      onSuccess: (res) => {
        setServiceData(res.data.data);
        setEditableFields(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching service: ", error);
      },
    });
  };

  // comments
  const fetchComments = () => {
    API({
      ...getConsumerComments,
      params: { consumer_service_id: service.consumer_service_id },
      onSuccess: (res) => {
        setComments(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching comments: ", error);
      },
    });
  };
  const handleAddComment = () => {
    const comment = document.getElementById("comments").value;
    if (!comment.trim()) {
      Swal.fire({
        icon: "error",
        title: `comment cannot be empty`,
      });
      return;
    }
    API({
      ...addConsumerComments,
      body: {
        consumer_service_id: service.consumer_service_id,
        comments: comment,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error) => {
        console.error("Error adding comment: ", error);
      },
    });
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };
  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Consumer Service Information
          </h2>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "80px" }}
            onClick={handleClickActive}
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={isActive}
                onChange={handleToggleSwitch}
              />
              <span
                className="slider round"
                data-content={sliderContent}
                style={{
                  color: isActive ? "#AAAEB0" : "#AAAEB0",
                  fontWeight: "500",
                  paddingLeft: isActive ? "80px" : "10px",
                  paddingTop: "2px",
                }}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
            <img
              className="ms-2 toggleUser"
              src={imageSource}
              alt={isActive ? "Inactive" : "Active"}
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                pointerEvents: "all",
              }}
            />
          </div>
          <Alert
            show={showAlertActive}
            onHide={hideAlert}
            title="Confirmation"
            actionText={isActive ? "deactivate" : "activate"}
            message={alertTextActive}
            term="Consumer Service"
            forTerm=" "
            walletColor={isActive ? "red" : "green"}
            onConfirm={() =>
              handleConfirmActive(isActive ? "Inactive" : "Active")
            }
          />
        </div>

        {/* Information  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Consumer Service Basic Info
          </h3>

          <div className="row justify-content-between mt-4 px-5">
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                API & Portal Based Utility Service Id
              </p>

              <p
                className="mb-0 mt-1"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                }}
              >
                {serviceData.consumer_service_id}
              </p>
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Service Mode
              </p>

              <select
                name="service_type"
                value={editableFields.service_type}
                onChange={(e) =>
                  handleInputChange("service_type", e.target.value)
                }
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="FINANCIAL">FINANCIAL</option>
                <option value="NON-FINANCIAL">NON-FINANCIAL</option>
              </select>
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Commission Type
              </p>
              <select
                name="commision_type"
                value={editableFields.commision_type}
                onChange={(e) =>
                  handleInputChange("commision_type", e.target.value)
                }
                className="p-2 w-100 mt-1"
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="POST-TRANSACTION">POST-TRANSACTION</option>
                <option value="CHARGE-CONSUMER">CHARGE-CONSUMER</option>
                <option value="ADVANCE-DISCOUNT">ADVANCE-DISCOUNT</option>
              </select>
            </div>
          </div>
          <div className="row mt-4 px-5">
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Consumer Service Name
              </p>
              <input
                type="text"
                name="consumer_service_name"
                className="p-2 w-100 mt-1"
                value={editableFields.consumer_service_name}
                onChange={(e) =>
                  handleInputChange("consumer_service_name", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Commission Fixed
              </p>
              <input
                type="text"
                name="commission_fixed"
                className="p-2 w-100 mt-1"
                value={editableFields.commission_fixed}
                onChange={(e) =>
                  handleInputChange("commission_fixed", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Commission Percentage
              </p>
              <input
                type="text"
                name="commission_percent"
                className="p-2 w-100 mt-1"
                value={editableFields.commission_percent}
                onChange={(e) =>
                  handleInputChange("commission_percent", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
          </div>
          <div className="row mt-4 px-5">
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Additional Surcharge
              </p>
              <input
                type="text"
                name="additional_surcharge"
                className="p-2 w-100 mt-1"
                value={editableFields.additional_surcharge}
                onChange={(e) =>
                  handleInputChange("additional_surcharge", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
            <div className="col-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Applied Commission
              </p>
              <input
                type="text"
                name="applied_commision"
                className="p-2 w-100 mt-1"
                value={editableFields.applied_commision}
                onChange={(e) =>
                  handleInputChange("applied_commision", e.target.value)
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
            </div>
          </div>
        </div>

        {/* Audit info  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mx-1 mt-4 pb-4"
        >
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              color: "#696666",
            }}
            className="mb-0 py-1 px-3 mx-1"
          >
            Audit Info
          </p>
          <div className="row mt-3 mx-2">
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created By: {serviceData.created_by}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created On: {formatDate(serviceData.created_on)}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified By: {serviceData.updated_by}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified On: {formatDate(serviceData.updated_on)}
              </p>
            </div>
          </div>
        </div>

        {/* comments  */}
        <div className="mt-4 pb-3">
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Comment
          </h3>
          <div
            className="mt-4 mx-5 px-5 py-3"
            style={{
              backgroundColor: "rgba(231, 232, 233, 1)",
              height: "260px",
              borderRadius: "14px",
              overflowY: "auto",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  User
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Comment
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Commented on
                </p>
              </div>
            </div>
            {comments.map((comment) => (
              <div
                key={comment.comment_id}
                className="row d-flex justify-content-around"
              >
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.username}
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.comments}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {formatDate(comment.created_on)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 mt-3 ms-3"
            >
              Comment
            </p>
            <div
              style={{
                backgroundColor: "#FFF6D8",
                borderRadius: "14px",
                border: "1.5px solid #D9D9D9",
              }}
            >
              <div className="d-flex align-items-center py-2">
                <textarea
                  className="w-100 px-3"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "14px",
                  }}
                  name="comments"
                  placeholder="Write"
                  id="comments"
                  rows="4"
                ></textarea>
                <button
                  style={{
                    borderRadius: "24px",
                    backgroundColor: "#FFF6D8",
                    border: "1px solid black",
                  }}
                  className="mx-3 px-3 py-1"
                  onClick={handleAddComment}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-itmes-center mt-5 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button children={"Update"} onClick={handleClickUpdate} />
          <Alert
            show={showAlertUpdate}
            onHide={hideAlert}
            title="Confirmation"
            actionText="update"
            message={alertTextUpdate}
            walletColor={walletColor}
            onConfirm={handleUpdate}
            forTerm=" "
            term="Consumer Service"
          />
        </div>
      </div>
    </>
  );
};

export default ConsumerInfo;
