import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import BTable from "react-bootstrap/Table";

const PaginationTable = ({
  columns,
  data,
  skipPageReset,
  setPagination,
  sticky = false,
  totalRows,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    usePagination
  );

  const [filterHeight, setFilterHeight] = useState();
  const [rowCount, setRowCount] = useState(totalRows ? totalRows : data.length);
  const [tableHeight, setTableHeight] = useState("20vh");

  useEffect(() => {
    setGlobalFilter("");
    if (document.querySelector("#filter")) {
      setFilterHeight(document.querySelector("#filter").offsetHeight);
    }
    setRowCount(data.length);
    setPageSize(14);

    const screenHeight = window.innerHeight;
    const topMargin = 160;
    const newHeight = screenHeight - topMargin - filterHeight;
    setTableHeight(`${newHeight}px`);
  }, [data, filterHeight]);

  const { filters, globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div
        id={"table"}
        className="overflow-y-auto mx-3"
        style={{
          fontSize: "13px",
          flexGrow: "1",
          fontFamily: "League Spartan",
          marginTop: "13px",
          overflowY: "auto",
        }}
      >
        <table className="table_font">
          <thead
            style={{
              position: "sticky",
              top: "0",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr className="" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className="px-3 py-1 mt-2"
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        color: "rgba(79, 87, 91, 1)",
                        backgroundColor: "#D9D9D9",
                        textAlign: "center",
                        fontSize: "16px",
                        border: "1px solid black",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "12px",
                    color: "#16407F",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  No data found
                </td>
              </tr>
            ) : (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr id={row.original.row_id} {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "6px",
                            cursor: "pointer",
                            textAlign: "center",
                            width: index === 0 ? "25px" : "",
                            backgroundColor: i % 2 === 0 ? "#fffff" : "#D0D7E1",
                            borderRight: "1px solid #063E70",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {typeof cell.value === "boolean"
                            ? cell.value.toString()
                            : cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <div
          className="mt-2 mx-3"
          style={{
            fontFamily: "League Spartan",
            borderTop: "2px solid #063E70",
          }}
        >
          <div className="d-flex align-items-center justify-content-between pt-1">
            <span style={{ fontFamily: "League Spartan" }}>
              Showing {pageIndex * pageSize + 1} -{" "}
              {Math.min((pageIndex + 1) * pageSize, rowCount)} of {rowCount}{" "}
              entries
            </span>

            <div>
              <button
                className="rounded border-0 px-2 py-1"
                disabled={!canPreviousPage}
                onClick={() => gotoPage(0)}
                style={{
                  color: "#191926",
                  opacity: !canPreviousPage ? "0.5" : "1",
                }}
              >
                {"First"}
              </button>
              <button
                className="mx-3 rounded border-0 px-2 py-1"
                disabled={!canPreviousPage}
                style={{
                  color: "#191926",
                  opacity: !canPreviousPage ? "0.5" : "1",
                }}
                onClick={() => {
                  previousPage();
                }}
              >
                {"Previous"}
              </button>

              {Array.from({ length: pageOptions.length })
                .slice(pageIndex > 1 ? pageIndex - 1 : 0, pageIndex + 2)
                .map((_, index) => (
                  <button
                    className="border-0"
                    key={index}
                    onClick={() =>
                      gotoPage(index + (pageIndex > 1 ? pageIndex - 1 : 0))
                    }
                    style={{
                      margin: "0 5px",
                      padding: "3px 8px",
                      background:
                        index + (pageIndex > 1 ? pageIndex - 1 : 0) ===
                        pageIndex
                          ? "#16407F"
                          : "white",
                      color:
                        index + (pageIndex > 1 ? pageIndex - 1 : 0) ===
                        pageIndex
                          ? "white"
                          : "#16407F",
                      cursor: "pointer",
                    }}
                  >
                    {index + 1 + (pageIndex > 1 ? pageIndex - 1 : 0)}
                  </button>
                ))}
              <button
                className="mx-3 rounded px-2 py-1 border-0"
                onClick={() => {
                  if (pageOptions.length - pageIndex - 1 < 4) {
                    setPagination((prev) => ({
                      ...prev,
                      offset: prev.offset + prev.limit * 1,
                    }));
                  }
                  nextPage();
                }}
              >
                {"Next"}
              </button>
              <button
                className="rounded px-2 py-1 border-0"
                disabled={!canNextPage}
                onClick={() => gotoPage(pageCount - 1)}
                style={{ color: "#191926" }}
              >
                {"Last"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaginationTable;
