import React, { useEffect, useState } from "react";
import { getWorkflow } from "../urls";
import API from "../components/api2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import Filter from "../components/Filter";
import PaginationTable from "../components/PaginationTable";

const AllWorkflows = () => {
  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflow, setFilteredWorkflow] = useState([]);
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 20 });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();
  const [excelData, setExcelData] = useState({});
  const [includeExcel, setIncludeExcel] = useState(false);

  useEffect(() => {
    fetchServices();
  }, [includeExcel]);

  const fetchServices = () => {
    API({
      ...getWorkflow,
      params: { limit: 40, include_excel: includeExcel ? true : undefined },
      onSuccess: (res) => {
        setWorkflows(res.data.data.table_data);
        setActive(res.data.data.active_count);
        setInActive(res.data.data.inactive_count);
        setExcelData(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching workflows: ", error);
      },
    });
  };

  const applyWorkflowFilters = (filters) => {
    const filtered = workflows.filter((s) => {
      return (
        (!filters.workflow_name || s.workflow_name === filters.workflow_name) &&
        (!filters.type || s.type === filters.type) &&
        (!filters.category || s.category === filters.category) &&
        (!filters.subcategory || s.subcategory === filters.subcategory)
      );
    });
    setFilteredWorkflow(filtered);
    setFilteredTable(filtered);
    setIncludeExcel(filtered.length > 0);
  };

  const changePageFunc = (clickedService) => {
    dispatch(
      changePage({
        page: "workflowInfo",
        service: clickedService,
      })
    );

    navigate("/", { state: { service: clickedService } });
  };

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Workflow
          </h2>
          <div className="d-flex me-5 pe-3">
            <div
              className="d-flex align-items-center"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(20, 140, 67, 1)",
                }}
                className="mb-0"
              >
                Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {active}
              </span>
            </div>
            <div
              className="d-flex align-items-center ms-3"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(249, 124, 75, 1)",
                }}
                className="mb-0"
              >
                In Active
              </p>
              <span
                className="mx-2"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                {inActive}
              </span>
            </div>
          </div>
        </div>
        <Filter
          forPage="WorkflowList"
          applyFilters={applyWorkflowFilters}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
          workflows={workflows}
          setPagination={setPagination}
          excelData={excelData}
        />
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() => changePageFunc(row.original)}
                  />
                );
              },
            },
            { Header: "Workflow ID", accessor: "workflow_sid" },
            { Header: "Workflow Name", accessor: "workflow_name" },
            {
              Header: "Type",
              accessor: "type",
            },
            {
              Header: "Category",
              accessor: "category",
            },
            {
              Header: "Sub Category",
              accessor: "subcategory",
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default AllWorkflows;
