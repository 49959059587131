import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";

const ExcelViewer = ({ src }) => {
  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    const fetchExcel = async () => {
      try {
        const response = await fetch(src);
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Assume we are interested in the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setExcelData(jsonData);
      } catch (error) {
        console.error("Error fetching or parsing Excel file:", error);
      }
    };

    fetchExcel();
  }, [src]);

  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = src;
    link.setAttribute("download", src.substring(src.lastIndexOf("/") + 1));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fileName = src.substring(src.lastIndexOf("/") + 1);

  const boxStyles = {
    width: "244px",
    maxHeight: "158px",
    cursor: "pointer",
    border: "1px solid #207245",
    borderRadius: "26px",
    backgroundColor: "white",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyles = {
    fontSize: "3rem",
    marginBottom: "10px",
  };

  const textStyles = {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "0",
    padding: "0px 10px",
    fontSize: "14px",
  };

  return (
    <div onClick={downloadExcel} style={boxStyles}>
      <FaFileExcel size={60} className="text-success" style={iconStyles} />
      <p style={textStyles}>{fileName}</p>
    </div>
  );
};

export default ExcelViewer;
