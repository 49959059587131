import React from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./router/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppRoutes />
  // {/* </React.StrictMode> */}
);
