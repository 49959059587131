import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    amount: "",
    total: "",
    srNo: "",
  },
  reducers: {
    setSR: (state, action) => {
      state.total = action.payload.total;
      state.srNo = action.payload.srNo;
    },
    setAmount: (state, action) => {
      state.amount = action.payload.amount;
    },
  },
});

export const { setSR, setAmount } = paymentSlice.actions;

export default paymentSlice.reducer;
