import React, { useEffect, useState } from "react";
import { getMenuShortcut, getTransCommission } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";
import Swal from "sweetalert2";

const CommissionReport = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 30 });
  const [dropdown, setDropdown] = useState([]);
  const [filters, setFilters] = useState({});

  async function fetchApplicationExcel({ filterValues = {} } = {}) {
    if (!filterValues.service_type) {
      Swal.fire({
        icon: "warning",
        title: "Please select the Service Type",
      });
      return;
    }
    return await new Promise((resolve, reject) => {
      fetchCommission({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }
  useEffect(() => {
    API({
      ...getMenuShortcut,
      onSuccess: (res) => {
        setDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching service dropdown: ", error);
      },
    });
  }, []);

  const fetchCommission = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        if (key === "transaction_date") {
          const date = filterValues[key];
          if (Array.isArray(date)) {
            const fromDate = date[0];
            const toDate = date[1] || fromDate;
            filteredObject["transaction_date"] = `${fromDate} to ${toDate}`;
          } else {
            filteredObject["transaction_date"] = `${date} to ${date}`;
          }
        } else {
          filteredObject[key] = filterValues[key];
        }
      }
    }
    setFilters(filteredObject);
    API({
      ...getTransCommission,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
            }
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching transaction commission: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchCommission({ append: true, filterValues: filters });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Commission Report
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchCommission}
          excelFunc={fetchApplicationExcel}
          excelFileName={"Commission Report"}
          filters={[
            {
              placeholder: "Service Type",
              id: "service_type",
              filterType: "select",
              inputType: null,
              options: dropdown?.length
                ? dropdown.map((item) => ({
                    value: item.utility_service_name,
                    label: item.utility_service_name,
                  }))
                : [{ value: "", label: "No options available" }],
            },
            {
              placeholder: "Transaction Date",
              id: "transaction_date",
              filterType: "date",
            },
          ]}
        ></Filter2>

        <PaginationTable
          columns={[
            { Header: "Transaction Date", accessor: "transaction_date" },
            { Header: "Transaction Time", accessor: "transaction_time" },
            { Header: "Commission", accessor: "commission" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default CommissionReport;
