import React, { useState } from "react";
import Header from "../../../components/Header";
import { bankTransferIcon, newLogo, serviceBackArrow } from "../../../images";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomerList from "../Recharge/CustomerList";
import CheckCustomer from "../../../components/CheckCustomer";

const QueryRemitter = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCustomerClick = (contactNo) => {
    setSelectedCustomer(contactNo);
    navigate("/", { state: { contactNo } });
  };

  const handleBack = (page) => {
    const targetPage = selectedCustomer ? "queryRemitter" : page;
    dispatch(changePage({ page: targetPage }));
    if (targetPage === "queryRemitter") {
      setSelectedCustomer(null);
    }
    navigate("/");
  };

  return (
    <>
      {/* Header  */}
      <div
        style={{ backgroundColor: "#D9D9D9" }}
        className="px-2 py-2 d-flex justify-content-between"
      >
        <img
          onClick={() => handleBack("home")}
          style={{
            width: "220px",
            cursor: "pointer",
            pointerEvents: "all",
            marginLeft: "20px",
            padding: "6px 0",
          }}
          src={newLogo}
          alt="logo"
        />
        <Header
          style={{
            width: "75%",
            backgroundColor: "#194280",
            borderRadius: "10px",
            color: "#194280",
            position: "sticky",
            padding: "10px 0px",
            top: "0",
          }}
        />
      </div>
      <div className="mb-2 mt-1 mx-5 px-2 d-flex align-items-center">
        <div
          className="mb-0 px-2 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "5px",
            color: "white",
            height: "50px",
            cursor: "pointer",
            pointerEvents: "all",
          }}
          onClick={() => handleBack("services")}
        >
          <img src={serviceBackArrow} alt="back" />
        </div>
        <div className="d-flex ms-2 flex-wrap text-center justify-content-center">
          <div
            style={{ cursor: "pointer", margin: "0 20px" }}
            className="d-flex flex-column text-center position-relative "
          >
            <img
              style={{ width: "40px", margin: "6px auto 0 auto" }}
              src={bankTransferIcon}
              alt="icon"
            />
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
              }}
              className="mb-0 login_text selected_service"
            >
              Bank Transfer
            </p>
          </div>
        </div>
      </div>

      {!selectedCustomer ? (
        <CustomerList handleCustomerClick={handleCustomerClick} />
      ) : (
        <CheckCustomer name={"Bank Transfer"} mobileNumber={selectedCustomer} />
      )}
    </>
  );
};

export default QueryRemitter;
