import React from "react";
import Slider from "react-slick";
import { slider1, slider2 } from "../images";
import Tilt from "react-parallax-tilt";

export default function SlickSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      <div className="h-100">
        <Tilt style={{ cursor: "pointer" }}>
          <img
            style={{ width: "210px" }}
            className="slider_img mx-auto"
            src={slider1}
            alt="1"
          />
        </Tilt>
      </div>
      <div className="h-100">
        <Tilt style={{ cursor: "pointer" }}>
          <img
            style={{ width: "210px" }}
            className="slider_img mx-auto"
            src={slider2}
            alt="2"
          />
        </Tilt>
      </div>
      <div className="h-100">
        <Tilt style={{ cursor: "pointer" }}>
          <img
            style={{ width: "210px" }}
            className="slider_img mx-auto"
            src={slider1}
            alt="1"
          />
        </Tilt>
      </div>
      <div className="h-100">
        <Tilt style={{ cursor: "pointer" }}>
          <img
            style={{ width: "210px" }}
            className="slider_img mx-auto"
            src={slider2}
            alt="2"
          />
        </Tilt>
      </div>
    </Slider>
  );
}
