import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "../components/Button";
import {
  airpayRechargeRequest,
  serviceRequest,
  serviceRequestStatus,
} from "../urls";
import API from "../components/api2";
import { connect, useDispatch } from "react-redux";
import { changePage } from "../redux/slice/dashboardSlice";
import { setSR } from "../redux/slice/paymentSlice";
import Swal from "sweetalert2";
import Header from "../components/Header";

const Payment = ({ backPage, merchant_id, srNo, amount }) => {
  const [show, setShow] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [proceedToPayDisabled, setProceedToPayDisabled] = useState(true);
  const [showTotal, setShowTotal] = useState(false);
  const [showPaymentMode, setShowPaymentMode] = useState(false);

  const [airpayForm, setAirpayForm] = useState(false);

  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(
      changePage({
        page: backPage,
      })
    );
    dispatch(
      setSR({
        srNo: "",
      })
    );
  };

  useEffect(() => {
    console.log(srNo);
    if (["", null, 0, undefined].includes(srNo)) {
      raiseServiceRequest();
    } else {
      fetchServiceRequestStatus();
      console.log("elsey");
    }
  }, []);

  useEffect(() => {
    fetchAirpayForm();
  }, [amount, paymentMode]);

  const raiseServiceRequest = () => {
    if (amount) {
      const requestBody = {
        transaction_amount: amount,
        request_type: "wallet_recharge",
      };

      API({
        ...serviceRequest,
        body: requestBody,
        onSuccess: (res) => {
          console.log("Wallet Recharge Request:", res);
          // setSrNo(res.data.data.sr_no);
          dispatch(
            setSR({
              srNo: res.data.data.sr_no,
            })
          );
        },
        onError: (error) => {
          console.error("Error Wallet Recharge Request: ", error);
        },
      });
    } else {
      console.error("Recharge Amount is missing.");
    }
  };

  const fetchServiceRequestStatus = () => {
    if (amount) {
      const requestBody = {
        srNo: srNo,
      };

      API({
        ...serviceRequestStatus,
        params: requestBody,
        onSuccess: (res) => {
          var data = res.data.data;
          if (Object.keys(data).length !== 0) {
            const title =
              data.status === "200"
                ? `<strong style="font-size: 35px;">Payment Successfull</strong><br><span style="font-size: 22px;">Transaction ID: ${data.tran_id}</span>`
                : `<strong style="font-size: 28px;">${data.reason}</strong><br><span style="font-size: 22px;">Transaction ID: ${data.tran_id}</span>`;

            Swal.fire({
              icon: data.status === "400" ? "error" : "success",
              title: title,
              preConfirm: () => {
                handleBack();
              },
            });
          } else {
            raiseServiceRequest();
          }
        },
        onError: (error) => {
          console.error("Error Service Request Status: ", error);
        },
      });
    } else {
      console.error("Recharge Amount is missing.");
    }
  };

  const fetchAirpayForm = () => {
    if (amount && paymentMode === "Online") {
      const requestBody = {
        recharge_amount: amount,
        sr_no: srNo,
      };

      API({
        ...airpayRechargeRequest,
        body: requestBody,
        onSuccess: (res) => {
          console.log("Airpay Recharge Request:", res);
          setAirpayForm(res.data.data);
        },
        onError: (error) => {
          console.error("Error Airpay Recharge Request: ", error);
        },
      });
    }
  };

  const initiatePayment = () => {
    const transactionForm = document.getElementById("main-transaction-form");
    if (transactionForm) {
      transactionForm.submit();
    } else {
      Swal.fire({
        icon: "error",
        title: "Contact to admin",
        text: "The payment form could not be found. Please contact support.",
      });
    }
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
    setErrorMessage("");
  };

  const handleDone = () => {
    if (paymentMode === "") {
      setErrorMessage("Please select any payment mode!");
      setProceedToPayDisabled(true);
    } else {
      setErrorMessage("");
      setProceedToPayDisabled(false);
      setShowTotal(true);
      setShowPaymentMode(true);
    }
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-white d-flex align-items-center my-2"
        style={{ height: "100%" }}
      >
        <div className="row px-3 flex-grow-1 h-100 py-3">
          <div
            className="col-7"
            style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
          >
            <h2
              className="mt-3 login_text"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                color: "rgba(26, 25, 24, 1)",
              }}
            >
              Recharge Wallet
            </h2>
            <div className="px-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                className="mb-0 mt-5 trans_text"
              >
                {"Merchant ID"} : {merchant_id}
              </p>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                className="mb-0 mt-3 trans_text"
              >
                {"Service Request No."} : {srNo}
              </p>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                className="mb-0 mt-3 trans_text"
              >
                Amount - {amount}
              </p>
              <div className="d-flex align-items-center mt-3 position-relative">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="mb-0 trans_text"
                >
                  Payment mode -
                </p>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between ps-4">
                    <Form.Check
                      className="mb-0 trans_text"
                      type="radio"
                      label="Cash"
                      name="paymentMode"
                      value="Cash"
                      checked={paymentMode === "Cash"}
                      onChange={handlePaymentModeChange}
                      id="cash-radio"
                    />
                    <Form.Check
                      className="ms-3 mb-0 trans_text"
                      type="radio"
                      label="Online"
                      name="paymentMode"
                      value="Online"
                      checked={paymentMode === "Online"}
                      onChange={handlePaymentModeChange}
                      id="online-radio"
                    />
                  </div>
                </Form.Group>
                {errorMessage && (
                  <p
                    className="mb-0 position-absolute mt-5"
                    style={{ color: "red", left: "21%" }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
            <div
              style={{ marginTop: "200px" }}
              className="d-flex justify-content-between"
            >
              <Button children={"Back"} onClick={handleBack} />
              <Button children={"Done"} onClick={handleDone} />
            </div>
          </div>
          <div className="col-5" style={{ flexGrow: "1" }}>
            <div
              className="d-flex flex-column px-3 py-4 h-100"
              style={{
                backgroundColor: "rgba(22, 64, 127, 1)",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 login_text"
                >
                  Payment Mode
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 login_text"
                >
                  {showPaymentMode ? paymentMode : "None"}
                </p>
              </div>
              <div
                style={{ borderBottom: "1.5px solid white" }}
                className="d-flex align-items-center justify-content-between mt-4 pb-3"
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 trans_text"
                >
                  Total
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 trans_text"
                >
                  {showTotal ? `₹ ${amount}` : "0"}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 trans_text"
                >
                  Grand Total
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "white",
                  }}
                  className="mb-0 trans_text"
                >
                  {showTotal ? `₹ ${amount}` : "0"}
                </p>
              </div>
              <div className="mt-auto">
                <button
                  className="border-0 text-white px-3 py-2 w-100 trans_text"
                  style={{
                    backgroundColor: proceedToPayDisabled
                      ? "#989898"
                      : "rgba(255, 162, 1, 1)",
                    borderRadius: "5px",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: proceedToPayDisabled ? "not-allowed" : "pointer",
                  }}
                  onClick={initiatePayment}
                >
                  Proceed to Pay
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="airpay-form"
          style={{ display: "none" }}
          dangerouslySetInnerHTML={{ __html: airpayForm }}
        ></div>
      </div>
    </>
  );
};

// export default Payment;
const mapStateToProps = (state) => ({
  backPage: state.dashboard.backPage,
  merchant_id: state.user.username,
  amount: state.payment.amount,
  srNo: state.payment.srNo,
});
export default connect(mapStateToProps)(Payment);
