import React, { useState } from "react";
import Input from "../components/Input";
import API from "../components/api2";
import { assignRights } from "../urls";
import Button from "../components/Button";
import Select from "../components/Select";

const AssignRights = () => {
  const [pageData, setPageData] = React.useState({});

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  function onSubmit(e) {
    e.preventDefault();

    API({
      ...assignRights,
      body: pageData,
      onSuccess: (res) => {
        console.log("New System User added successfully");
      },
      onError: (error) => {
        alert(error.response.data.message);
      },
    });
  }

  //   Admin Rights
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const adminCheckboxData = ["Admin Right 1", "Admin Right 2", "Admin Right 3"];
  const [adminChildCheckboxes, setAdminChildCheckboxes] = useState(
    Array(adminCheckboxData.length).fill(false)
  );

  const toggleAdminParentCheckbox = () => {
    setIsAdminChecked(!isAdminChecked);
    if (!isAdminChecked) {
      setIsOperationChecked(false);
      setOperationChildCheckboxes(
        Array(operationCheckboxData.length).fill(false)
      );
    }
    setAdminChildCheckboxes(adminChildCheckboxes.map(() => !isAdminChecked));
    setOperationChildCheckboxes(
      operationChildCheckboxes.map(() => !isOperationChecked)
    );
  };

  const toggleAdminChildCheckbox = (index) => {
    const updatedCheckboxes = [...adminChildCheckboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setAdminChildCheckboxes(updatedCheckboxes);

    setIsAdminChecked(updatedCheckboxes.every((checkbox) => checkbox));
  };

  //   Operation Rights
  const [isOperationChecked, setIsOperationChecked] = useState(false);
  const operationCheckboxData = [
    "Operation Right 1",
    "Operation Right 2",
    "Operation Right 3",
  ];
  const [operationChildCheckboxes, setOperationChildCheckboxes] = useState(
    Array(operationCheckboxData.length).fill(false)
  );

  const toggleOperationParentCheckbox = () => {
    setIsOperationChecked(!isOperationChecked);
    setOperationChildCheckboxes(
      operationChildCheckboxes.map(() => !isOperationChecked)
    );
  };

  const toggleOperationChildCheckbox = (index) => {
    const updatedCheckboxes = [...operationChildCheckboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setOperationChildCheckboxes(updatedCheckboxes);

    setIsOperationChecked(updatedCheckboxes.every((checkbox) => checkbox));
  };

  return (
    <>
      <form onSubmit={onSubmit} className=" text-start">
        <div
          style={{ maxWidth: "710px" }}
          className="bg-worker my-4 px-5 mx-auto py-4 table-container2"
        >
          <h2 className="fw-bold fs-3 text-center pb-4 underline">
            Assign Rights
          </h2>
          <label className="d-flex fw-bold align-items-center">
            Select Profile &nbsp; &nbsp; &nbsp;
            <Select
              id={"selectProfile"}
              name={"selectProfile"}
              valueState={pageData}
              onChange={pageDataFunc}
            />
          </label>
          <label className="d-flex fw-bold align-items-center mt-4">
            Select Username
            <Select
              id={"selectUsername"}
              name={"selectUsername"}
              valueState={pageData}
              onChange={pageDataFunc}
            />
          </label>
          <label className="d-flex fw-bold align-items-center mt-4 no-wrap">
            Assign Role &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Select
              id={"assignRole"}
              name={"assignRole"}
              valueState={pageData}
              onChange={pageDataFunc}
            />
          </label>

          <div className="row mt-5">
            <div className="col-md-6">
              <label className="fw-bold fs-5">
                <input
                  type="checkbox"
                  checked={isAdminChecked}
                  onChange={toggleAdminParentCheckbox}
                />
                All Admin Rights
              </label>

              {adminCheckboxData.map((label, index) => (
                <div key={index}>
                  <input
                    className="mt-4"
                    type="checkbox"
                    checked={adminChildCheckboxes[index] || false}
                    onChange={() => toggleAdminChildCheckbox(index)}
                  />
                  {label}
                </div>
              ))}
            </div>
            <div className="col-md-6">
              <label className="fw-bold fs-5">
                <input
                  type="checkbox"
                  checked={isOperationChecked}
                  onChange={toggleOperationParentCheckbox}
                  // disabled={!isAdminChecked}
                />
                All Operation Rights
              </label>

              {operationCheckboxData.map((label, index) => (
                <div key={index}>
                  <input
                    className="mt-4"
                    type="checkbox"
                    checked={operationChildCheckboxes[index] || false}
                    onChange={() => toggleOperationChildCheckbox(index)}
                    // disabled={!isAdminChecked}
                  />
                  {label}
                </div>
              ))}
            </div>
          </div>

          <div className="my-3 text-end ">
            <Button children={"Save"} />
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignRights;
