import React, { useEffect, useState } from "react";
import { available } from "../urls";
import API from "../components/api2";
import Filter from "../components/Filter";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredMRoles] = useState([]);
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 2 });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    API({
      ...available,
      onSuccess: (res) => {
        setRoles(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching roles: ", error);
      },
    });
  };

  const applyMenuFilters = (filters) => {
    const filtered = roles.filter((role) => {
      return (
        (!filters.menu_id || role.menu_id === filters.menu_id) &&
        (!filters.menu_name || role.menu_name.includes(filters.menu_name))
      );
    });
    setFilteredMRoles(filtered);
    setFilteredTable(filtered);
  };

  const changePageFunc = (role) => {
    dispatch(
      changePage({
        page: "roleInformation",
        role_id: role.role_id,
      })
    );

    navigate("/", {
      state: {
        role,
      },
    });
  };

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            List of Roles
          </h2>
        </div>
        <Filter
          forPage="RolesList"
          applyFilters={applyMenuFilters}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
          roles={roles}
          setPagination={setPagination}
        />
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() => changePageFunc(row.original)}
                  />
                );
              },
            },
            { Header: "Role ID", accessor: "role_id" },
            { Header: "Role Name", accessor: "role_name" },
            {
              Header: "Created On",
              accessor: "created_on",
              Cell: ({ value }) => {
                const dateObject = new Date(value);
                const formattedDate = dateObject.toISOString().split("T")[0];
                return formattedDate;
              },
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default ManageRoles;
